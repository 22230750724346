import React, { memo, useMemo } from 'react'
import classes from './PrivateLayout.module.scss'
import BottomNavigationBar from 'components/ui/controls/BottomNavigationBar'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import Logo from 'components/ui/layout/Logo'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { isShowNavigationBar } from 'modules/app/store/selectors.js'
import { useLocation } from 'react-router-dom'
import { routesByName } from '../../../../constants/routes'

const navigationBarClasses = {
	root: classes.navigationBar
}
const logoClasses = {
	icon: classes.logo
}

const PrivateLayout = ({
	leftSidebar,
	rightSidebar,
	content,
	contentSlotOnMobile = 'content',
	classes: outerClasses = {},
	withRightSidebar = false,
	withMateName = false,
	mate
}) => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const navigationBar = useSelector(isShowNavigationBar)
	const location = useLocation()
	const withoutSidebars = useMemo(
		() => location.pathname !== routesByName.main.notifications.emails,
		[location.pathname]
	)
	const slots = useMemo(
		() => ({
			'left-sidebar': (
				<div className={clsx(classes.leftSidebar, outerClasses.leftSidebar)}>
					{leftSidebar}
				</div>
			),
			content: (
				<div className={clsx(classes.content, outerClasses.content)}>
					{content}
				</div>
			)
		}),
		[content, leftSidebar, outerClasses.content, outerClasses.leftSidebar]
	)

	const invertSlot = useMemo(
		() => (contentSlotOnMobile !== 'content' ? 'content' : 'left-sidebar'),
		[contentSlotOnMobile]
	)

	const style = useMemo(
		() => ({
			gridTemplateAreas: `"${contentSlotOnMobile}" "navigation-bar"`
		}),
		[contentSlotOnMobile]
	)

	return (
		<div
			className={clsx(classes.root, {
				[classes.withRightSidebar]:
					withRightSidebar && format === screenFormat.desktop,
				[classes.withNavigationBar]: navigationBar && withoutSidebars,
				[classes.withoutLogo]: !withoutSidebars
			})}
			style={style}
		>
			{slots[contentSlotOnMobile]}
			{withoutSidebars && (
				<BottomNavigationBar classes={navigationBarClasses} />
			)}
			{format === screenFormat.desktop ? (
				<>
					{withoutSidebars && slots[invertSlot]}

					{withoutSidebars && (
						<div className={classes.logoContainer}>
							{withMateName && mate.firstName ? (
								<div className={classes.mateName}>{mate.firstName}</div>
							) : (
								<Logo variant="dark" withTitle classes={logoClasses} />
							)}
						</div>
					)}

					{withRightSidebar ? (
						<div
							className={clsx(classes.rightSidebar, outerClasses.rightSidebar)}
						>
							{rightSidebar}
						</div>
					) : null}
				</>
			) : null}
		</div>
	)
}

export default memo(PrivateLayout)
