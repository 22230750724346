import React from 'react'
import BaseTabs from 'components/ui/controls/BaseTabs'
import clsx from 'clsx'
import classes from './MessagesHeader.module.scss'
import { routesByName } from 'constants/routes'

export const matches = 'matches'
export const likes = 'likes'
export const requests = 'requests'

const messageTabs = [
	{
		label: 'Matches',
		value: matches,
		path: routesByName.main.notifications.matches.root
	},
	{
		label: 'Likes',
		value: likes,
		path: routesByName.main.notifications.likes.root
	},
	{
		label: 'Requests',
		value: requests,
		path: routesByName.main.notifications.requests.root
	}
]

const MessagesHeader = ({
	classes: outerClasses = {},
	onClickClose = () => null
}) => {
	return (
		<div className={clsx(classes.root, outerClasses.root)}>
			{/*<SpriteIcon*/}
			{/*	name="close"*/}
			{/*	className={classes.closeIcon}*/}
			{/*	onClick={onClickClose}*/}
			{/*/>*/}
			<div className={classes.tabs}>
				<BaseTabs tabs={messageTabs} />
			</div>
		</div>
	)
}

export default MessagesHeader
