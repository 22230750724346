import { useCallback, useMemo } from 'react'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { useSelector } from 'react-redux'
import {
	getCurrentConversation,
	getMateIdByConversationId,
	getUserForCreateConversation
} from 'modules/conversation/store/selectors.js'
import { getUserById } from 'modules/users/selectors.js'

const NotificationsContainer = ({ children }) => {
	const conversationId = useSelector(getCurrentConversation)
	const userForCreateConversation = useSelector(getUserForCreateConversation)
	const mateId = useSelector(getMateIdByConversationId(conversationId))
	const mate = useSelector(getUserById(mateId || userForCreateConversation))

	const format = useDetermineScreenFormat(breakpointsUp.sm)

	const handleClickClose = useCallback(() => {}, [])

	const props = useMemo(
		() => ({
			format,
			mate,
			conversationId,
			onClickClose: handleClickClose
		}),
		[conversationId, format, handleClickClose, mate]
	)

	return children(props)
}

export default NotificationsContainer
