import React from 'react'
import { ReactComponent as ProfileIcon } from 'assets/staticIcons/profile.svg'
import classes from 'modules/profile/components/LeftSidebar/LeftSidebar.module.scss'
import Typography from 'components/ui/Typography'

const SettingsLeftSidebar = () => {
	return (
		<div className={classes.root}>
			<ProfileIcon />
			<Typography className={classes.text}>
				Update, edit and preview
				<br />
				information about you
			</Typography>
		</div>
	)
}

export default SettingsLeftSidebar
