import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getAppVersionFromMeta,
	initAppAction,
	setAppVersionFromMeta,
	setShowNavigationBar
} from 'modules/app/store/actions'
import GuestRouteStack from 'modules/app/guest/GuestStack.js'
import ControlledLoader from 'components/ui/ControlledLoader/ControlledLoader'
import CommonRouteStack from 'modules/app/common/CommonRouteStack'
import PrivateRouteStack from 'modules/app/private/PrivateRouteStack'
import GuestSwitch from 'modules/app/guest/GuestSwitch'
import PrivateSwitch from 'modules/app/private/PrivateSwitch'
import { BrowserRouter as Router } from 'react-router-dom'
import _useAsyncDidMount from 'hooks/_useAsyncDidMount'
import * as Sentry from '@sentry/browser'
import CacheBuster from './modules/app/components/cacheBuster'

const App = () => {
	const dispatch = useDispatch()
	const { logined, inited, appVersion } = useSelector(
		({ app: { inited, appVersion }, auth: { logined } }) => ({
			inited,
			logined,
			appVersion
		})
	)

	const CurrentRouteStack = useMemo(
		() => (!logined ? GuestRouteStack : PrivateRouteStack),
		[logined]
	)

	const CurrentSwitch = useMemo(
		() => (!logined ? GuestSwitch : PrivateSwitch),
		[logined]
	)

	_useAsyncDidMount(async () => {
		dispatch(setShowNavigationBar(true))
		if (!appVersion) {
			dispatch(setAppVersionFromMeta())
		}
		try {
			await dispatch(getAppVersionFromMeta())
			await dispatch(initAppAction())
		} catch (e) {
			Sentry.captureException(e)
		}
	})

	return inited && appVersion ? (
		<>
			<Router>
				<CurrentSwitch>
					{CurrentRouteStack}
					{CommonRouteStack}
				</CurrentSwitch>
			</Router>
			<CacheBuster />
		</>
	) : (
		<ControlledLoader />
	)
}

export default App
