import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Popper from '@material-ui/core/Popper'
import { commonWhite } from 'constants/colors'
import Fade from 'components/wrappers/transitions/Fade'

const zIndex = 999999

const styles = () => ({
	root: {
		zIndex
	},
	content: {
		backgroundColor: commonWhite,
		boxShadow: `3px 3px 4px rgba(0, 0, 0, 0.15)`,
		borderRadius: 8,
		zIndex
	},
	topOffset: {
		paddingTop: 10,
		'& > #arrow': {
			top: 5,
			transform: 'rotate(-45deg)'
		}
	},
	bottomOffset: {
		paddingBottom: 10,
		'& > #arrow': {
			bottom: 5,
			transform: 'rotate(135deg)'
		}
	},
	leftOffset: {
		paddingLeft: 10,
		'& > #arrow': {
			left: 5,
			transform: 'rotate(225deg)'
		}
	},
	rightOffset: {
		paddingRight: 5,
		'& > #arrow': {
			right: 5,
			transform: 'rotate(45deg)'
		}
	},
	arrow: {
		position: 'absolute',
		// borderColor: `transparent ${palette[type].dropdown.background} transparent transparent`,
		borderWidth: '0px 10px 10px 0px',
		width: 0,
		height: 0,
		borderStyle: 'solid'
		// boxShadow: `${palette[type].dropdown.shadow} 1px -1px 3px -2px`
	}
})

const BasePopup = ({
	children,
	trigger,
	classes,
	placement = 'bottom',
	on,
	innerClasses = {},
	disabled,
	hasArrow,
	offset = 10,
	...props
}) => {
	const [arrowRef, setArrowRef] = useState(null)
	const [open, setOpen] = useState(false)
	const [didMouseLeaveTrigger, toggleDidMouseLeaveTrigger] = useState(true)
	const [didMouseLeaveContainer, toggleDidMouseLeaveContainer] = useState(true)

	const handleArrowRef = useCallback(node => {
		setArrowRef(node)
	}, [])

	const ref = useRef({
		didClickInside: null,
		anchor: null
	})

	const handleMouseOutside = useCallback(() => {
		ref.current.didClickInside = false
	}, [])

	const closePopup = useCallback(() => {
		ref.current.anchor = null
		setOpen(false)
	}, [])

	const openPopup = useCallback(({ currentTarget }) => {
		ref.current.anchor = currentTarget
		setOpen(true)
	}, [])

	const clickInside = useCallback(() => {
		ref.current.didClickInside = true
	}, [])

	const toggle = useCallback(
		({ currentTarget }) => {
			clickInside()
			ref.current.anchor = currentTarget
			setOpen(value => !value)
		},
		[clickInside]
	)

	const hover = useCallback(
		({ currentTarget }) => {
			toggleDidMouseLeaveTrigger(false)
			openPopup({ currentTarget })
		},
		[openPopup]
	)

	const mode = useMemo(() => {
		return disabled
			? {}
			: on === 'click'
			? { onClick: toggle, onMouseLeave: handleMouseOutside }
			: {
					onMouseLeave: () => toggleDidMouseLeaveTrigger(true),
					onMouseEnter: hover
			  }
	}, [disabled, handleMouseOutside, hover, on, toggle])

	const contentListeners = useMemo(
		() => ({
			onMouseLeave:
				on === 'click'
					? handleMouseOutside
					: () => toggleDidMouseLeaveContainer(true),
			onClick: clickInside,
			onMouseEnter:
				on === 'hover' ? () => toggleDidMouseLeaveContainer(false) : undefined
		}),
		[clickInside, handleMouseOutside, on]
	)

	useEffect(() => {
		const closeModalWhenClickOutside = () => {
			if (ref.current.didClickInside === false) {
				closePopup()
			}
		}
		document.addEventListener('click', closeModalWhenClickOutside)
		return () =>
			document.removeEventListener('click', closeModalWhenClickOutside)
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (on === 'hover') {
			if (didMouseLeaveTrigger && didMouseLeaveContainer) {
				closePopup()
			}
		}
		//eslint-disable-next-line
	}, [didMouseLeaveTrigger, didMouseLeaveContainer])

	const memoTrigger = useMemo(() => React.cloneElement(trigger, mode), [
		mode,
		trigger
	])

	return (
		<>
			{memoTrigger}
			<Popper
				anchorEl={ref.current.anchor}
				open={open}
				placement={placement}
				modifiers={{
					flip: {
						enabled: false
					},
					arrow: {
						enabled: hasArrow,
						element: arrowRef
					}
				}}
				className={classes.root}
				transition
				{...props}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps}>
						<div
							{...contentListeners}
							className={clsx(classes.container, {
								[classes.topOffset]: placement.split('-')[0] === 'bottom',
								[classes.bottomOffset]: placement.split('-')[0] === 'top',
								[classes.rightOffset]: placement.split('-')[0] === 'left',
								[classes.leftOffset]: placement.split('-')[0] === 'right'
							})}
						>
							{hasArrow ? (
								<span
									id="arrow"
									className={classes.arrow}
									ref={handleArrowRef}
								/>
							) : null}
							<div className={classes.content}>{children}</div>
						</div>
					</Fade>
				)}
			</Popper>
		</>
	)
}

BasePopup.propTypes = {
	on: PropTypes.oneOf(['click', 'hover']).isRequired,
	innerClasses: PropTypes.object,
	hasArrow: PropTypes.bool.isRequired
}

BasePopup.defaultProps = {
	on: 'hover',
	hasArrow: false
}

export default withStyles(styles)(BasePopup)
