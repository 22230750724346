import appConfig from 'constants/appConfig.js'

const channelPrefix = appConfig.broadcastChannelName

const channelsByName = {
	conversationById: id => `${channelPrefix}-messenger.conversation.${id}`,
	conversationsByUser: id =>
		`${channelPrefix}-messenger.user.${id}.conversations`,
	matchesById: id => `${channelPrefix}-user.${id}.matches`
}

export default channelsByName
