import React, { memo, useMemo } from 'react'
import classes from './CardBrand.module.scss'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'
import { ReactComponent as Visa } from 'assets/staticIcons/visa.svg'
import { ReactComponent as Mastercard } from 'assets/staticIcons/mastercard.svg'

const CardBrand = ({ brand = '', classes: outerClasses = {} }) => {
	const Icon = useMemo(() => {
		switch (brand) {
			case 'visa':
				return props => <Visa {...props} />
			case 'mastercard':
				return props => <Mastercard {...props} />
			default:
				return props => <SpriteIcon name="credit-card" {...props} />
		}
	}, [brand])

	return (
		<div className={clsx(classes.root, outerClasses.root)}>
			<Icon className={clsx(classes.icon, outerClasses.icon)} />
		</div>
	)
}

export default memo(CardBrand)
