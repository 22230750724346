import React, { useCallback, useMemo, useState } from 'react'
import classes from './../Conversation.module.scss'
import PrivateLayout from 'components/ui/layout/PrivateLayout'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import useHistory from 'hooks/useHistory'
import { useSelector } from 'react-redux'
import { getUserById } from 'modules/users/selectors'
import ProfilePreviewMatePage from 'modules/notifications/ProfilePreviewMatePage.js'
import CreateConversationPage from './CreateConversationPage.js'
import { getUserForCreateConversation } from 'modules/conversation/store/selectors.js'

const componentsByTab = {
	Chat: ({ onPushMessage, mate }) => (
		<CreateConversationPage
			onPushMessage={onPushMessage}
			isConversationBlocked={mate?.is_blocked}
		/>
	),
	Profile: ({ mate }) => <ProfilePreviewMatePage mate={mate} />
}

const tabs = [
	{
		value: 'Chat',
		label: 'Chat'
	},
	{
		value: 'Profile',
		label: 'Profile'
	}
]

const CreateConversationMobile = ({ onPushMessage, title }) => {
	const userId = useSelector(getUserForCreateConversation)

	const mate = useSelector(getUserById(userId))

	const [selectedTab, setSelectedTab] = useState('Chat')
	const history = useHistory()

	const handleChangeTab = useCallback(value => {
		setSelectedTab(value)
	}, [])

	const handleClickBackIcon = useCallback(() => {
		history.goBack()
	}, [history])

	const CurrentTab = useMemo(() => componentsByTab[selectedTab], [selectedTab])

	return (
		<PrivateLayout
			content={
				<div className={classes.mobileContainer}>
					<NavigationHeader
						selectedTab={selectedTab}
						onChangeTab={handleChangeTab}
						tabs={tabs}
						title={title || mate?.firstName || 'Messages'}
						onClickBackIcon={handleClickBackIcon}
					/>
					<CurrentTab mate={mate} onPushMessage={onPushMessage} />
				</div>
			}
		/>
	)
}

export default CreateConversationMobile
