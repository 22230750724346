// export const getLikes = ({ likes: { items } }) => items

export const getReceivedLikes = ({ likes }) => likes?.receivedLikes
export const getSentLikes = ({ likes }) => likes?.sentLikes
export const getCurrentUserId = ({ likes }) => likes?.currentUserId
export const getHasMoreReceivedLikes = ({ likes }) =>
	likes?.hasMoreReceivedLikes
export const getHasMoreSentLikes = ({ likes }) => likes?.hasMoreSentLikes
export const getReceivedLikesLength = ({ likes }) =>
	likes?.receivedLikes?.length
export const getSentLikesLength = ({ likes }) => likes?.sentLikes?.length
