import React from 'react'
import BaseCard from 'components/ui/controls/BaseCard'
import classes from './BillingView.module.scss'
import Typography from 'components/ui/Typography'
import AddButton from 'modules/profile/components/AddButton/AddButton'
import PaymentCard from 'modules/subscriptionBilling/billing/components/PaymentCard'

const BillingView = ({ paymentsInfo = [], onClickEdit, onClickAdd }) => {
	return (
		<div className={classes.root}>
			<BaseCard className={classes.selectPaymentMethod}>
				<Typography className={classes.title}>My payment methods</Typography>
				<div className={classes.cardSection}>
					{paymentsInfo.map((paymentCard, index) => (
						<PaymentCard
							key={`payment-card-${paymentCard.pm}}`}
							onClick={onClickEdit}
							{...paymentCard}
						/>
					))}
				</div>
				{!paymentsInfo.length ? (
					<AddButton label="Add new payment" onClick={onClickAdd} />
				) : null}
			</BaseCard>
		</div>
	)
}

export default BillingView
