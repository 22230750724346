import React, { useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'

const CustomInfiniteScroll = ({
	children,
	loadMore,
	isLoading,
	hasMore,
	parentHeight,
	...rest
}) => {
	const contentRef = useRef(null)

	const handleLoadMore = useCallback(async () => {
		if (!hasMore || isLoading) {
			return
		}
		await loadMore()
	}, [isLoading, hasMore, loadMore])

	useEffect(() => {
		if (parentHeight && contentRef.current.clientHeight) {
			const childrenHeight = contentRef.current.clientHeight

			if (childrenHeight < parentHeight) {
				handleLoadMore()
			}
		}
	}, [parentHeight, handleLoadMore])

	return (
		<InfiniteScroll
			initialLoad={false}
			useWindow={false}
			loadMore={handleLoadMore}
			hasMore={hasMore}
			{...rest}
		>
			<div ref={contentRef}>{children}</div>
		</InfiniteScroll>
	)
}

CustomInfiniteScroll.propTypes = {
	children: PropTypes.node.isRequired,
	parentHeight: PropTypes.number,
	loadMore: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	hasMore: PropTypes.bool.isRequired
}

CustomInfiniteScroll.defaultProps = {
	parentHeight: null
}

export default CustomInfiniteScroll
