import React, { useCallback } from 'react'
import classes from './UnpauseAccount.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import Button from 'components/ui/Button'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'

const UnpauseAccount = () => {
	const history = useHistory()

	const handleClick = useCallback(
		() => history.push(routesByName.main.settings.root),
		[history]
	)

	return (
		<div className={classes.root}>
			<div>
				<SpriteIcon name="pause" className={classes.icon} />
				<p className={classes.text}>
					You have paused your profile from <br /> being shown to new people
				</p>
			</div>
			<Button shape="rounded" className={classes.btn} onClick={handleClick}>
				Unpause profile
			</Button>
		</div>
	)
}

export default UnpauseAccount
