import React, { memo, useMemo } from 'react'
import classes from './UserPreviewAvatar.module.scss'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import SpriteIcon from 'components/ui/SpriteIcon'
import clsx from 'clsx'
import { black3, secondaryColor } from 'constants/colors'
import BasePopup from 'components/wrappers/ui/BasePopup/BasePopup'
import { blobToSrc } from 'utils/blobToSrc'
import BaseImage from 'components/wrappers/ui/BaseImage'
import useUpgradeNotification from 'hooks/useUpgradeNotification'

const UserPreviewAvatar = ({
	className,
	firstName,
	age,
	career,
	location,
	avatar,
	userId,
	onClickBlock,
	onClickReport,
	onClickUndo,
	onClickSendMessage,
	onClickUnmatch,
	onClickWeMeet,
	onClickUnblock,
	isCurrentUserBlocked
}) => {
	const src = useMemo(() => {
		return avatar instanceof Blob ? blobToSrc(avatar) : avatar
	}, [avatar])

	const { isSubscribe } = useUpgradeNotification()

	return (
		<div className={clsx(classes.root, className)}>
			<BaseImage classes={{ root: classes.userPic }} src={src} />

			<div className={classes.userPicDesc}>
				<Typography className={clsx(classes.text, classes.name)}>
					{firstName}, {age}
				</Typography>
				<Typography className={clsx(classes.text, classes.description)}>
					{career} • {location}
				</Typography>
			</div>

			{(onClickUnmatch ||
				onClickWeMeet ||
				onClickReport ||
				onClickBlock ||
				onClickUnblock) && (
				<BasePopup
					trigger={
						<Button
							shape="rounded"
							size="xs"
							color="white"
							className={classes.btn}
						>
							<SpriteIcon name="context-menu" color={black3} />
						</Button>
					}
					on="click"
					placement="left-start"
				>
					<div className={clsx(classes.contextContainer)}>
						{onClickUnmatch && (
							<div
								role="presentation"
								data-id={userId}
								className={classes.contextAction}
								onClick={onClickUnmatch}
							>
								Unmatch
							</div>
						)}
						{onClickWeMeet && (
							<div
								role="presentation"
								data-id={userId}
								className={classes.contextAction}
								onClick={onClickWeMeet}
							>
								We met
							</div>
						)}
						{onClickReport && (
							<div
								role="presentation"
								onClick={onClickReport}
								data-id={userId}
								className={clsx(classes.contextAction, classes.delimiter)}
							>
								Report
							</div>
						)}
						{/*{onClickBlock && (*/}
						{/*	<div*/}
						{/*		role="presentation"*/}
						{/*		data-id={userId}*/}
						{/*		onClick={onClickBlock}*/}
						{/*		className={clsx(classes.contextAction)}*/}
						{/*	>*/}
						{/*		Block*/}
						{/*	</div>*/}
						{/*)}*/}
						{isCurrentUserBlocked ? (
							<div
								role="presentation"
								data-id={userId}
								onClick={onClickUnblock}
								className={clsx(classes.contextAction)}
							>
								Unblock
							</div>
						) : (
							<div
								role="presentation"
								data-id={userId}
								onClick={onClickBlock}
								className={clsx(classes.contextAction)}
							>
								Block
							</div>
						)}
					</div>
				</BasePopup>
			)}

			{onClickUndo ? (
				<Button
					shape="rounded"
					size="xs"
					color="white"
					className={clsx(classes.undo, classes.btn)}
					onClick={onClickUndo}
					disabled={!isSubscribe || !onClickUndo}
				>
					<SpriteIcon name="reply" color={secondaryColor} />
				</Button>
			) : null}
			{onClickSendMessage ? (
				<Button
					shape="rounded"
					size="xs"
					color="white"
					className={clsx(classes.message, classes.btn)}
					onClick={onClickSendMessage}
					data-id={userId}
					disabled={!isSubscribe || !onClickSendMessage}
				>
					<SpriteIcon name="message-cloud" color={secondaryColor} />
				</Button>
			) : null}
		</div>
	)
}

export default memo(UserPreviewAvatar)
