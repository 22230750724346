export const APP_INITED = 'APP_INITED'
export const SET_SHOW_NAVIGATION_BAR = 'SET_SHOW_NAVIGATION_BAR'
export const GET_APP_CONFIG = 'GET_APP_CONFIG'
export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const GET_UNMATCH_REASONS = 'GET_UNMATCH_REASONS'
export const GET_REPORT_REASONS = 'GET_REPORT_REASONS'
export const UPDATE_BANNER_COUNTER = 'UPDATE_BANNER_COUNTER'
export const SET_SHOW_BANNER = 'SET_SHOW_BANNER'
export const APP_SET_VERSION = 'APP_SET_VERSION'
export const APP_SET_META_VERSION = 'APP_SET_META_VERSION'
