export const getPreference = ({ preference: { filters } }) => filters
export const getSelectedLocationText = ({
	preference: {
		filters: {
			locations: { text }
		}
	}
}) => text || []

export const getFiltersInitialState = ({
	preference: { filtersInitialState }
}) => filtersInitialState
