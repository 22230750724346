import React from 'react'
import classes from 'components/ui/layout/NavigationHeader/NavigationHeader.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import Typography from 'components/ui/Typography'
import BaseTabs from 'components/ui/controls/BaseTabs/BaseTabs'
import clsx from 'clsx'

const NavigationHeader = ({
	onClickBackIcon = () => null,
	selectedTab = () => null,
	onChangeTab = () => null,
	tabs = [],
	title = '',
	name = '',
	withoutBack = false,
	classes: outerClasses = {}
}) => {
	return (
		<div
			className={clsx(classes.root, outerClasses.root, {
				[classes.withoutTabs]: !tabs.length
			})}
		>
			<div
				className={clsx(classes.navigation, {
					[classes.withoutTabs]: !tabs.length
				})}
			>
				<div className={classes.backField}>
					{!withoutBack && (
						<SpriteIcon
							name="arrow-back"
							className={classes.backIcon}
							onClick={onClickBackIcon}
							color="#E5E5E5"
						/>
					)}

					{name && <Typography className={classes.userName}>{name}</Typography>}
				</div>
				<Typography className={classes.title}>{title}</Typography>
			</div>
			{tabs.length ? (
				<BaseTabs
					variant="secondary"
					value={selectedTab}
					tabs={tabs}
					onChange={onChangeTab}
				/>
			) : null}
		</div>
	)
}

export default NavigationHeader
