import {
	birthday,
	locations,
	drink,
	familyPlans,
	growth,
	horoscope,
	job,
	kid,
	religion,
	smoke,
	study,
	wantGender
} from 'modules/profile/createProfile/config'
import React, { useCallback, useMemo } from 'react'
import BaseSlider from 'components/ui/BaseSlider/BaseSlider'
import BaseCard from 'components/ui/controls/BaseCard'
import classes from './Preference.module.scss'
import BaseCheckboxButtonsGroup from 'components/ui/controls/BaseCheckboxButtonsGroup'
import BaseRadioButtonsGroup from 'components/ui/controls/BaseRadioButtonsGroup'
import { configFields } from 'modules/profile/store/constants'
import BaseSelectLocation from 'components/ui/controls/BaseSelectLocation'
import clsx from 'clsx'
import BaseSwitch from 'components/ui/controls/BaseSwitch'
import { sm } from 'utils/simulateEvent'
import Typography from 'components/ui/Typography'
import { _set } from 'utils/lodash'

const ageFormat = values => values?.join('-')
// const city = 2
const country = 1
const any = 0

export const componentsLibrary = {
	[birthday]: props => {
		return (
			<BaseCard className={classes.card}>
				<BaseSlider labelFormat={ageFormat} min={18} max={110} {...props} />
			</BaseCard>
		)
	},
	[wantGender]: ({ config, ...props }) => (
		<BaseRadioButtonsGroup
			{...props}
			variant="secondary"
			typePreference
			options={config[configFields.wantGenders].options}
		/>
	),
	[religion]: ({ config, ...props }) => (
		<BaseCheckboxButtonsGroup
			{...props}
			typePreference
			options={config[configFields.religions].options}
		/>
	),
	[study]: ({ config, ...props }) => (
		<BaseCheckboxButtonsGroup
			{...props}
			typePreference
			options={config[configFields.educations].options}
		/>
	),
	[job]: ({ config, ...props }) => (
		<BaseCheckboxButtonsGroup
			{...props}
			typePreference
			options={config[configFields.jobs].options}
			sortedByAlphabet
		/>
	),
	[growth]: ({ config, ...props }) => {
		const growthFormat = useCallback(
			values =>
				values
					?.map(value => config[configFields.growth]?.original[value])
					?.join('-'),
			[config]
		)

		return (
			<BaseCard className={classes.card}>
				<BaseSlider
					labelFormat={growthFormat}
					min={1}
					max={config[configFields.growth].options.length}
					{...props}
				/>
			</BaseCard>
		)
	},
	[kid]: ({ config, ...props }) => (
		<BaseCheckboxButtonsGroup
			{...props}
			typePreference
			options={config[configFields.haveKids].options}
		/>
	),
	[familyPlans]: ({ config, ...props }) => (
		<BaseCheckboxButtonsGroup
			{...props}
			typePreference
			options={config[configFields.familyPlans].options}
		/>
	),
	[smoke]: ({ config, ...props }) => (
		<BaseCheckboxButtonsGroup
			{...props}
			typePreference
			options={config[configFields.smoke].options}
		/>
	),
	[drink]: ({ config, ...props }) => (
		<BaseCheckboxButtonsGroup
			{...props}
			typePreference
			options={config[configFields.drink].options}
		/>
	),
	[horoscope]: ({ config, ...props }) => (
		<BaseCheckboxButtonsGroup
			{...props}
			typePreference
			options={config[configFields.horoscope].options}
		/>
	),
	[locations]: ({ onChange, ...props }) => {
		const changeType = useCallback(
			type => {
				onChange(sm(`${props.name}.type`, type))
				onChange(sm(`${props.name}.selectedIds`, []))
			},
			[onChange, props.name]
		)

		const inputs = useMemo(() => props.value.selectedIds.length + 1, [
			props.value.selectedIds.length
		])

		const handleChangeSwitch = useCallback(
			({ target: { value, name } }) => {
				switch (name) {
					case `${props.name}.city`:
						return changeType(2)
					case `${props.name}.country`:
						return changeType(1)
					case `${props.name}.any`:
						return changeType(0)
					case `${props.name}.selectedIds`:
						return changeType(0)

					default:
						return
				}
			},
			[changeType, props.name]
		)

		const handleChangeLocation = useCallback(
			({ target: { name, value } }) => {
				let newLocations

				if (!value) {
					newLocations = props.value.selectedIds.filter((v, i) => i !== name)
				} else {
					newLocations = _set([...props.value.selectedIds], `[${name}]`, value)
				}

				const locationsText =
					props.value.text?.filter(item => newLocations.includes(item.id)) || []

				onChange(sm(`${props.name}.selectedIds`, newLocations))
				onChange(sm(`${props.name}.text`, locationsText))
			},
			[onChange, props.name, props.value.selectedIds, props.value.text]
		)

		return (
			<>
				<BaseCard
					className={clsx(
						classes.locationCard,
						classes.switchContainer,
						'mb-2'
					)}
				>
					{/*<BaseSwitch*/}
					{/*	name={`${props.name}.city`}*/}
					{/*	checked={props.value.type === city}*/}
					{/*	onChange={handleChangeSwitch}*/}
					{/*	labelPlacement="start"*/}
					{/*	spaceBetween*/}
					{/*	label="City"*/}
					{/*/>*/}
					<BaseSwitch
						name={`${props.name}.country`}
						checked={props.value.type === country}
						onChange={handleChangeSwitch}
						labelPlacement="start"
						spaceBetween
						label="Country"
					/>
					<BaseSwitch
						name={`${props.name}.any`}
						checked={props.value.type === any}
						onChange={handleChangeSwitch}
						labelPlacement="start"
						label="Any"
						spaceBetween
					/>
				</BaseCard>
				{props.value.type !== any ? (
					<BaseCard className={classes.locationCard}>
						<Typography>See people near</Typography>
						{Array.from({ length: inputs }, (value, index) => (
							<BaseSelectLocation
								placeholder={`Add ${index === 0 ? '' : 'another'} ${
									props.value.type === country ? 'country' : 'city'
								}`}
								key={`select-location-${index}`}
								value={props.value.text?.[index]?.text || ''}
								name={index}
								classes={{ root: classes.input }}
								onChange={handleChangeLocation}
								isClearable
								type={props.value.type}
							/>
						))}
					</BaseCard>
				) : null}
			</>
		)
	}
}
