const root = 'subscriptionBilling'

export const getSubscriptionPlans = ({ [root]: { config } }) => config

export const getPaymentsInfo = ({ [root]: { paymentsInfo } }) => paymentsInfo

export const getPaymentInfoById = pm => ({ [root]: { paymentsInfo } }) =>
	paymentsInfo.find(({ id }) => id === pm) || []

export const getExchangeRates = ({ [root]: { exchangeRates } }) => exchangeRates

export const getPaymentList = ({ [root]: { paymentList } }) => paymentList

export const getNextPaymentDate = ({
	profile: {
		user: {
			subscription: { next_payment_date }
		}
	}
}) => next_payment_date

export const getCurrentPlan = ({ [root]: { currentPlan } }) => currentPlan

export const getCurrentPlanId = ({ subscriptionBilling }) =>
	subscriptionBilling?.currentPlan?.id
