import DiscoveryContainer from 'modules/discovery/DiscoveryContainer'
import DiscoveryPageView from 'modules/discovery/DiscoveryView'
import React from 'react'
import ProfileContainer from 'modules/profile/ProfileContainer'

const DiscoveryPage = props => {
	return (
		<DiscoveryContainer>
			{({
				callbacks,
				applicant,
				applicantId,
				userId,
				scrollRef,
				usersAreOver
			}) => (
				<ProfileContainer user={applicant}>
					{({
						formProps,
						config,
						onClickBlock,
						onClickReport,
						onClickConfirmReport,
						reportModal,
						userApproval,
						isAccountPaused
					}) => (
						<DiscoveryPageView
							scrollRef={scrollRef}
							formProps={formProps}
							config={config}
							applicant={applicant}
							applicantId={applicantId}
							onClickBlock={onClickBlock}
							onClickReport={onClickReport}
							onClickConfirmReport={onClickConfirmReport}
							reportModal={reportModal}
							userApproval={userApproval}
							isAccountPaused={isAccountPaused}
							userId={userId}
							usersAreOver={usersAreOver}
							{...callbacks}
							{...props}
						/>
					)}
				</ProfileContainer>
			)}
		</DiscoveryContainer>
	)
}

export default DiscoveryPage
