import React from 'react'
import LikesContainer from 'modules/notifications/likes/LikesContainer'
import LikesView, { sent } from 'modules/notifications/likes/LikesView'

const SentPage = () => {
	return (
		<LikesContainer>
			{props => <LikesView {...props} selectedTab={sent} />}
		</LikesContainer>
	)
}

export default SentPage
