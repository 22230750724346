import React from 'react'
import classes from './SuccessEmail.module.scss'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import SpriteIcon from 'components/ui/SpriteIcon'

export const btnSize = {
	desktop: {
		size: 'lg'
	},
	mobile: {
		size: 'sm'
	}
}

const SuccessEmail = ({ open, onClickContinue }) => {
	const format = useDetermineScreenFormat()

	return (
		<BaseDialog open={open} fullScreen>
			<div className={classes.root}>
				<SpriteIcon name="email-success" className={classes.icon} />

				<Typography className={clsx('my-5', classes.text)} variant="body-4">
					Email sent for verification
				</Typography>
				<Button
					shape="rounded"
					color="white"
					shadow
					{...btnSize[format]}
					onClick={onClickContinue}
				>
					Continue
				</Button>
			</div>
		</BaseDialog>
	)
}

export default SuccessEmail
