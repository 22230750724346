import React, { useMemo } from 'react'
import classes from './Footer.module.scss'
import PropTypes from 'prop-types'
import Logo from 'components/ui/layout/Logo'
import clsx from 'clsx'
import Link from 'components/ui/Typography/Link'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { commonWhite } from 'constants/colors'
import SpriteIcon from 'components/ui/SpriteIcon'
import { screenFormat } from 'constants/screenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { routesByName } from '../../../../constants/routes'
import TermsAndConditions from 'pages/TermsAndConditionsDialog'
import PrivacyPolicyPage from 'pages/PrivacyPolicyDialog'
import useFlagManager from 'hooks/useFlagManager'

const configSize = {
	desktop: {
		size: 18,
		lineHeight: 22
	},
	mobile: {
		size: 10,
		lineHeight: 12
	}
}

const iconSize = {
	desktop: {
		width: 40,
		height: 40
	},
	mobile: {
		width: 18,
		height: 18
	}
}

const links = [
	{
		href: routesByName.blog,
		title: 'Blog'
	},
	{
		href: routesByName.about,
		title: 'About Us'
	},
	{
		href: routesByName.contact,
		title: 'Contact Us'
	},
	{
		href: routesByName.faq,
		title: 'FAQ'
	}
]

const socialNetworksLinks = [
	{
		iconName: 'facebook-logo',
		href: 'https://www.facebook.com/mytamildate/'
	},
	{
		iconName: 'instagram-logo',
		href: 'https://www.instagram.com/mytamildate/?hl=en'
	},
	{
		iconName: 'twitter',
		href: 'https://twitter.com/mytamildate'
	}
]

const Footer = ({ className, withSocialLinks, withPrivacyPolicy }) => {
	const format = useDetermineScreenFormat(breakpointsUp.md)
	const withTitle = useMemo(() => format !== screenFormat.mobile, [format])
	const termsDialog = useFlagManager()
	const policyDialog = useFlagManager()

	return (
		<footer className={clsx(classes.root, className)}>
			<Logo
				className={classes.logo}
				{...iconSize[format]}
				withTitle={withTitle}
			/>
			<div className={classes.content}>
				{links.map(({ href, title }, index) => (
					<Link
						{...configSize[format]}
						key={title + index}
						href={href}
						target={index === 0 ? '_blank' : undefined}
						rel={index === 0 ? 'noopener noreferrer' : undefined}
					>
						{title}
					</Link>
				))}
				{withPrivacyPolicy && (
					<>
						<span
							className={classes.infoText}
							role="presentation"
							onClick={termsDialog.turnIn}
						>
							Terms & Conditions
						</span>
						<span
							className={classes.infoText}
							role="presentation"
							onClick={policyDialog.turnIn}
						>
							Privacy
						</span>
					</>
				)}
				{withSocialLinks && (
					<div className={classes.iconContainer}>
						{socialNetworksLinks.map(({ iconName, href }, index) => (
							<Link
								key={iconName + index}
								href={href}
								target="_blank"
								rel="noopener noreferrer"
							>
								<SpriteIcon
									name={iconName}
									color={commonWhite}
									size={format === screenFormat.mobile ? 'sm' : 'md'}
								/>
							</Link>
						))}
					</div>
				)}
			</div>
			{withPrivacyPolicy && (
				<>
					<TermsAndConditions
						open={termsDialog.state}
						onClose={termsDialog.turnOff}
					/>
					<PrivacyPolicyPage
						open={policyDialog.state}
						onClose={policyDialog.turnOff}
					/>
				</>
			)}
		</footer>
	)
}

Footer.propTypes = {
	className: PropTypes.string,
	withSocialLinks: PropTypes.bool,
	withPrivacyPolicy: PropTypes.bool
}

Footer.defaultProps = {
	className: '',
	onClick: () => null,
	withSocialLinks: true,
	withPrivacyPolicy: true
}

export default Footer
