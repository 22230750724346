export const getMatches = ({ matches: { items } }) => items
export const getLastMatch = ({ matches: { lastElement } }) => lastElement
export const getHasMoreMatches = ({ matches }) => matches?.hasMore
export const getPersonForMatch = ({ matches }) => matches?.personForMatch

const matchesSelectors = {
	getMatches,
	getLastMatch,
	getHasMoreMatches,
	getPersonForMatch
}

export default matchesSelectors
