import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from 'components/ui/MenuElement/MenuElement.module.scss'
import Typography from 'components/ui/Typography'
import BaseCard from 'components/ui/controls/BaseCard'
import SpriteIcon from 'components/ui/SpriteIcon'
import clsx from 'clsx'
import useHistory from 'hooks/useHistory'

const MenuElement = ({
	icon = '',
	label = '',
	value = '',
	name = '',
	justify = 'between',
	classes: outerClasses = {},
	onClick,
	to = '',
	disabled
}) => {
	const history = useHistory()

	const handleClick = useCallback(
		name => {
			if (to) {
				history.push(to)
			}
			if (onClick) {
				onClick(name)
			}
		},
		[history, onClick, to]
	)

	return (
		<BaseCard
			className={clsx(
				classes.root,
				classes[justify],
				'user-select-none',
				outerClasses.root,
				{
					[classes.disabled]: disabled
				}
			)}
			name={name}
			onClick={handleClick}
		>
			<div
				className={clsx(classes.title, outerClasses.title, {
					[classes.disabled]: disabled
				})}
			>
				{icon ? (
					<SpriteIcon
						name={icon}
						className={clsx(classes.icon, outerClasses.icon, {
							[classes.disabled]: disabled
						})}
					/>
				) : null}
				<Typography
					className={clsx(classes.label, outerClasses.label, {
						[classes.disabled]: disabled
					})}
				>
					{label}
				</Typography>
			</div>

			{value ? (
				<Typography
					className={clsx(classes.value, outerClasses.value, {
						[classes.disabled]: disabled
					})}
				>
					{value}
				</Typography>
			) : null}
		</BaseCard>
	)
}

MenuElement.propTypes = {
	justify: PropTypes.oneOf(['center', 'justify']),
	classes: PropTypes.object,
	onClick: PropTypes.func,
	icon: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string
}

export default memo(MenuElement)
