import React, { useCallback } from 'react'
import classes from './SelectedPlanPage.module.scss'
import Heading from 'components/ui/Typography/Heading'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import { screenFormat } from 'constants/screenFormat'
import SpriteIcon from 'components/ui/SpriteIcon'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import Logo from 'components/ui/layout/Logo'
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowTutorial } from 'modules/profile/store/actions'
import { breakpointsUp } from 'constants/breakpoints'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import {
	getPreference,
	updatePreference
} from 'modules/preferences/store/actions'
import { getUserAge } from 'modules/profile/store/selectors'

const SelectedPlanPage = () => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const dispatch = useDispatch()
	const history = useHistory()
	const userAge = useSelector(getUserAge)

	const handleSubmitMyAccount = useCallback(() => {
		history.push(routesByName.main.discovery.root)
		if (userAge) {
			dispatch(updatePreference({ age_from: userAge > 23 ? userAge - 5 : 18 }))
			dispatch(updatePreference({ age_to: userAge < 100 ? userAge + 10 : 110 }))
			dispatch(getPreference())
		}

		if (format === screenFormat.mobile) {
			dispatch(toggleShowTutorial())
		}
	}, [dispatch, format, history, userAge])

	return (
		<div className={classes.dialog}>
			<header className={classes.headerDialog}>
				{format === screenFormat.mobile ? (
					<SpriteIcon
						name="arrow-back"
						className={classes.closeIcon}
						onClick={handleSubmitMyAccount}
						color="#E5E5E5"
					/>
				) : (
					<Logo variant="dark" withTitle />
				)}
			</header>
			<div className={classes.contentDialog}>
				<SpriteIcon name="email-success" className={classes.icon} />

				<Heading variant="heading-4" className={classes.title}>
					You are almost there
				</Heading>
				<Typography>
					You need to approve your email, we have already sent link to you. Please check it.
				</Typography>
			</div>

			<div className={classes.btnWrapper}>
				<Button
					type="submit"
					size="xl"
					shape="rounded"
					className={classes.btn}
					onClick={handleSubmitMyAccount}
				>
					Submit
				</Button>
			</div>
		</div>
	)
}

SelectedPlanPage.defaultProps = {
	className: '',
	open: false,
	onClose: () => null
}

export default SelectedPlanPage
