import BaseAxiosInstance from 'libs/axios/BaseAxiosInstance'
import appConfig from 'constants/appConfig.js'
import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'

export const verifyCreate = data =>
	BaseAxiosInstance.post(appConfig.apiPrefix + '/verification/create', data)

export const verifyChange = data =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + '/verification/change',
		data
	)

export const verifyResend = data =>
	BaseAxiosInstance.post(appConfig.apiPrefix + '/verification/resend', data)

export const signUp = data =>
	BaseAxiosInstance.post(appConfig.apiPrefix + '/auth/register', data)
