import React, { memo } from 'react'
import classes from './BaseAccordion.module.scss'
import Typography from 'components/ui/Typography'
import useFlagManager from 'hooks/useFlagManager.js'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'

const BaseAccordion = ({ children, header, classes: outerClasses = {} }) => {
	const accordion = useFlagManager(false)

	return (
		<div className={clsx(classes.root, outerClasses.root)}>
			<div
				role="presentation"
				className={classes.headerContainer}
				onClick={accordion.toggle}
			>
				<Typography className={classes.header}>{header}</Typography>
				<SpriteIcon
					name="arrow-down"
					className={clsx(classes.icon, {
						[classes.rotateArrow]: accordion.state
					})}
				/>
			</div>

			<div
				className={clsx({
					[classes.expandContainer]: !accordion.state,
					[classes.expandedContainer]: accordion.state
				})}
			>
				{accordion.state ? children : null}
			</div>
		</div>
	)
}

export default memo(BaseAccordion)
