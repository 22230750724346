import React from 'react'
import PropTypes from 'prop-types'
import Heading from 'components/ui/Typography/Heading'
import Typography from 'components/ui/Typography'
import classes from './InfoBlock.module.scss'
import clsx from 'clsx'

const InfoBlock = ({ image, title, text, link, imageAlign }) => {
	return (
		<div className={classes.root}>
			<img
				src={image}
				alt={title}
				className={classes.image}
				style={{ objectPosition: imageAlign }}
			/>
			<Heading variant="heading-7" className={classes.heading}>
				{title}
			</Heading>
			<Typography className={clsx(classes.text, 'mt-5')}>
				{text}{' '}
				<a
					className={classes.link}
					href={link}
					target="_blank"
					rel="noopener noreferrer"
				>
					Read more
				</a>
			</Typography>
		</div>
	)
}

InfoBlock.propTypes = {
	image: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	link: PropTypes.string,
	imageAlign: PropTypes.string
}

InfoBlock.defaultProps = {
	image: '',
	title: '',
	text: '',
	link: '',
	imageAlign: 'center'
}

export default InfoBlock
