import storage from 'redux-persist-indexeddb-storage'
import { purgeStoredState } from 'redux-persist'
export const indexedDb = storage('MTDF')

const version = 'v-0.6'

export const persistConfig = {
	root: {
		key: `root-${version}`,
		storage: indexedDb,
		whitelist: [
			'signIn',
			'signUp',
			'users',
			'likes',
			'requests',
			'subscriptionBilling',
			'matches',
			'app'
		]
	},
	app: {
		key: 'app',
		storage: indexedDb,
		whitelist: ['countToShowUpgradeBanner', 'isShownUpgradeBanner']
	},
	discovery: {
		key: 'discovery',
		storage: indexedDb,
		whitelist: ['applicants', 'searchedByPreferences', 'popupStatus', 'wave']
	},
	profile: {
		key: 'profile',
		storage: indexedDb,
		whitelist: ['user', 'config', 'finishedCreatingProfile']
	},
	messenger: {
		key: 'messenger',
		storage: indexedDb,
		blacklist: [
			// 'currentConversationId',
			// 'userForCreateConversation',
			'metaByConversationId',
			'hasMore'
		]
	},
	preferences: {
		key: 'preferences',
		storage: indexedDb,
		whitelist: ['wantGenderIdFlag']
	},
	emails: {
		key: 'emails',
		storage: indexedDb,
		whitelist: ['unsubscribeInfo']
	}
}

export const clearPersistStorage = () => {
	for (let config in persistConfig) {
		purgeStoredState(persistConfig[config])
	}
}
