import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './BaseSlider.module.scss'
import Slider from '@material-ui/core/Slider'
import Typography from 'components/ui/Typography'
import { sm } from 'utils/simulateEvent'

const sliderStyles = {
	rail: classes.rail,
	thumb: classes.thumb,
	track: classes.track
}

const BaseSlider = ({
	labelFormat,
	onChange = () => null,
	value = [],
	name,
	...props
}) => {
	const handleChange = useCallback(
		(e, value) => {
			onChange(sm(name, value))
		},
		[name, onChange]
	)

	return (
		<div className={classes.root}>
			<Typography>{labelFormat ? labelFormat(value) : value}</Typography>
			<Slider
				{...props}
				value={value}
				onChange={handleChange}
				classes={sliderStyles}
			/>
		</div>
	)
}

BaseSlider.propTypes = {
	className: PropTypes.string
}

BaseSlider.defaultProps = {
	className: ''
}

export default memo(BaseSlider)
