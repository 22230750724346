import React, { useCallback, useState } from 'react'
import classes from 'modules/auth/signUp/SignUp.module.scss'
import { Formik } from 'formik'
import View from 'modules/auth/signUp/View'
import Header from 'components/ui/layout/GuestHeader'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import Stepper from 'components/ui/Stepper'
import { screenFormat } from 'constants/screenFormat'
import { useDispatch } from 'react-redux'
import SuccessEmail from 'pages/SuccessEmail'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import FormikFieldInput from 'components/wrappers/formik/FormikFieldInput.js'
import yup from 'libs/yup.js'
import { putEmail } from 'modules/profile/services.js'
import { updateMe } from 'modules/profile/store/actions.js'
import { breakpointsUp } from 'constants/breakpoints'
import BaseImage from 'components/wrappers/ui/BaseImage'
import SpriteIcon from 'components/ui/SpriteIcon'
import { gray1 } from 'constants/colors'
import { toggleLoader } from 'modules/app/store/actions'
import useFlagManager from 'hooks/useFlagManager'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'
import {_isEmpty} from '../../../utils/lodash';

const emailTooltip = {
	text:
		'Hotmail/Outlook e-mails are not optimized for our platform and you might miss important e-mails. ' +
		'Try another e-mail service to get the best experience!'
}

const EnterEmail = () => {
	const [successScreen, toggleShowSuccessScreen] = useState(false)
	const history = useHistory()
	const dispatch = useDispatch()
	const [userValues, setUserValues] = useState({})
	const format = useDetermineScreenFormat(breakpointsUp.md)
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const handleSubmit = useCallback(
		async values => {
			try {
				dispatch(toggleLoader())
				await putEmail(values)
				setUserValues(values)
				toggleShowSuccessScreen(true)
			} catch (e) {
				console.dir(e)
				const errorMsg = !_isEmpty(e?.validation)
						? e.validationErrors.identity.join('\n')
						: e.getException
				setError(errorMsg)
				infoDialog.turnIn()
			} finally {
				dispatch(toggleLoader())
			}
		},
		[dispatch, infoDialog]
	)

	const handleClickBack = useCallback(() => {
		history.push(routesByName.home)
	}, [history])

	const handleClickContinue = useCallback(() => {
		dispatch(updateMe(userValues))
		history.push(routesByName.createProfile)
	}, [dispatch, history, userValues])

	return (
		<>
			<div className={classes.root}>
				<div className={classes.form}>
					<Header
						hasBack={format === screenFormat.mobile}
						variantLogo="dark"
						hideLogoOnMobile
						onClickBackIcon={handleClickBack}
					/>

					<div className={classes.wrapper}>
						<div>
							{format === screenFormat.desktop && (
								<SpriteIcon
									name="arrow-back"
									onClick={handleClickBack}
									color={gray1}
									size="md"
									className={classes.backIcon}
								/>
							)}
							<Stepper
								className={classes.stepper}
								count={3}
								step={2}
								hideOnMobile
							/>
						</div>

						<Formik
							initialValues={{
								email: ''
							}}
							validationSchema={yup.object().shape({
								email: yup
									.string()
									.email('Please enter a valid email address.')
									.required('Email is required')
							})}
							onSubmit={handleSubmit}
							enableReinitialize
						>
							<View
								title="Enter Your E-mail"
								description=""
								Input={props => (
									<FormikFieldInput
										{...props}
										// type="email"
										autocomplete="on"
										rootClass="mt-3"
										name="email"
										placeholder="example@example.com"
										tooltip={emailTooltip}
										showSuccessMessage
									/>
								)}
								format={format}
								secondaryActions={<div />}
							/>
						</Formik>
					</div>
				</div>

				{format !== screenFormat.mobile && (
					<BaseImage
						src="assets/couplephoto.jpg"
						classes={{ root: classes.img, wrapper: classes.imageWrapper }}
					/>
				)}
			</div>

			<SuccessEmail
				open={successScreen}
				onClickContinue={handleClickContinue}
			/>

			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.turnOff}
				type={'error'}
				title={'Error'}
				// body={error}
				body={
					<>
						<p>
							{error}
							<a
									href="https://mytamildate.com/sign-in"
									className={classes.link}
							>
								here.
							</a>
						</p>
					</>
				}
			/>
		</>
	)
}

EnterEmail.propTypes = {}

EnterEmail.defaultProps = {}

export default EnterEmail
