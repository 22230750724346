import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import classes from 'modules/notifications/Notifications.module.scss'
import MessagesHeader from 'modules/notifications/matches/components/MessagesHeader'
import { routesByName } from 'constants/routes'
import React from 'react'
import RequestSwitch from 'modules/notifications/requests/RequestSwitch.js'
// import componentLoader from 'utils/componentLoader'
import MatchesPage from 'modules/notifications/matches'
import LikesSwitch from './likes/LikesSwitch'

// const LikesPage = React.lazy(() => import('modules/notifications/likes'))
// const MatchesPage = React.lazy(() => import('modules/notifications/matches'))

// const LikesPage = React.lazy(() =>
// 	componentLoader(() => import('modules/notifications/likes'))
// )
// const MatchesPage = React.lazy(() =>
// 	componentLoader(() => import('modules/notifications/matches'))
// )

// const Task = React.lazy(() => componentLoader(() => import(path), attemptsLeft));

const NotificationsLeftSidebar = () => {
	const { path } = useRouteMatch()
	return (
		<div className={classes.content}>
			<MessagesHeader />
			<Switch>
				<Route exact path={path}>
					<Redirect to={routesByName.main.notifications.matches.root} />
				</Route>

				<Route path={routesByName.main.notifications.matches.root}>
					<MatchesPage />
				</Route>
				<Route
					path={routesByName.main.notifications.likes.root}
					component={LikesSwitch}
				/>
				<Route
					path={routesByName.main.notifications.requests.root}
					component={RequestSwitch}
				/>
			</Switch>
		</div>
	)
}

export default NotificationsLeftSidebar
