const root = 'discovery'

export const getCurrentApplicantId = ({ [root]: { applicants } }) =>
	applicants[0]

export const getApplicants = ({ [root]: { applicants } }) => applicants

export const getSearchedByPreferences = ({ discovery }) =>
	discovery?.searchedByPreferences

export const getUsersAreOver = ({ discovery }) => discovery?.usersAreOver

export const getCurrentWave = ({ discovery }) => discovery?.wave

const discoverySelectors = {
	getCurrentApplicantId,
	getApplicants,
	getSearchedByPreferences,
	getUsersAreOver,
	getCurrentWave
}

export default discoverySelectors
