import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useHistory from 'hooks/useHistory'
import { useDispatch, useSelector } from 'react-redux'
import {getCurrentPlan, getPaymentInfoById} from 'modules/subscriptionBilling/store/selectors'
import { toggleLoader } from 'modules/app/store/actions'
import { getPaymentInfo } from 'modules/subscriptionBilling/store/actions'
import { routesByName } from 'constants/routes'
import { updatePaymentMethod } from 'modules/subscriptionBilling/services'
import useFlagManager from 'hooks/useFlagManager'

const PaymentMethodContainer = ({ children, mode }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { state = [] } = useLocation()
	const paymentInfo = useSelector(getPaymentInfoById(state?.pm))
	const currentPlan = useSelector(getCurrentPlan)
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const handleDeletePM = useCallback(
		async event => {
			event.stopPropagation()
			try {
				dispatch(toggleLoader())
				await updatePaymentMethod(null)
				await dispatch(getPaymentInfo())

				history.replace(routesByName.main.settings.billing.root)
			} catch (e) {
				setError(e.message)
				infoDialog.turnIn()
			} finally {
				dispatch(toggleLoader())
			}
		},
		[dispatch, history, infoDialog]
	)

	const handleSubmit = useCallback(async () => {
		try {
			dispatch(toggleLoader())

			await dispatch(getPaymentInfo())
			history.goBack()
		} catch (e) {
			console.dir(e)
		} finally {
			dispatch(toggleLoader())
		}
	}, [dispatch, history])

	const props = useMemo(
		() => ({
			onDeletePM: state?.pm ? handleDeletePM : undefined,
			onSubmit: handleSubmit,
			currentPlan: currentPlan,
			billingState: paymentInfo?.billingDetails,
			infoDialog: {
				state: infoDialog.state,
				close: infoDialog.turnOff,
				error: error
			}
		}),
		[
			error,
			handleDeletePM,
			handleSubmit,
			infoDialog.state,
			infoDialog.turnOff,
			currentPlan,
			paymentInfo.billingDetails,
			state.pm
		]
	)

	return children(props)
}

export default PaymentMethodContainer
