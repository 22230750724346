import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
	birthday,
	ageFrom,
	ageTo,
	drink,
	familyPlans,
	growth,
	heightFrom,
	heightTo,
	locations,
	locationTypeField,
	locationSelectedIdField,
	job,
	kid,
	religion,
	smoke,
	study,
	wantGender,
	locationId,
	locationType,
	horoscope
} from 'modules/profile/createProfile/config.js'
import { configFields } from 'modules/profile/store/constants.js'
import { useDispatch, useSelector } from 'react-redux'
import * as profileSelectors from 'modules/profile/store/selectors.js'
import useFlagManager from 'hooks/useFlagManager.js'
import _useEffect from 'hooks/_useEffect.js'
import * as preferenceSelectors from './store/selectors.js'
import { updatePreference } from 'modules/preferences/store/actions.js'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import { screenFormat } from 'constants/screenFormat.js'
import useHistory from 'hooks/useHistory.js'
import { _set, _get, _isEmpty } from 'utils/lodash'
import { getPreference } from 'modules/preferences/store/actions'
import * as types from 'modules/preferences/store/constants'
import useUpgradeNotification from 'hooks/useUpgradeNotification'
import {
	getDiscoveryUsers,
	resetPopupStatus,
	setUsersAreOver
} from 'modules/discovery/store/actions'
import _useDidMount from 'hooks/useDidMount'

const titles = {
	[birthday]: 'Age',
	[wantGender]: 'I’m looking for',
	[religion]: 'Religion',
	[study]: 'Education',
	[job]: 'Career',
	[growth]: 'Height',
	[kid]: 'Kids',
	[smoke]: 'Smoking',
	[drink]: 'Drinking',
	[familyPlans]: 'Family Plans',
	locations: 'Locations',
	[horoscope]: 'Horoscope'
}

const PreferenceContainer = ({ children }) => {
	const editFilter = useFlagManager(false)
	const [filtersState, setFilterState] = useState({})
	const [currentEditField, setCurrentEditField] = useState(birthday)
	const filtersFromStore = useSelector(preferenceSelectors.getPreference)
	// const filtersInitialState = useSelector(
	// 	preferenceSelectors.getFiltersInitialState
	// )
	const locationsArray = useSelector(
		preferenceSelectors.getSelectedLocationText
	)

	const dispatch = useDispatch()
	const history = useHistory()
	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile

	const profileConfig = useSelector(profileSelectors.getProfileConfig)
	const upgradeNotification = useUpgradeNotification()
	const isSubscribe = upgradeNotification.isSubscribe
	const preferencesUpdated = useRef(false)

	_useDidMount(() => {
		preferencesUpdated.current = false
	})

	_useEffect(() => {
		setFilterState(filtersFromStore)
	}, [filtersFromStore])

	const getMultipleOption = useCallback(
		(configField, value) => {
			if (!value || !value.length) {
				return 'Open to all'
			}

			if (value.length > 3) {
				return 'More than 3 chosen'
			}

			return profileConfig[configField].options
				.filter(option => value.includes(option.value))
				.map(option => option.label)
				.join(', ')
		},
		[profileConfig]
	)

	const wantGenderId = useSelector(({ profile }) => profile.user.wantGenderId)
	const wantGenderIdFlag = useSelector(
		({ preference }) => preference.wantGenderIdFlag
	)

	if (
		!wantGenderIdFlag &&
		!_isEmpty(filtersState) &&
		!filtersState?.[wantGender]?.length
	) {
		dispatch(updatePreference({ [wantGender]: [wantGenderId] }))
		dispatch({
			type: types.UPDATE_WANT_GENDER_FLAG,
			payload: true
		})
	}

	const commonFilterOptions = useMemo(
		() => [
			{
				label: 'I’m interested in',
				icon: 'gender',
				value: getMultipleOption(
					configFields.wantGenders,
					filtersState[wantGender]
				),
				name: wantGender
			},
			{
				label: 'Age range',
				icon: 'calendar',
				value: (filtersState[birthday] || [])?.join('-'),
				name: birthday
			},
			{
				label: 'Religion',
				icon: 'religion',
				value: getMultipleOption(
					configFields.religions,
					filtersState[religion]
				),
				name: religion
			}
			// {
			// 	label: 'Horoscope',
			// 	icon: 'horoscope',
			// 	value: getMultipleOption(
			// 		configFields.horoscope,
			// 		filtersState[horoscope]
			// 	),
			// 	name: horoscope
			// }
		],
		[filtersState, getMultipleOption]
	)

	const premiumFilterOptions = useMemo(
		() => [
			{
				label: 'Location',
				icon: 'map-mark',
				value: isSubscribe ? (
					filtersState.locations?.type[0] !== 0 && locationsArray.length ? (
						locationsArray.length > 1 ? (
							<div>
								{_get(locationsArray, '[0].text', '')}
								<br />
								{_get(locationsArray, '[1].text', '')}
								{locationsArray.length > 2 ? '...' : ''}
							</div>
						) : (
							_get(locationsArray, '[0].text', '')
						)
					) : (
						'Any'
					)
				) : (
					'Any'
				),
				name: locations
			},
			{
				label: 'Education',
				icon: 'education',
				value: isSubscribe
					? getMultipleOption(configFields.educations, filtersState[study])
					: 'Open to all',
				name: study
			},
			// {
			// 	label: 'Job',
			// 	icon: 'job',
			// 	value: isSubscribe
			// 		? getMultipleOption(configFields.jobs, filtersState[job])
			// 		: 'Open to all',
			// 	name: job
			// },
			// {
			// 	label: 'Height',
			// 	icon: 'tall',
			// 	value: isSubscribe
			// 		? filtersState[growth]
			// 				?.map(value => profileConfig[configFields.growth].original[value])
			// 				?.join('-')
			// 		: '3’0”(91.44cm)-7’0” (213.36cm)',
			// 	name: growth
			// },
			{
				label: 'Smoking',
				icon: 'smoke',
				value: isSubscribe
					? getMultipleOption(configFields.smoke, filtersState[smoke])
					: 'Open to all',
				name: smoke
			},
			{
				label: 'Drinking',
				icon: 'drink',
				value: isSubscribe
					? getMultipleOption(configFields.drink, filtersState[drink])
					: 'Open to all',
				name: drink
			},
			{
				label: 'Children',
				icon: 'kids',
				value: isSubscribe
					? getMultipleOption(configFields.haveKids, filtersState[kid])
					: 'Open to all',
				name: kid
			},
			{
				label: 'Family plans',
				icon: 'family-plans',
				value: isSubscribe
					? getMultipleOption(
							configFields.familyPlans,
							filtersState[familyPlans]
					  )
					: 'Open to all',
				name: familyPlans
			}
		],
		[isSubscribe, filtersState, locationsArray, getMultipleOption]
	)

	const handleChangeFilter = useCallback(({ target: { value, name } }) => {
		setFilterState(values => _set({ ...values }, name, value))
		preferencesUpdated.current = true
	}, [])

	const handleOpenCommonFilter = useCallback(
		async values => {
			await setCurrentEditField(values)
			editFilter.turnIn()
		},
		[editFilter]
	)

	const handleOpenPremiumFilter = useCallback(
		async values => {
			if (!upgradeNotification.isSubscribe) {
				return upgradeNotification.unlockFeatures.controller.turnIn()
			} else {
				handleOpenCommonFilter(values)
			}
		},
		[
			handleOpenCommonFilter,
			upgradeNotification.isSubscribe,
			upgradeNotification.unlockFeatures.controller
		]
	)

	const handleCloseEditElement = useCallback(
		async name => {
			editFilter.turnOff()
			if (preferencesUpdated.current) {
				switch (name) {
					case wantGender:
						await dispatch(
							updatePreference({
								[wantGender]: Array.isArray(filtersState[wantGender])
									? filtersState[wantGender]
									: [filtersState[wantGender]]
							})
						)
						break
					case birthday:
						await dispatch(
							updatePreference({ [ageFrom]: filtersState[birthday][0] })
						)
						await dispatch(
							updatePreference({ [ageTo]: filtersState[birthday][1] })
						)
						break
					case growth:
						await dispatch(
							updatePreference({ [heightFrom]: filtersState[growth][0] })
						)
						await dispatch(
							updatePreference({ [heightTo]: filtersState[growth][1] })
						)
						break
					case locations:
						await dispatch(
							updatePreference({
								[locationId]: filtersState[locations][locationSelectedIdField]
							})
						)
						await dispatch(
							updatePreference({
								[locationType]: filtersState[locations][locationTypeField]
							})
						)
						break
					default:
						await dispatch(updatePreference({ [name]: filtersState[name] }))
						break
				}

				dispatch(getPreference())
			}
		},
		[dispatch, editFilter, filtersState]
	)

	const handleSaveFilters = useCallback(() => {
		history.goBack()
		if (preferencesUpdated.current) {
			dispatch(getPreference())
			dispatch(resetPopupStatus())
			dispatch(getDiscoveryUsers({ wave: 1 }))
			dispatch(setUsersAreOver(false))
		}
	}, [dispatch, history])

	const handleResetFilters = useCallback(async () => {
		preferencesUpdated.current = true
		// dispatch(resetPreferences())
		// Object.entries(filtersInitialState).map(async ([key, value]) => {
		// 	await dispatch(updatePreference({ [key]: value }))
		// })
		// dispatch(getPreference())
		// dispatch(getDiscoveryUsers())
	}, [])

	const handleClickBack = useCallback(() => {
		handleSaveFilters()
	}, [handleSaveFilters])

	const props = useMemo(
		() => ({
			callbacks: {
				onSaveFilters: handleSaveFilters,
				onCancelFilters: handleResetFilters,
				onClickBack: handleClickBack
			},
			editFilter: {
				open: editFilter.state,
				onClose: handleCloseEditElement,
				onOpenCommonFilter: handleOpenCommonFilter,
				onOpenPremiumFilter: handleOpenPremiumFilter,
				title: titles[currentEditField],
				name: currentEditField,
				value: filtersState[currentEditField],
				onChange: handleChangeFilter
			},
			data: {
				commonFilterOptions,
				premiumFilterOptions,
				isMobile,
				config: profileConfig,
				upgradeNotification: upgradeNotification
			}
		}),
		[
			upgradeNotification,
			commonFilterOptions,
			currentEditField,
			editFilter.state,
			filtersState,
			handleChangeFilter,
			handleCloseEditElement,
			handleOpenCommonFilter,
			handleOpenPremiumFilter,
			handleSaveFilters,
			handleResetFilters,
			isMobile,
			premiumFilterOptions,
			profileConfig,
			handleClickBack
		]
	)

	return children(props)
}

export default PreferenceContainer
