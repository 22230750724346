import classes from './NotificationPlaceholder.module.scss'
import { ReactComponent as MatchesIcon } from 'assets/staticIcons/matches-icon.svg'
import React from 'react'
import Typography from 'components/ui/Typography'

const NotificationPlaceholder = ({ text }) => (
	<div className={classes.root}>
		<MatchesIcon className="mb-4" />
		<Typography className={classes.text}>{text}</Typography>
	</div>
)

export default NotificationPlaceholder
