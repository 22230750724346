import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, ReactReduxContext } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import 'styles/index.scss'
import { ReactComponent as Sprite } from 'assets/sprite.svg'
import { history, persistor, store } from 'store/store'
import App from 'App'
import appConfig from 'constants/appConfig'
import { PersistGate } from 'redux-persist/integration/react'
import ReduxToastr from 'react-redux-toastr/lib/ReduxToastr'
import ControlledLoader from 'components/ui/ControlledLoader/ControlledLoader'
import MobileTutorial from 'pages/MobileTutorial'
import * as serviceWorker from 'serviceWorker/serviceWorker'
import ErrorBoundary from 'errors/ErrorBoundary.js'
import CancelApi from 'libs/axios/CancelApi.js'
import ReduxConfirmDialog from './components/ui/Dialogs/ReduxConfirmDialog'

export const cancelApi = new CancelApi()

ReactDOM.render(
	<Provider store={store} context={ReactReduxContext}>
		<ConnectedRouter history={history} context={ReactReduxContext}>
			<PersistGate loading={null} persistor={persistor}>
				<ErrorBoundary>
					<Sprite />
					<App />
					<MobileTutorial />
					<ControlledLoader />
				</ErrorBoundary>
				<ReduxToastr position="bottom-left" preventDuplicates />
				<ReduxConfirmDialog />
			</PersistGate>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
)

if (!appConfig.isDev) {
	serviceWorker.unregister()
} else {
	if (module.hot) {
		module.hot.accept()
	}
}
