import React, { memo, useMemo } from 'react'
import classes from './SentItem.module.scss'
import Typography from 'components/ui/Typography'
import SpriteIcon from 'components/ui/SpriteIcon'
import { primaryColor, secondaryColor } from 'constants/colors'
import Button from 'components/ui/Button'
import { unix } from 'moment'
import { _get } from 'utils/lodash.js'
import { useSelector } from 'react-redux'
import { getUserById } from 'modules/users/selectors.js'
import clsx from 'clsx'

const SentItem = ({ onClick = () => null, smooth, ...props }) => {
	const participant = useMemo(
		() => props.participants.find(({ userId }) => userId !== props.ownerId),
		[props.ownerId, props.participants]
	)

	const user = useSelector(getUserById(participant.userId))

	return (
		<div
			role="presentation"
			className={clsx(classes.root, { [classes.smooth]: smooth })}
			onClick={onClick}
			data-id={props.id}
		>
			<Typography className={classes.sentAt}>
				{unix(_get(props.lastMessage, 'updatedAt', '') / 1000).fromNow()}
			</Typography>

			<div className={classes.content}>
				<SpriteIcon
					name="message-contained"
					color={primaryColor}
					className={classes.messageIcon}
				/>
				<Typography
					className={classes.body}
				>{`You requested to chat with ${user.firstName}`}</Typography>
				<Button shape="rounded" size="xs" color="white" className={classes.btn}>
					<SpriteIcon name="person" color={secondaryColor} />
				</Button>
			</div>
		</div>
	)
}

export default memo(SentItem)
