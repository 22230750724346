import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearMessengerMeta,
	// getConversations,
	setCurrentConversation,
	setUserForCreateConversation
} from 'modules/conversation/store/actions.js'
import useConnectToEchoServer from 'libs/echo/useConnectToEchoServer'
import { MESSAGE_SENT } from 'libs/echo/events'
import channelsByName from 'libs/echo/channels'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { screenFormat } from 'constants/screenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import useHistory from 'hooks/useHistory.js'
import { routesByName } from 'constants/routes.js'
import matchesSelectors from 'modules/notifications/matches/store/selectors'
import { getIdFromDataAttribute } from 'utils/getIdFromDataAttribute.js'
import {
	getConversations,
	setConversation,
	setIsLastMessageRead
} from 'modules/conversation/store/actions'
import _useDidMount from 'hooks/useDidMount'
import { getMatches } from 'modules/notifications/matches/store/actions'
import conversationSelectors from 'modules/conversation/store/selectors'
import useUpgradeNotification from 'hooks/useUpgradeNotification'

const MatchesContainer = ({ children }) => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const history = useHistory()
	const dispatch = useDispatch()
	const matches = useSelector(matchesSelectors.getMatches)
	const upgradeNotification = useUpgradeNotification()
	const [isLoadingNewConversations, setLoading] = useState(false)

	const handleClickConversation = useCallback(
		async event => {
			if (!upgradeNotification.isSubscribe) {
				return upgradeNotification.unlockFeatures.controller.turnIn()
			}
			const id = getIdFromDataAttribute(event)
			await dispatch(setCurrentConversation(id))
			if (format === screenFormat.mobile) {
				history.push(routesByName.conversation.root)
				dispatch(setIsLastMessageRead(id, true))
			} else {
				history.push(routesByName.main.notifications.matches.conversation)
			}
		},
		[
			dispatch,
			format,
			history,
			upgradeNotification.isSubscribe,
			upgradeNotification.unlockFeatures.controller
		]
	)

	const conversations = useSelector(conversationSelectors.getConversations)
	const itemsMap = useSelector(({ users: { itemsMap } }) => itemsMap)
	const hasMoreConversations = useSelector(
		conversationSelectors.getHasMoreConversations
	)
	const myId = useSelector(({ profile: { user } }) => user.id)

	const handleReceivedMessage = useCallback(
		data => {
			dispatch(setConversation(data))
		},
		[dispatch]
	)

	useConnectToEchoServer({
		channelName: channelsByName.conversationsByUser(myId),
		events: {
			[MESSAGE_SENT]: handleReceivedMessage
		}
	})

	_useDidMount(() => {
		dispatch(getConversations())
		dispatch(getMatches())
	})
	const loadMoreConversations = useCallback(async () => {
		setLoading(true)
		try {
			await dispatch(
				getConversations({ offset: conversations.length, limit: 20 }, 'push')
			)
			setLoading(false)
		} catch (e) {
			console.dir(e)
		}
	}, [conversations.length, dispatch])

	const loadMoreMatches = useCallback(async () => {
		try {
			await dispatch(getMatches({ offset: matches.length, limit: 20 }, 'push'))
		} catch (e) {
			console.dir(e)
		}
	}, [matches.length, dispatch])

	const conversationsWithBlockStatus = useMemo(() => {
		return conversations?.map(conversation => {
			const participantId = conversation?.participants?.find(
				({ userId }) => userId !== myId
			)?.userId
			conversation.is_blocked = itemsMap[participantId]?.is_blocked
			return conversation
		})
	}, [conversations, itemsMap, myId])

	const blockedConversations = useMemo(() => {
		return conversationsWithBlockStatus?.filter(
			conversation => conversation?.is_blocked === true
		)
	}, [conversationsWithBlockStatus])

	const restConversations = useMemo(() => {
		return conversationsWithBlockStatus?.filter(
			conversation => conversation?.is_blocked === false
		)
	}, [conversationsWithBlockStatus])

	const filteredConversations = [...restConversations, ...blockedConversations]

	const handleClickMatchPerson = useCallback(
		async event => {
			if (!upgradeNotification.isSubscribe) {
				return upgradeNotification.unlockFeatures.controller.turnIn()
			}
			try {
				const id = getIdFromDataAttribute(event)
				dispatch(clearMessengerMeta())
				dispatch(setUserForCreateConversation(id))

				if (format === screenFormat.mobile) {
					history.push(
						routesByName.main.notifications.matches.mobileConversationCreate
					)
				} else {
					history.push(
						routesByName.main.notifications.matches.conversationCreate
					)
				}
			} catch (e) {
				console.dir(e)
			}
		},
		[
			dispatch,
			format,
			history,
			upgradeNotification.isSubscribe,
			upgradeNotification.unlockFeatures.controller
		]
	)

	const hasMoreMatches = useSelector(matchesSelectors.getHasMoreMatches)

	const props = useMemo(
		() => ({
			matchList: matches,
			onClickMatchPerson: handleClickMatchPerson,
			conversationList: filteredConversations,
			onClickConversation: handleClickConversation,
			loadMoreConversations: loadMoreConversations,
			loadMoreMatches: loadMoreMatches,
			isLoadingNewConversations,
			hasMoreConversations,
			hasMoreMatches,
			upgradeNotification
		}),
		[
			filteredConversations,
			handleClickConversation,
			handleClickMatchPerson,
			hasMoreConversations,
			hasMoreMatches,
			isLoadingNewConversations,
			loadMoreConversations,
			loadMoreMatches,
			matches,
			upgradeNotification
		]
	)

	return children(props)
}

export default MatchesContainer
