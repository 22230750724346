import React, { useCallback } from 'react'
import classes from './ContactPage.module.scss'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import GuestHeader from 'components/ui/layout/GuestHeader'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import { Form, Formik } from 'formik'
import yup from 'libs/yup'
import FormikFieldInput from 'components/wrappers/formik/FormikFieldInput'
import FormikFieldTextArea from 'components/wrappers/formik/FormikFieldTextArea'
import { postContactForm } from 'pages/ContactPage/services'
import FormikFieldNativeSelect from 'components/wrappers/formik/FormikFieldNativeSelect'
import infoToastr from 'libs/toastr/infoToastr'

const selectOptions = [
	{
		value: 1,
		label: 'I have a technical issue'
	},
	{
		value: 2,
		label: 'I have a question'
	},
	{
		value: 3,
		label: 'I have a billing issue'
	}
]

const ContactPage = () => {
	const initialValues = {
		name: '',
		email: '',
		issue: '1',
		message: ''
	}

	const name = 'name'
	const email = 'email'
	const issue = ''
	const message = 'message'

	const handleSubmit = useCallback(data => {
		const issueItem = selectOptions.find(
			({ value }) => value === Number(data['issue'])
		)
		// console.log({ ...data, issue: issueItem.label })
		postContactForm({ ...data, issue: issueItem.label })
		infoToastr('Your feedback has been submitted!')
	}, [])

	return (
		<div className={classes.root}>
			<BaseScrollbars>
				<GuestHeader variantLogo="light" classes={{ root: classes.header }} />
				<div className={classes.content}>
					<div className={classes.form}>
						<p className={classes.title}>Why are you contacting us today?</p>
						<Formik
							initialValues={initialValues}
							onSubmit={handleSubmit}
							validationSchema={yup.object().shape({
								[name]: yup.string().required('Field is required'),
								[email]: yup
									.string()
									.email('Enter valid email')
									.required('Field is required'),
								[issue]: yup.string().required('Field is required'),
								[message]: yup.string().required('Field is required')
							})}
							enableReinitialize
						>
							<Form className={classes.form}>
								<FormikFieldInput
									inputClass={classes.input}
									placeholder="Name"
									name={'name'}
									errorMsgClass={classes.error}
								/>
								<FormikFieldInput
									inputClass={classes.input}
									placeholder="Email"
									name={'email'}
									errorMsgClass={classes.error}
								/>
								<FormikFieldNativeSelect
									className={clsx(classes.input, classes.select)}
									options={selectOptions}
									name={'issue'}
									hasArrow
									arrowClass={classes.arrow}
								/>
								{/*	{selectOptions.map((option, index) => (*/}
								{/*		<option value={index} key={option + index}>*/}
								{/*			{option}*/}
								{/*		</option>*/}
								{/*	))}*/}
								{/*</FormikFieldNativeSelect>*/}
								<p className={classes.description}>
									When reporting a bug - to help us solve your issue faster,
									please provide us with the following information:
								</p>
								<p className={classes.description}>
									- Operating System
									<br />
									- The browser being used
									<br />
									- The email address used for signing up
									<br />
									- The exact error message / screenshot of the issue (if
									possible) or a written description of what happens
									<br />- Approximate date / time of occurrence
								</p>
								<FormikFieldTextArea
									className={clsx(classes.input, classes.textarea)}
									placeholder="Message"
									rows="3"
									name={'message'}
									errorMsgClass={classes.error}
								/>
								<Button shape="rounded" className={classes.btn} type="submit">
									Submit
								</Button>
							</Form>
						</Formik>
					</div>
				</div>
			</BaseScrollbars>
		</div>
	)
}

export default ContactPage
