import React, { useCallback, useMemo, useState } from 'react'
import MenuElement from 'components/ui/MenuElement/MenuElement'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Typography from 'components/ui/Typography'
import BaseInput from 'components/ui/controls/BaseInput'
import Button from 'components/ui/Button'
import useFlagManager from 'hooks/useFlagManager'
import classes from './PromoCode.module.scss'
import clsx from 'clsx'
import { checkPromo } from 'modules/subscriptionBilling/services'
import { _get, _isEmpty } from 'utils/lodash'
import _useDidMount from 'hooks/useDidMount'
import { toggleLoader } from 'modules/app/store/actions'
import { useDispatch } from 'react-redux'
import { setCurrentPlan } from '../../modules/subscriptionBilling/store/actions'

const Dialog = ({
	open,
	onClose,
	onSubmit,
	onSetDiscount,
	planId,
	promo,
	error,
	checked
}) => {
	const dispatch = useDispatch()

	const handleApplyPromoCode = useCallback(async () => {
		try {
			dispatch(toggleLoader())

			let status = false

			if (promo.value) {
				const { result } = await checkPromo({
					promo_code: promo.value,
					price_id: planId
				})
				status = result
			}

			if (!status) {
				error.setValue('* Please enter a valid promo code.')
			} else {
				checked.setValue(true)
				onSubmit(promo.value)

				let percent = _get(status, 'percent_off', 0)
				onSetDiscount(percent / 100)
			}
		} catch (e) {
			console.dir(e)
			error.setValue('* Please enter a valid promo code.')
		} finally {
			dispatch(toggleLoader())
		}
	}, [dispatch, promo.value, planId, error, checked, onSubmit, onSetDiscount])

	_useDidMount(() => {
		if (!_isEmpty(promo.value)) {
			handleApplyPromoCode()
		}
	})

	return (
		<BaseDialog open={open} onClose={onClose}>
			<div className={classes.content}>
				<Typography className={classes.title}>
					{!checked.value
						? 'Redeem your promo code'
						: 'Your promo code has been submitted!'}
				</Typography>
				{!checked.value ? (
					<div className={classes.inputContainer}>
						<BaseInput
							placeholder="Enter promo code"
							value={promo.value}
							onChange={({ target: { value } }) => promo.setValue(value)}
							// touched
							error={error.value}
						/>
						<Typography className={classes.error}>{error.value}</Typography>
					</div>
				) : (
					<Typography className={classes.promo}>{promo.value}</Typography>
				)}

				<div className={classes.actions}>
					{!checked.value ? (
						<>
							<Button
								shape="rounded"
								onClick={handleApplyPromoCode}
								className={clsx(classes.btn)}
							>
								Submit
							</Button>
							<Button
								variant="outlined"
								className={clsx(classes.btn, classes.closeBtn)}
								shape="rounded"
								onClick={onClose}
							>
								Cancel
							</Button>
						</>
					) : (
						<Button
							shape="rounded"
							onClick={onClose}
							className={clsx(classes.btn)}
						>
							Okay
						</Button>
					)}
				</div>
			</div>
		</BaseDialog>
	)
}

const usePromoCode = (planId, code = '') => {
	const dialog = useFlagManager(false)
	const [promo, setPromo] = useState(code)
	const [error, setError] = useState('')
	const [discount, setDiscount] = useState(null)
	const [checked, setChecked] = useState(false)
	const dispatch = useDispatch()

	const handleSetDiscount = useCallback(value => setDiscount(value), [])

	const handleResetPromoCode = useCallback(() => {
		setPromo('')
		setDiscount(null)
		setError('')
		setChecked(false)
	}, [])

	const handleResetCodeForPlan = useCallback(() => {
		dispatch(setCurrentPlan(planId))
	}, [dispatch, planId])

	const props = useMemo(
		() => ({
			promo: {
				value: promo,
				setValue: setPromo
			},
			error: {
				value: error,
				setValue: setError
			},
			checked: {
				value: checked,
				setValue: setChecked
			}
		}),
		[checked, error, promo]
	)

	const Component = useMemo(
		() => (
			<>
				<MenuElement
					icon="promo-code"
					label={!checked ? 'Redeem your promo code' : 'Promo Code Applied!'}
					justify="center"
					onClick={dialog.turnIn}
					classes={{
						icon: clsx(classes.icon, { [classes.active]: checked }),
						root: { [classes.menuElementRoot]: checked },
						label: clsx(classes.menuElementLabel, {
							[classes.active]: checked
						})
					}}
				/>
				<Dialog
					open={dialog.state}
					onClose={dialog.turnOff}
					onSubmit={setPromo}
					onSetDiscount={handleSetDiscount}
					planId={planId}
					{...props}
				/>
			</>
		),
		[
			checked,
			dialog.state,
			dialog.turnIn,
			dialog.turnOff,
			handleSetDiscount,
			planId,
			props
		]
	)

	return {
		Component,
		value: promo,
		discount,
		resetCode: handleResetPromoCode,
		resetCodeForPlan: handleResetCodeForPlan
	}
}

export default usePromoCode
