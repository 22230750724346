import React from 'react'
import { toastr } from 'react-redux-toastr'
import ToastrIconContainer from 'libs/toastr/ToastrIconContainer/ToastrIconContainer'
import SpriteIcon from 'components/ui/SpriteIcon'

const infoToastr = (message, title = '', options = {}) => {
	toastr.info(title, message, {
		icon: (
			<ToastrIconContainer type="info">
				<SpriteIcon name="info" size="sm" />
			</ToastrIconContainer>
		),
		timeOut: 300 * 1000,
		position: 'top-center',
		transitionIn: 'fadeIn',
		transitionOut: 'fadeOut',
		...options,
		showCloseButton: true
	})
}

export default infoToastr
