import React from 'react'
import RequestsContainer from 'modules/notifications/requests/RequestsContainer.js'
import RequestsView, {
	received
} from 'modules/notifications/requests/RequestsView.js'

const ReceivedPage = () => {
	return (
		<RequestsContainer>
			{props => <RequestsView {...props} selectedTab={received} />}
		</RequestsContainer>
	)
}

export default ReceivedPage
