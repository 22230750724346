import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'modules/notifications/likes/store/selectors'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { likeApplicant } from 'modules/discovery/services'
import { rejectApplicantFromLikes } from './services'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import { getIdFromDataAttribute } from 'utils/getIdFromDataAttribute.js'
import useUpgradeNotification from 'hooks/useUpgradeNotification'
import userSelectors from 'modules/users/selectors'
import {
	received,
	sent,
	previewReceived,
	previewSent
} from '../likes/LikesView'
import {
	removeReceivedLikeById,
	removeSentLikeById,
	setCurrentUserId
} from 'modules/notifications/likes/store/actions'
import { screenFormat } from '../../../constants/screenFormat'
import { getReceivedLikes } from 'modules/notifications/likes/store/actions'
import { getSentLikes } from './store/actions'
import { useLocation } from 'react-router-dom'
import { setUserForCreateConversation } from '../../conversation/store/actions'

const paths = {
	[received]: routesByName.main.notifications.likes.received,
	[sent]: routesByName.main.notifications.likes.sent,
	[previewReceived]: routesByName.main.notifications.likes.previewReceived,
	[previewSent]: routesByName.main.notifications.likes.previewSent
}

const LikesContainer = ({ children }) => {
	const [isLoadingReceivedLikes, setLoadingReceivedLikes] = useState(false)
	const [isLoadingSentLikes, setLoadingSentLikes] = useState(false)
	const receivedLikes = useSelector(selectors.getReceivedLikes)
	const sentLikes = useSelector(selectors.getSentLikes)
	const dispatch = useDispatch()
	const history = useHistory()
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const upgradeNotification = useUpgradeNotification()
	const userId = useSelector(selectors.getCurrentUserId)
	const user = useSelector(userSelectors.getUserById(userId))
	const { pathname } = useLocation()
	const hasMoreReceivedLikes = useSelector(selectors.getHasMoreReceivedLikes)
	const hasMoreSentLikes = useSelector(selectors.getHasMoreSentLikes)
	const receivedLikesLength = useSelector(selectors.getReceivedLikesLength)
	const sentLikesLength = useSelector(selectors.getSentLikesLength)
	const loadMoreReceivedLikes = useCallback(async () => {
		try {
			setLoadingReceivedLikes(true)
			await dispatch(
				getReceivedLikes({ offset: receivedLikesLength, limit: 20 }, 'push')
			)
		} catch (e) {
			console.dir(e)
		} finally {
			setLoadingReceivedLikes(false)
		}
	}, [receivedLikesLength, dispatch])

	const loadMoreSentLikes = useCallback(async () => {
		try {
			setLoadingSentLikes(true)
			await dispatch(
				getSentLikes({ offset: sentLikesLength, limit: 20 }, 'push')
			)
		} catch (e) {
			console.dir(e)
		} finally {
			setLoadingSentLikes(false)
		}
	}, [sentLikesLength, dispatch])

	const handleChangeSelectedTab = useCallback(
		value => {
			value === received
				? dispatch(getReceivedLikes())
				: dispatch(getSentLikes())
			history.push(paths[value])
			dispatch(setCurrentUserId(null))
		},
		[history, dispatch]
	)

	const handleClickPreview = useCallback(
		event => {
			event.stopPropagation()

			const tab = event.currentTarget.getAttribute('data-tab')
			if (tab === received && !upgradeNotification.isSubscribe) {
				return upgradeNotification.unlockFeatures.controller.turnIn()
			}

			const id = Number.parseInt(event.currentTarget.getAttribute('data-id'))
			dispatch(setCurrentUserId(id))

			if (format === screenFormat.mobile) {
				pathname === paths[received]
					? history.push(paths[previewReceived])
					: history.push(paths[previewSent])
			}
		},
		[
			history,
			upgradeNotification.isSubscribe,
			upgradeNotification.unlockFeatures.controller,
			dispatch,
			format,
			pathname
		]
	)

	const handleClickPass = useCallback(
		async event => {
			try {
				if (!upgradeNotification.isSubscribe) {
					return upgradeNotification.unlockFeatures.controller.turnIn()
				}

				event.stopPropagation()
				const id = getIdFromDataAttribute(event)
				await rejectApplicantFromLikes(id)

				if (
					pathname === paths[received] ||
					pathname === paths[previewReceived]
				) {
					dispatch(removeReceivedLikeById(id))
				} else {
					dispatch(removeSentLikeById(id))
				}

				if (
					format === screenFormat.mobile &&
					(pathname === paths[previewReceived] ||
						pathname === paths[previewSent])
				) {
					history.goBack()
				}
				dispatch(setCurrentUserId(null))
			} catch (e) {
				console.dir(e)
			}
		},
		[
			dispatch,
			history,
			upgradeNotification.isSubscribe,
			upgradeNotification.unlockFeatures.controller,
			format,
			pathname
		]
	)

	const handleClickLike = useCallback(
		async event => {
			try {
				event.stopPropagation()
				const id = getIdFromDataAttribute(event)
				await likeApplicant(id)
				pathname === paths[received] || pathname === paths[previewReceived]
					? dispatch(removeReceivedLikeById(id))
					: dispatch(removeSentLikeById(id))
				if (format === screenFormat.mobile) {
					history.goBack()
				}
				dispatch(setCurrentUserId(null))
			} catch (e) {
				console.dir(e)
			}
		},
		[dispatch, history, format, pathname]
	)

	const handleClickSendMessage = useCallback(
		async event => {
			console.log(
				'upgradeNotification.isSubscribe',
				upgradeNotification.isSubscribe
			)
			if (!upgradeNotification.isSubscribe)
				return upgradeNotification.showUpgradeToastr()

			try {
				const id = getIdFromDataAttribute(event)
				dispatch(setUserForCreateConversation(id))

				if (format === screenFormat.mobile) {
					history.push(
						routesByName.main.notifications.requests.sentConversationCreate
					)
				} else {
					history.push(routesByName.main.discovery.sendMessage)
				}
			} catch (e) {
				console.dir(e)
			}
		},
		[dispatch, format, history, upgradeNotification]
	)

	const props = useMemo(
		() => ({
			receivedLikes,
			sentLikes,
			onClickPreview: handleClickPreview,
			onClickPass: handleClickPass,
			onClickLike: handleClickLike,
			onChangeTab: handleChangeSelectedTab,
			onClickSendMessage: handleClickSendMessage,
			loadMoreReceivedLikes,
			loadMoreSentLikes,
			hasMoreReceivedLikes,
			hasMoreSentLikes,
			isLoadingReceivedLikes,
			isLoadingSentLikes,
			format,
			user,
			upgradeNotification
		}),
		[
			user,
			receivedLikes,
			sentLikes,
			handleClickPreview,
			handleClickPass,
			handleClickLike,
			handleClickSendMessage,
			loadMoreReceivedLikes,
			loadMoreSentLikes,
			hasMoreReceivedLikes,
			hasMoreSentLikes,
			isLoadingReceivedLikes,
			isLoadingSentLikes,
			handleChangeSelectedTab,
			format,
			upgradeNotification
		]
	)

	return children(props)
}

export default LikesContainer
