import React, { useCallback } from 'react'
import classes from './ComingSoon.module.scss'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import useHistory from 'hooks/useHistory'
import Header from 'components/ui/layout/GuestHeader'

export const btnSize = {
	desktop: {
		size: 'md'
	},
	mobile: {
		size: 'sm'
	}
}

const ComingSoon = () => {
	const format = useDetermineScreenFormat()
	const history = useHistory()

	const handleClickBack = useCallback(() => history.goBack(), [history])

	return (
		<div className={classes.root}>
			<Header variantLogo="light" classes={{ root: classes.header }} />
			<Typography className={clsx('my-5', classes.text)} variant="body-4">
				Coming Soon...
			</Typography>
			<Button
				shape="rounded"
				color="white"
				shadow
				{...btnSize[format]}
				onClick={handleClickBack}
			>
				Back
			</Button>
		</div>
	)
}

export default ComingSoon
