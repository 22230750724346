import React from 'react'
import NotificationsContainer from 'modules/notifications/NotificationsContainer'
import ProfilePreviewMatePage from 'modules/notifications/ProfilePreviewMatePage'

const NotificationRightSidebar = () => {
	return (
		<NotificationsContainer>
			{({ mate }) => <ProfilePreviewMatePage mate={mate} />}
		</NotificationsContainer>
	)
}

export default NotificationRightSidebar
