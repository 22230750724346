import React, { memo } from 'react'
import classes from './Personalities.module.scss'
import Typography from 'components/ui/Typography'
import BaseRadioButton from 'components/ui/controls/BaseRadioButton'
import AddButton from 'modules/profile/components/AddButton/AddButton'

const Personalities = ({ onClickUpdate = () => null, personalities = [] }) => {
	return (
		<div className={classes.root}>
			<Typography className={classes.title}>Personality</Typography>

			<div className={classes.personalities}>
				{personalities?.map(({ label }, index) => (
					<BaseRadioButton
						label={label}
						isSelect
						className={classes.personality}
						key={`personality-${index}`}
					/>
				))}
			</div>

			<AddButton label="Update" onClick={onClickUpdate} />
		</div>
	)
}

export default memo(Personalities)
