import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import classes from './ChangeIdentityView.module.scss'
import FormikFieldInput from 'components/wrappers/formik/FormikFieldInput'
import FormikFieldPhoneInput from 'components/wrappers/formik/FormikFieldPhoneInput'
import BaseCard from 'components/ui/controls/BaseCard'
import yup from 'libs/yup'
import Button from 'components/ui/Button'
import FormikFieldCodeInput from 'components/wrappers/formik/FormikFieldCodeInput'
import Typography from 'components/ui/Typography'
import { EnterCodeDescription } from 'modules/auth/signIn/SignIn'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'

const componentsLibrary = {
	email: {
		Input: () => (
			<FormikFieldInput
				name="email"
				placeholder="example@example.com"
				showSuccessMessage
			/>
		),
		initialValues: {
			email: ''
		},
		validationSchema: yup.object().shape({
			email: yup
				.string()
				.email('Please enter a valid email address.')
				.required()
		})
	},
	phone: {
		Input: () => (
			<FormikFieldPhoneInput name="phone" hasCountryCode showSuccessMessage />
		),
		initialValues: {
			phone: {
				number: '',
				international: '',
				country: 'US'
			}
		},
		validationSchema: yup.object().shape({
			phone: yup.mixed().phone()
		})
	},
	verify_code: {
		Input: props => <FormikFieldCodeInput name="verify_code" {...props} />,
		initialValues: {
			verify_code: ''
		},
		validationSchema: yup.object().shape({
			verify_code: yup
				.string()
				.min(4, 'Code must be at least 4 characters')
				.required('Code is required')
		})
	}
}

const ChangeIdentityView = ({
	onSubmit,
	identityType,
	verifyData,
	infoDialog
}) => {
	const { Input, initialValues, validationSchema } = useMemo(
		() => componentsLibrary[identityType],
		[identityType]
	)

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				<Form className={classes.root}>
					<BaseCard className={classes.content}>
						{identityType !== 'verify_code' ? (
							<Typography className={classes.cardTitle}>
								Update your {identityType}
							</Typography>
						) : (
							<EnterCodeDescription
								identity={verifyData?.identity}
								className="mb-2 text-center"
							/>
						)}
						<Input timeTillDate={verifyData?.expiresIn} />
						<Button shape="rounded" size="md" className={classes.submit} shadow>
							Submit
						</Button>
					</BaseCard>
				</Form>
			</Formik>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.close}
				type={infoDialog.error ? 'error' : 'success'}
				title={infoDialog.error ? 'Error' : 'Success'}
				body={infoDialog.body}
				onConfirm={infoDialog.error ? () => null : infoDialog.onConfirm}
			/>
		</>
	)
}

export default ChangeIdentityView
