import React from 'react'
import classes from './HistoryView.module.scss'
import BaseCard from 'components/ui/controls/BaseCard'
import CardBrand from 'modules/subscriptionBilling/billing/components/CardBrand/CardBrand'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import Typography from 'components/ui/Typography'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getNextPaymentDate } from 'modules/subscriptionBilling/store/selectors'

const HistoryView = ({ paymentList }) => {
	const isMobileFormat =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile

	const nextPaymentDate = useSelector(getNextPaymentDate)

	return (
		<BaseScrollbars>
			<div className={classes.root}>
				{!isMobileFormat && (
					<Typography className={classes.title}>Billing History</Typography>
				)}
				{paymentList?.map(
					({ date, title, amountPaid, cardLast4, cardType }, index) => (
						<BaseCard className={classes.historyItem} key={index}>
							<div className={classes.dates}>
								<span className={classes.date}>
									{moment(date * 1000).format('MMM D, YYYY')}
									{index === 0 &&
										` - ${moment(nextPaymentDate).format('MMM D, YYYY')}`}
								</span>
								{/*<span className={classes.date}>Expires: Aug 25, 2020</span>*/}
							</div>
							<p className={classes.subscriptionType}>{title}</p>
							<div className={classes.paymentDetails}>
								<div className={classes.card}>
									<CardBrand
										brand={cardType}
										classes={{ root: classes.cardIcon }}
									/>
									<span className={classes.cardNumber}>**** {cardLast4}</span>
								</div>
								<span className={classes.sum}>CAD ${amountPaid}</span>
							</div>
						</BaseCard>
					)
				)}
			</div>
		</BaseScrollbars>
	)
}

export default HistoryView
