import React, { memo, useCallback, useState } from 'react'
import classes from './PaymentForm.module.scss'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import {
	getPaymentSecret,
	updatePaymentMethod
} from 'modules/subscriptionBilling/services'
import _useDidMount from 'hooks/useDidMount'
import { toggleLoader } from 'modules/app/store/actions'
import { useDispatch } from 'react-redux'
import Button from 'components/ui/Button'
import { errorColor, primaryColor } from 'constants/colors'
import { Form, Formik } from 'formik'
import Typography from 'components/ui/Typography'
import FormikFieldInput from 'components/wrappers/formik/FormikFieldInput'
import SpriteIcon from 'components/ui/SpriteIcon'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'
import useFlagManager from 'hooks/useFlagManager'
import {_isEmpty} from '../../../utils/lodash';

const CARD_OPTIONS = {
	iconStyle: 'solid',
	classes: {
		invalid: classes.invalid
	},
	style: {
		base: {
			iconColor: primaryColor,
			color: '#000000',
			fontWeight: 500,
			fontFamily: 'Montserrat, sans-serif',
			fontSize: '16px',
			fontSmoothing: 'antialiased'
		},
		invalid: {
			iconColor: errorColor
		}
	},
	hidePostalCode: true
}

const billingAddress = {
	address: {
		line1: '',
		line2: '',
		city: '',
		state: '',
		postal_code: ''
	}
}

const PaymentForm = ({
	onSubmit = () => null,
	onDelete,
	billingState,
	currentPlan
}) => {
	const stripe = useStripe()
	const elements = useElements()
	const dispatch = useDispatch()
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')
	const [stripeError, setStripeError] = useState(null)

	_useDidMount(() => {})

	const onCardChange = (e) => {
		setStripeError( e.error )
	}

	const handleSubmit = useCallback(
		async billingDetails => {
			try {
				dispatch(toggleLoader())

				if (!stripe || !elements) {
					return
				}

				const cardElement = elements.getElement(CardElement)

				const userPaymentSecret = await getPaymentSecret()

				const {
					error,
					setupIntent: { payment_method }
				} = await stripe.confirmCardSetup(userPaymentSecret, {
					payment_method: {
						type: 'card',
						card: cardElement,
						billing_details: billingDetails
					}
				})

				if (error) {
					setError(error.message)
					infoDialog.turnIn()
				} else {
					await updatePaymentMethod(payment_method)
					onSubmit()
				}
			} catch (error) {
				setError(error.message)
				infoDialog.turnIn()
			} finally {
				dispatch(toggleLoader())
			}
		},
		[dispatch, elements, infoDialog, onSubmit, stripe]
	)

	return (
		<>
			<Formik
				onSubmit={handleSubmit}
				enableReinitialize
				initialValues={billingState || billingAddress}
			>
				<Form className={classes.root}>
					<div className={classes.header}>
						<Typography className={classes.details}>Details</Typography>
						{onDelete ? (
							<Button
								shape="rounded"
								variant="outlined"
								color="primary"
								size="sm"
								type="button"
								className={classes.delete}
								onClick={onDelete}
								icon={<SpriteIcon name="trash" className="mr-1" />}
							>
								Delete
							</Button>
						) : null}
					</div>

					<div className={classes.card}>
						<CardElement options={CARD_OPTIONS} onChange={onCardChange}/>
					</div>

					{!_isEmpty(stripeError) &&
					 <span className={classes.stripeError}>{stripeError.message}</span>}

					<Typography className={classes.billingAddress}>
						Billing Address
					</Typography>

					<Typography className={classes.label}>Address</Typography>
					<FormikFieldInput name="address.line1" rootClass={classes.input} />
					<FormikFieldInput name="address.line2" rootClass={classes.input} />

					<Typography className={classes.label}>City</Typography>
					<FormikFieldInput name="address.city" rootClass={classes.input} />

					<Typography className={classes.label}>State / Province</Typography>
					<FormikFieldInput name="address.state" rootClass={classes.input} />

					<Typography className={classes.label}>ZIP / Postal Code</Typography>
					<FormikFieldInput
						name="address.postal_code"
						rootClass={classes.input}
					/>
					<div className={classes.recurringBilling}>
						<Typography>Recurring billing, cancel anytime</Typography>

						<Typography>
							Once your payment is confirmed, your subscription will
							automatically renew at ${currentPlan?.renew} CAD/month. Cancel
							anytime in your settings under your profile. By tapping continue
							you agree to our <Typography> terms of service</Typography>.{' '}
							<br />
							<br />
							We like commitment. No refunds.
						</Typography>
					</div>

					<Button
						type="submit"
						shape="rounded"
						size="md"
						className={classes.submit}
						disabled={!stripe}
					>
						Subscribe
					</Button>
					<div className={classes.infoText}>
						Billed in CAD. Other conversions are estimates only. Actual charge
						may vary based on exchange rates. <br />
						<SpriteIcon name="lock" />
						This is a secure page.
					</div>
				</Form>
			</Formik>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.turnOff}
				type={'error'}
				title={'Error'}
				body={error}
			/>
		</>
	)
}

export default memo(PaymentForm)
