import React from 'react'
import classes from './AnswerCard.module.scss'
import Typography from 'components/ui/Typography'
import clsx from 'clsx'

const AnswerCard = ({ question, answer, className }) => {
	return (
		<div className={clsx(classes.root, className)}>
			<Typography className={classes.question}>{question}</Typography>
			<Typography className={classes.answer}>{answer}</Typography>
		</div>
	)
}

export default AnswerCard
