import React from 'react'
import { ReactComponent as QuestionIcon } from 'assets/staticIcons/question.svg'
import classes from './LeftSidebar.module.scss'
import Typography from 'components/ui/Typography'

const HelpPageLeftSideBar = () => {
	return (
		<div className={classes.root}>
			<QuestionIcon />
			<Typography className={classes.text}>
				Have a question?
				<br /> We can help you out!
			</Typography>
		</div>
	)
}

export default HelpPageLeftSideBar
