import React from 'react'
import RequestCreateContainer from './RequestCreateContainer.js'
import CreateConversationMobile from 'modules/conversation/create/CreateConversationMobile.js'

const RequestCreateMobile = () => {
	return (
		<RequestCreateContainer>
			{({ onPushMessage }) => (
				<CreateConversationMobile
					title="Request to Chat"
					onPushMessage={onPushMessage}
				/>
			)}
		</RequestCreateContainer>
	)
}

export default RequestCreateMobile
