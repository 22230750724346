import React from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { routesByName } from 'constants/routes'
// import componentLoader from 'utils/componentLoader'
import ProfilePage from 'modules/profile/index'

// const ProfilePage = React.lazy(() => import('modules/profile/index'))

// const ProfilePage = React.lazy(() =>
// 	componentLoader(() => import('modules/profile/index'))
// )

const ProfileSwitch = () => {
	let { path } = useRouteMatch()

	return (
		<Switch>
			<Route path={path} exact>
				<Redirect to={routesByName.main.profile.edit} />
			</Route>
			<Route path={routesByName.main.profile.root} component={ProfilePage} />
		</Switch>
	)
}

export default ProfileSwitch
