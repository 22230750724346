import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from 'modules/notifications/matches/store/selectors.js'
import { getUserById } from 'modules/users/selectors.js'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import { screenFormat } from 'constants/screenFormat.js'
import { getMe, getMeAvatar } from 'modules/profile/store/selectors.js'
import useHistory from 'hooks/useHistory.js'
import { routesByName } from 'constants/routes.js'
import { setUserForCreateConversation } from 'modules/conversation/store/actions.js'
import _useDidMount from 'hooks/useDidMount'
import { getLastMatch } from 'modules/notifications/matches/store/actions'
import useUpgradeNotification from 'hooks/useUpgradeNotification'

const LastMatchContainer = ({ children }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const lastMatch = useSelector(selectors.getLastMatch)
	const myAvatar = useSelector(getMeAvatar)
	const { firstName: myName } = useSelector(getMe)
	const user = useSelector(getUserById(lastMatch))
	const upgradeNotification = useUpgradeNotification()

	const mobileFormat =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile

	const handleClickSendMessage = useCallback(() => {
		if (!upgradeNotification.isSubscribe) {
			return upgradeNotification.unlockFeatures.controller.turnIn()
		}

		dispatch(setUserForCreateConversation(user.id))

		if (mobileFormat) {
			history.push(routesByName.main.lastMatch.mobileConversationCreate)
		} else {
			history.push(routesByName.main.lastMatch.conversationCreate)
		}
	}, [
		dispatch,
		history,
		mobileFormat,
		upgradeNotification.isSubscribe,
		upgradeNotification.unlockFeatures.controller,
		user.id
	])

	_useDidMount(() => {
		dispatch(getLastMatch())
	})

	const handleClickKeepBrowsing = useCallback(() => {
		history.push(routesByName.main.discovery.root)
	}, [history])

	const props = useMemo(
		() => ({
			mobileFormat,
			onClickSendMessage: handleClickSendMessage,
			onClickKeepBrowsing: handleClickKeepBrowsing,
			myAvatar: myAvatar?.large,
			matchAvatar: user?.photos?.find(({ type }) => type === 'avatar')?.large,
			myName,
			matchName: user.firstName,
			lastMatch,
			upgradeNotification
		}),
		[
			handleClickKeepBrowsing,
			handleClickSendMessage,
			mobileFormat,
			myAvatar,
			user,
			myName,
			lastMatch,
			upgradeNotification
		]
	)

	return children(props)
}

export default LastMatchContainer
