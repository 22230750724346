import React, { useCallback, useMemo, useState } from 'react'
import classes from './SignUp.module.scss'
import { Formik } from 'formik'
import View from 'modules/auth/signUp/View'
import Header from 'components/ui/layout/GuestHeader'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { steps } from 'modules/auth/signUp/config'
import Stepper from 'components/ui/Stepper'
import { screenFormat } from 'constants/screenFormat'
import { useDispatch, useSelector } from 'react-redux'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import _useDidMount from 'hooks/useDidMount'
import { breakpointsUp } from 'constants/breakpoints'
import BaseImage from 'components/wrappers/ui/BaseImage'
import SpriteIcon from 'components/ui/SpriteIcon'
import { gray1 } from 'constants/colors'
import { toggleLoader } from 'modules/app/store/actions.js'
import { getVerifyExpire } from 'modules/auth/signUp/store/selectors.js'
import { isExpired } from 'modules/auth/utils.js'
import MetaTags from 'react-meta-tags'
import useFlagManager from 'hooks/useFlagManager'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'
import { _isEmpty } from 'utils/lodash'
import * as Sentry from '@sentry/browser'

const countSteps = steps.length
const verifyToken = localStorage.getItem('verifyToken')

const SignUp = props => {
	const [currentStep, setStep] = useState(0)
	const history = useHistory()
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const dispatch = useDispatch()
	const expiresIn = useSelector(getVerifyExpire)
	const infoDialog = useFlagManager()
	const [error, setError] = useState()
	const [otherError, setOtherError] = useState(false)
	// const recaptchaRef = React.useRef()

	const {
		description,
		initialValues,
		validationSchema,
		Input,
		title,
		handlerValues,
		secondaryActions
	} = useMemo(() => steps[currentStep], [currentStep])

	_useDidMount(() => {
		// Add reCaptcha
		const script = document.createElement('script')
		script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${process.env.REACT_APP_RECAPCTHA_SITE_KEY}`
		document.body.appendChild(script)

		if (verifyToken && !isExpired(expiresIn)) {
			setStep(1)
		}
	})

	const handleSubmit = useCallback(
		async values => {
			try {
				dispatch(toggleLoader())
				const isFirstStep = currentStep === 0

				if (isFirstStep) {
					const recaptcha = window.grecaptcha
					const recaptchaToken = await recaptcha?.execute(
						process.env.REACT_APP_RECAPCTHA_SITE_KEY,
						{ action: 'init_phone_verification' }
					)
					if (recaptchaToken) {
						await handlerValues({ ...values, recaptchaToken }, dispatch)
					}

					// recaptcha.ready(() => {
					// 	recaptcha.execute(process.env.REACT_APP_RECAPCTHA_SITE_KEY, { action: 'init_phone_verification' }).then(async recaptchaToken => {
					// 		if (recaptchaToken) {
					// 			await handlerValues({ ...values, recaptchaToken }, dispatch)
					// 		}
					// 	})
					// })
				} else {
					await handlerValues(values, dispatch)
				}

				const nextStep = currentStep + 1
				const isLastStep = countSteps === nextStep

				if (isLastStep) {
					// toggleShowSuccessScreen(true)
					history.push(routesByName.enterEmail)
				} else {
					setStep(nextStep)
				}
			} catch (e) {
				const errorMsg = !_isEmpty(e?.validation)
					? e.validationErrors.identity.join('\n')
					: e.getException
				const recaptchaError =
					e.response?.data.error.code === 'HTTP429'
						? 'Too many requests, please try again in a few minutes.'
						: e.response?.data.error.message
				if (!errorMsg && recaptchaError) {
					setOtherError(true)
				} else {
					setOtherError(false)
				}

				setError(errorMsg || recaptchaError)
				infoDialog.turnIn()
				Sentry.captureException(e)
			} finally {
				dispatch(toggleLoader())
			}
		},
		[currentStep, dispatch, handlerValues, history, infoDialog]
	)

	const handleClickBack = useCallback(() => {
		history.push(routesByName.home)
	}, [history])

	return (
		<>
			<MetaTags>
				<title>myTamilDate | Create an account</title>
				<meta
					name="description"
					content="Create your profile and join for FREE. The largest Tamil dating network available on iOS and Android."
				/>
				<meta
					name="keywords"
					content="free tamil dating account, my tamil date account, my tamil date login"
				/>
			</MetaTags>
			<div className={classes.root}>
				<div className={classes.form}>
					<Header
						variantLogo="dark"
						hideLogoOnMobile
						hasBack={format === screenFormat.mobile}
						onClickBackIcon={handleClickBack}
						classes={{ root: classes.header }}
					/>

					<div className={classes.wrapper}>
						<div>
							{format === screenFormat.desktop && (
								<SpriteIcon
									name="arrow-back"
									onClick={handleClickBack}
									color={gray1}
									size="md"
									className={classes.backIcon}
								/>
							)}
							<Stepper
								className={classes.stepper}
								count={countSteps + 1}
								step={currentStep}
								hideOnMobile
							/>
						</div>

						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
							enableReinitialize
						>
							<View
								title={title}
								description={description}
								Input={Input}
								format={format}
								secondaryActions={secondaryActions}
							/>
						</Formik>
					</div>
				</div>

				{format !== screenFormat.mobile && (
					<BaseImage
						src="assets/couplephoto.jpg"
						classes={{ root: classes.img, wrapper: classes.imageWrapper }}
					/>
				)}
				<InfoDialog
					open={infoDialog.state}
					onClose={infoDialog.turnOff}
					type={'error'}
					title={'Error'}
					// body={error}
					body={
						currentStep === 0 ? (
							<>
								<p>
									{error}
									{!otherError && (
										<a
											href="https://mytamildate.com/sign-in"
											className={classes.link}
										>
											here.
										</a>
									)}
								</p>
							</>
						) : (
							error
						)
					}
				/>
			</div>
			{/*<ReCAPTCHA*/}
			{/*	ref={recaptchaRef}*/}
			{/*	sitekey={process.env.REACT_APP_RECAPCTHA_SITE_KEY}*/}
			{/*	size="invisible"*/}
			{/*/>*/}
		</>
	)
}

SignUp.propTypes = {}

SignUp.defaultProps = {}

export default SignUp
