export const getMateIdByConversationId = id => ({
	messenger: {
		conversationsMap: { [id]: conversation }
	},
	requests: {
		sentRequestsMap: { [id]: sentConversation },
		receivedRequestsMap: { [id]: receivedConversation }
	},
	profile: {
		user: { id: myId }
	}
}) =>
	(conversation || sentConversation || receivedConversation)?.participants.find(
		({ userId }) => userId !== myId
	)?.userId

export const getCurrentConversation = ({
	messenger: { currentConversationId }
}) => currentConversationId

export const getUserForCreateConversation = ({
	messenger: { userForCreateConversation }
}) => userForCreateConversation

export const getConversations = ({ messenger: { conversations } }) =>
	conversations
export const getHasMoreConversations = ({ messenger: { hasMore } }) => hasMore

export const getIsLastMessageRead = id => ({
	messenger: {
		conversationsMap: { [id]: conversation }
	}
}) => conversation?.isLastMessageRead

const conversationSelectors = {
	getMateIdByConversationId,
	getCurrentConversation,
	getUserForCreateConversation,
	getConversations,
	getHasMoreConversations,
	getIsLastMessageRead
}

export default conversationSelectors
