import React, { useMemo } from 'react'
import { Formik } from 'formik'
import EditorDialog from 'modules/profile/components/EditorDialog'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import ProfileDesktopView from 'modules/profile/ProfileDesktopView'
import ProfileMobileView from 'modules/profile/ProfileMobileView/ProfileMobileView'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'
import { _isEmpty } from 'utils/lodash'
import {
	closeDialogManager,
	setDialogManagerState
} from 'modules/dialogManager/store/actions'
import { useDispatch } from 'react-redux'

const viewByFormat = {
	desktop: ProfileDesktopView,
	mobile: ProfileMobileView
}
const ProfileView = ({
	formProps = {},
	user = {},
	validationSchema,
	onSubmit = () => null,
	config = {},
	CurrentEditorElement = () => null,
	openEditorDialog = false,
	onCloseEditorDialog = () => null,
	handleChangeTab = () => null,
	selectedTab = '',
	headerEditorDialog = '',
	nameEditorDialog = '',
	infoDialog
}) => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const CurrentView = useMemo(() => viewByFormat[format], [format])
	const dispatch = useDispatch()

	return (
		<>
			{
				<CurrentView
					selectedTab={selectedTab}
					onChangeTab={handleChangeTab}
					{...formProps}
				/>
			}
			<Formik
				initialValues={user}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				validateOnMount={false}
				validateOnBlur={false}
				validateOnChange={true}
			>
				{({ submitForm, resetForm, validateForm }) => (
					<EditorDialog
						open={openEditorDialog}
						onClose={() => {
							onCloseEditorDialog()
							resetForm()
						}}
						header={headerEditorDialog}
						onClickSubmit={() => {
							validateForm().then(result => {
								if (!_isEmpty(result)) {
									dispatch(
										setDialogManagerState({
											open: true,
											body: result[Object.keys(result)[0]],
											onClose: () => dispatch(closeDialogManager()),
											onConfirm: () => dispatch(closeDialogManager()),
											withCancelBtn: false,
											boldText: true
										})
									)
								} else {
									submitForm()
								}
							})
						}}
						name={nameEditorDialog}
					>
						{
							<CurrentEditorElement
								config={config}
								user={user}
								format={format}
							/>
						}
					</EditorDialog>
				)}
			</Formik>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.close}
				type={'error'}
				title={'Error'}
				body={infoDialog.error}
			/>
		</>
	)
}

export default ProfileView
