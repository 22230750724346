import React from 'react'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import classes from './CancelAutoRenewalDialog.module.scss'

const CancelAutoRenewalDialog = ({ open = false, onClose, onCancel }) => {
	return (
		<BaseDialog
			open={open}
			rootClass={classes.dialog}
			maxWidth="xs"
			onClose={onClose}
		>
			<div className={classes.root}>
				<div className={classes.modal}>
					<Typography className={classes.modalTitle}>
						Cancel auto-renewal on your subscription?
					</Typography>
					<Typography className={classes.modalSubtitle}>
						Your account will not auto-renew and your service will be
						interrupted on the expiry date. Keep auto-renew for a seamless
						experience.
					</Typography>
					<div className={classes.buttons}>
						<Button onClick={onClose} shape="rounded" size="sm">
							Keep
						</Button>
						<Button
							onClick={onCancel}
							shape="rounded"
							size="sm"
							className={classes.outlinedBtn}
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		</BaseDialog>
	)
}

export default CancelAutoRenewalDialog
