import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classes from './SignInHelpPage.module.scss'
import Typography from 'components/ui/Typography'
import BaseAccordion from 'components/wrappers/ui/BaseAccordion'

const SignInHelpPage = props => {
	const [expanded, setExpanded] = useState(false)

	const handleToggleExpanded = useCallback((event, value) => {
		setExpanded(value)
	}, [])

	return (
		<div className={classes.root}>
			<BaseAccordion
				header={<Typography>Collapsible Group Item #1</Typography>}
				onToggle={handleToggleExpanded}
				expanded={expanded}
			>
				<Typography>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
					malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
					dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
					lacus ex, sit amet blandit leo lobortis eget.
				</Typography>
			</BaseAccordion>
		</div>
	)
}

SignInHelpPage.propTypes = {
	className: PropTypes.string
}

SignInHelpPage.defaultProps = {
	className: ''
}

export default SignInHelpPage
