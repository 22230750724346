import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getPaymentList } from 'modules/subscriptionBilling/store/selectors'

const HistoryContainer = ({ children }) => {
	const paymentList = useSelector(getPaymentList)
	const props = useMemo(
		() => ({
			paymentList
		}),
		[paymentList]
	)
	return children(props)
}

export default HistoryContainer
