import React from 'react'
import RequestCreateContainer from 'modules/notifications/requests/createConversation/RequestCreateContainer.js'
import CreateConversationPage from 'modules/conversation/create/CreateConversationPage.js'

const RequestCreatePage = () => {
	return (
		<RequestCreateContainer>
			{({ onPushMessage, infoDialog }) => (
				<CreateConversationPage
					onPushMessage={onPushMessage}
					infoDialog={infoDialog}
				/>
			)}
		</RequestCreateContainer>
	)
}

export default RequestCreatePage
