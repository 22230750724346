import React, {memo, Suspense, useCallback, useEffect, useState} from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { routesByName } from 'constants/routes'
import { Elements } from '@stripe/react-stripe-js'
import FallbackPage from 'pages/FallbackPage/FallbackPage'
import EchoProvider from 'libs/echo/Provider'
import { loadStripe } from '@stripe/stripe-js'
import appConfig from 'constants/appConfig'
import CreateProfile from 'modules/profile/createProfile'
import { useDispatch, useSelector } from 'react-redux'
import {
	getMeAction,
	getProfileOptions,
	getRejectReasonAction
} from 'modules/profile/store/actions'
import {
	getPaymentInfo,
	getSubscriptionPlans,
	getExchangeRates
} from 'modules/subscriptionBilling/store/actions'
import {
	getLastMatch,
	getMatches
} from 'modules/notifications/matches/store/actions'
import { getConversations } from 'modules/conversation/store/actions'
// import { getLikes } from 'modules/notifications/likes/store/actions'
import { getRequests } from 'modules/notifications/requests/store/actions'
import firebaseInit from 'libs/firebase/Firebase'
import _useAsyncDidMount from 'hooks/_useAsyncDidMount.js'
import { getFinishedCreatingProfile } from 'modules/profile/store/selectors'
import appActions from 'modules/app/store/actions'
import useInterval from '@rooks/use-interval'
import { userActivity } from 'modules/profile/services'
import { useLocation } from 'react-router-dom'
import {
	getReceivedLikes,
	getSentLikes
} from '../../notifications/likes/store/actions'
import {
	getDiscoveryUsers,
	setUsersAreOver
} from 'modules/discovery/store/actions'
import * as services from 'modules/preferences/services'
import { _get } from 'utils/lodash'
import { determineNullableFilterValues } from 'modules/profile/createProfile/utils'
import { APPROVED } from 'hooks/useUpgradeNotification'
import { getUnsubscribeInfo } from '../../notifications/emails/store/actions'
import useDidMount from 'hooks/useDidMount'

const PrivateSwitch = ({ children }) => {
	const { path } = useRouteMatch()
	const dispatch = useDispatch()
	const location = useLocation()
	const [stripe, setStripe] = useState(null);

	useDidMount(() => {
		const initializeStripe = async () => {
			const stripePromise = await loadStripe(appConfig.stripePublicKey)
			setStripe(stripePromise)
		}

		initializeStripe()
	});

	const finishedCreatingProfile = useSelector(getFinishedCreatingProfile)

	const { start, stop } = useInterval(
		() => userActivity('ping').catch(console.dir),
		5000
	)

	const getApplicationState = useCallback(async () => {
		try {
			dispatch(getUnsubscribeInfo())
			const user = await dispatch(getMeAction())
			await dispatch(getRejectReasonAction())
			dispatch(setUsersAreOver(false))
			dispatch(getReceivedLikes())
			dispatch(getSentLikes())
			dispatch(getSubscriptionPlans())
			dispatch(getExchangeRates())
			dispatch(getProfileOptions())
			dispatch(appActions.getReportReasons())
			dispatch(appActions.getUnmatchReasons())
			dispatch(getLastMatch())
			dispatch(getConversations())
			dispatch(getMatches())
			dispatch(getRequests())
			dispatch(getPaymentInfo())

			if (user?.approval === APPROVED) {
				const response = await services.getPreference()
				const result = _get(response, 'result', {})
				const nullableValuesByKey = dispatch(
					determineNullableFilterValues(result)
				)
				dispatch(
					getDiscoveryUsers({
						wave: nullableValuesByKey.length < 13 ? 1 : 0
					})
				)
			}

			await firebaseInit()
		} catch (e) {
			console.dir(e)
		}
	}, [dispatch])

	_useAsyncDidMount(async () => {
		await getApplicationState()
	})

	useEffect(() => {
		start()
		return () => stop()
	}, [start, stop])

	useEffect(() => {
		userActivity('move').catch(console.dir)
	}, [location.pathname])

	return (
		<EchoProvider>
			<Elements stripe={stripe}>
				<Suspense fallback={<FallbackPage />}>
					<Switch>
						<Route exact path={path}>
							<Redirect to={routesByName.main.discovery.root} />
						</Route>
						<Route path={routesByName.createProfile}>
							{!finishedCreatingProfile ? (
								<CreateProfile />
							) : (
								<Redirect to={routesByName.main.discovery.root} />
							)}
						</Route>
						{children}
						<Route path="*">
							<Redirect to={routesByName.main.discovery.root} />
						</Route>
					</Switch>
				</Suspense>
			</Elements>
		</EchoProvider>
	)
}

export default memo(PrivateSwitch)
