import React from 'react'
import RequestsContainer from 'modules/notifications/requests/RequestsContainer.js'
import RequestsView, {
	sent
} from 'modules/notifications/requests/RequestsView.js'

const SentPage = () => {
	return (
		<RequestsContainer>
			{props => <RequestsView {...props} selectedTab={sent} />}
		</RequestsContainer>
	)
}

export default SentPage
