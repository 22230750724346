import ConversationContainer from 'modules/conversation/ConversationContainer.js'
import ConversationView from 'modules/conversation/ConversationView'
import React from 'react'
import TextPlaceholder from 'components/ui/layout/TextPlaceholder'

const ConversationPage = ({ hideInput, isConversationBlocked }) => (
	<ConversationContainer>
		{({ conversationId, ...props }) =>
			conversationId ? (
				<ConversationView
					{...props}
					hideInput={hideInput}
					isConversationBlocked={isConversationBlocked}
				/>
			) : (
				<TextPlaceholder text="Please select conversation." />
			)
		}
	</ConversationContainer>
)

export default ConversationPage
