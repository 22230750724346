import { routesByName } from 'constants/routes'
import SuccessStories from 'pages/SuccessStories'
import mapRouteConfig from 'utils/mapRouteConfig'
import ComingSoon from 'pages/ComingSoon'
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage'
import TermsAndConditionsPage from 'pages/TermsAndConditionsPage'
import AboutPage from 'pages/AboutPage'
import FAQPage from 'pages/FAQPage'
import ContactPage from 'pages/ContactPage'

const CommonRouteConfig = [
	{
		path: routesByName.successStories,
		component: SuccessStories,
		exact: false
	},
	{
		path: routesByName.blog,
		component: ComingSoon,
		exact: false
	},
	{
		path: routesByName.contact,
		component: ContactPage,
		exact: false
	},
	{
		path: routesByName.about,
		component: AboutPage,
		exact: false
	},
	{
		path: routesByName.faq,
		component: FAQPage,
		exact: false
	},
	{
		path: routesByName.common.privacy,
		component: PrivacyPolicyPage,
		exact: true
	},
	{
		path: routesByName.common.terms,
		component: TermsAndConditionsPage,
		exact: true
	}
]

export default mapRouteConfig(CommonRouteConfig)
