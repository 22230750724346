export const joinWithFacebook = 'Join with Facebook'
export const joinWithPhone = 'Sign up with phone number'
export const successStories = 'Success Stories'

export const slides = [
	'assets/slides/1.png',
	'assets/slides/2.jpg',
	'assets/slides/3.png',
	'assets/slides/4.jpg'
]

export const slidesForMobile = [
	'assets/slides/1mobile.png',
	'assets/slides/2.jpg',
	'assets/slides/3mobile.png',
	'assets/slides/4.jpg'
]

export const titles = [
	'Meet Tamil Singles',
	'Like & Match',
	'Connect & Chat',
	'Meet & Date'
]

export const btnSize = {
	desktop: {
		size: 'lg'
	},
	mobile: {
		size: 'md'
	}
}
export const btnNames = {
	joinWithFacebook,
	joinWithPhone,
	successStories
}
