import update from 'immutability-helper'
import * as types from 'modules/discovery/store/constants'

const initialState = {
	applicants: [],
	liked: [],
	rejected: [],
	searchedByPreferences: true,
	popupStatus: {
		1: false,
		2: false
	},
	wave: 0,
	usersAreOver: false
}

const discoveryReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.PREPARE_NEXT_APPLICANT: {
			return update(state, {
				applicants: { $set: payload.applicants }
			})
		}
		case types.GET_DISCOVERY_USERS: {
			return update(state, {
				applicants: { $set: payload },
				liked: { $set: [] },
				rejected: { $set: [] }
			})
		}
		case types.GET_MORE_DISCOVERY_USERS: {
			return update(state, {
				applicants: { $set: payload },
				liked: { $set: [] },
				rejected: { $set: [] }
			})
		}
		case types.PREPARE_PREVIOUS_APPLICANT: {
			return update(state, {
				applicants: { $set: payload.applicants }
			})
		}
		case types.LIKE_APPLICANT: {
			return update(state, {
				liked: { $push: payload }
			})
		}
		case types.REJECT_APPLICANT: {
			return update(state, {
				rejected: { $push: payload }
			})
		}
		case types.SET_POPUP_STATUS: {
			return update(state, {
				popupStatus: { $merge: payload }
			})
		}
		case types.RESET_POPUP_STATUS: {
			return update(state, {
				popupStatus: { $set: payload }
			})
		}
		case types.SET_WAVE: {
			return update(state, {
				wave: { $set: payload }
			})
		}
		case types.SET_USERS_ARE_OVER: {
			return update(state, {
				usersAreOver: { $set: payload }
			})
		}
		default:
			return state
	}
}

export default discoveryReducer
