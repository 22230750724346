import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classes from './LikeCard.module.scss'
import clsx from 'clsx'
import Button from 'components/ui/Button'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, primaryColor } from 'constants/colors'
import Typography from 'components/ui/Typography'
import BaseImage from 'components/wrappers/ui/BaseImage'
import { useSelector } from 'react-redux'
import {
	getAvatarByUserId,
	getPhotoByUserId,
	getUserByIdWithBindConfig
} from 'modules/users/selectors'
import * as moment from 'moment'

const LikeCard = ({
	onAccept = () => null,
	onPass = () => null,
	onMessage = () => null,
	id,
	selectedTab,
	isBlurred,
	isMessageIcon,
	isCloseIcon
}) => {
	const avatar = useSelector(getAvatarByUserId(id))
	const photo = useSelector(getPhotoByUserId(id))
	const user = useSelector(getUserByIdWithBindConfig(id))

	return (
		<div className={clsx(classes.root)} role="presentation">
			<BaseImage
				src={avatar || photo}
				classes={{
					root: clsx(classes.avatar, { [classes.smooth]: isBlurred })
				}}
			/>
			<div
				className={clsx(classes.textWrapper, {
					[classes.smooth]: isBlurred
				})}
			>
				<Typography className={clsx(classes.name, classes.text)}>
					{user.firstName}
				</Typography>
				<br />
				<Typography className={clsx(classes.desc, classes.text)}>
					{moment(user.birthday).fromNow(true)} • {user.job}
				</Typography>
			</div>
			<div className={classes.active}>
				{isMessageIcon && (
					<Button
						size="lg"
						shape="rounded"
						color="white"
						onClick={onMessage}
						data-id={id}
						className={clsx(classes.btn, classes.skipBtn)}
					>
						<SpriteIcon
							name="message-contained"
							color={black3}
							className={classes.icon}
						/>
					</Button>
				)}

				{isCloseIcon && !isMessageIcon && (
					<Button
						size="lg"
						shape="rounded"
						color="white"
						onClick={onPass}
						data-id={id}
						className={clsx(classes.btn, classes.skipBtn)}
					>
						<SpriteIcon name="close" color={black3} className={classes.icon} />
					</Button>
				)}

				<Button
					size="lg"
					shape="rounded"
					color="white"
					data-id={id}
					data-tab={selectedTab}
					onClick={onAccept}
					className={clsx(classes.btn, classes.previewBtn)}
				>
					<SpriteIcon
						name="person"
						color={primaryColor}
						className={classes.icon}
					/>
				</Button>
			</div>
		</div>
	)
}

LikeCard.propTypes = {
	firstName: PropTypes.string,
	avatar: PropTypes.string,
	job: PropTypes.string,
	selectedTab: PropTypes.string,
	onPass: PropTypes.func,
	onAccept: PropTypes.func,
	isBlurred: PropTypes.bool,
	isMessageIcon: PropTypes.bool,
	isCloseIcon: PropTypes.bool
}

LikeCard.defaultProps = {
	onAccept: () => null,
	onPass: () => null,
	onMessage: () => null,
	isBlurred: true,
	isMessageIcon: false,
	selectedTab: null,
	isCloseIcon: true
}

export default memo(LikeCard)
