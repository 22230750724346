import React, { useMemo } from 'react'
import clsx from 'clsx'
import classes from './MatchesBar.module.scss'
import Typography from 'components/ui/Typography'
import MatchItem from './MatchItem'
import BaseHorizontalScroll from 'components/wrappers/ui/BaseHorizontalScroll'

const matchItemClass = {
	root: classes.matchItem
}

const MatchesBar = ({
	list = [],
	onClickMatchPerson: handleClickMatchPerson,
	classes: outerClasses = {},
	loadMoreMatches,
	hasMore
}) => {
	const matchCount = useMemo(() => (list.length < 100 ? list.length : '99+'), [
		list
	])

	const matches = useMemo(
		() =>
			list?.map((id, index) => (
				<MatchItem
					key={`match-${index}`}
					classes={matchItemClass}
					id={id}
					onClickMatchPerson={handleClickMatchPerson}
				/>
			)),
		[handleClickMatchPerson, list]
	)

	return (
		<div className={clsx(classes.root, outerClasses.root)}>
			<Typography className={classes.title}>
				New Matches ({matchCount})
			</Typography>

			<BaseHorizontalScroll
				data={matches}
				inertiaScrolling
				inertiaScrollingSlowdown={1.5}
				menuClass={classes.container}
				innerWrapperClass={classes.innerWrapper}
				itemClassActive={classes.active}
				wrapperClass={classes.wrapper}
				onLoadMore={loadMoreMatches}
				hasMore={hasMore}
				wheel={matches?.length > 3}
				alignOnResize={true}
			/>
		</div>
	)
}

export default MatchesBar
