export const stories = [
	{
		text: 'Agi’s First Attempt With Online Dating Led Her To Soulmate Ano.',
		title: 'Agi & Ano',
		image: 'https://tamilculture.com/storage/post_images/646b2f79f369b_original.png',
		link:
			'https://tamilculture.com/mytamildate-success-agis-first-attempt-with-online-dating-led-her-to-soulmate-ano'
	},
	{
		text:
			'A Gentle Push Towards Online Dating By Family Brought Céline & Santhous Together.',
		title: 'Céline & Santhous',
		image:
			'https://tamilculture.com/storage/post_images/6362c13962bb4_original.jpg',
		link:
			'https://tamilculture.com/mytamildate-success-a-gentle-push-towards-online-dating-by-family-brought-celine-santhous-together'
	},
	{
		text: 'The Second Time’s the Charm for Aranie & Saaron.',
		title: 'Aranie & Saaron',
		image:
			'https://tamilculture.com/storage/post_images/633482f32fefb_original.png',
		imageAlign: 'left',
		link:
			'https://tamilculture.com/mytamildate-success-the-second-times-the-charm-for-aranie-saaron'
	},
	{
		text: 'It was love at first sight for Madhu and Niya.',
		title: 'Madhu & Niya',
		image: 'assets/successStories/8.png',
		link:
			'https://tamilculture.com/index.php/mytamildate-success-story-it-was-love-at-first-sight-for-madhu-niya'
	},
	{
		text:
			"Suji & Sinthu's journey is a heartwarming example of fate, timing and going after what you want!",
		title: 'Suji & Sinthu',
		image: 'assets/successStories/5.png',
		link:
			'https://tamilculture.com/mytamildate-love-story-patience-and-taking-chances-brought-suji-sinthu-together'
	},
	{
		text:
			"Jenani & Nav's story was all about being at the right place and at the right time in life for their love to blossom into a marriage!",
		title: 'Jenani & Nav',
		image: 'assets/successStories/6.png',
		link:
			'https://tamilculture.com/mytamildate-love-story-jenani-nav-found-each-other-at-the-right-time-and-right-place-in-life'
	},
	{
		text:
			'Tharshi & Ravi (names have been changed for privacy) are our latest myTamilDate.com success story. Their journey shows us the importance of knowing and loving yourself first as a prerequisite to finding true love and a solid partnership.',
		title: 'Tharshi & Ravi',
		image: 'assets/successStories/7.png',
		link:
			'https://tamilculture.com/mytamildate-love-story-tharshi-ravi-found-love-during-lockdown'
	},
	{
		text:
			'Shana, a 28-year-old who was born and raised in Paris, France joined myTamilDate.com on August 2019 and came across 31-year-old Ramesh’s profile, which immediately caught her attention. Now, the couple are planning their upcoming nuptials! This is their story.',
		title: 'Shana & Ramesh',
		image: 'assets/successStories/1.jpg',
		link:
			'https://tamilculture.com/how-france-met-canada-a-mytamildatecom-love-story'
	},
	{
		text:
			'My husband was born  in Montreal and came to Toronto when he was 2 years old. Fun fact: he is an identical twin! I was born in Sri Lanka and came to Canada when I was 3, so we are as Canadian as can be and super patriotic at that!',
		title: 'Sanjutha & Roban',
		image: 'assets/successStories/2.jpg',
		link: 'https://tiethethali.com/sanjutha-roban/'
	},
	{
		text:
			'Fast forward to when I turned 24, my friend told me about myTamilDate, I didn’t want to sign up initially, but because it was free to join and I was curious about online dating, I thought ‘why not see what’s out there.’ That’s when I came across Sathish’s picture and his million dollar smile, but I didn’t reach out to him. The next day, I kept thinking about his captivating smile, so I logged back in to search for him again...',
		title: 'Sathish & Sahana',
		image: 'assets/successStories/3.jpg',
		link:
			'https://tamilculture.com/toronto-to-new-york-a-digital-love-that-transcends-borders'
	},
	{
		text:
			'Lavanya, 27, is a healthcare professional.  Her interests include personal growth, weight training and spending time with family and friends. \n' +
			'\n' +
			'Vitharan, 28, is an educator and social worker. He enjoys traveling, reading and practicing yoga.\n' +
			'\n' +
			'Their first date: We spent the entire day together, roaming the busy streets downtown, listening to live music at an outdoor concert and watching fireworks.',
		title: 'Lavanya & Vitharan',
		image: 'assets/successStories/4.jpg',
		link:
			'https://tamilculture.com/how-a-message-on-mytamildatecom-led-to-an-engagement-for-lavanya-vitharan'
	}
]
