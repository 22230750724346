import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getCurrentConversation,
	getMateIdByConversationId
} from 'modules/conversation/store/selectors.js'
import { getIdFromDataAttribute } from 'utils/getIdFromDataAttribute.js'
import { likeApplicant, rejectApplicant } from 'modules/discovery/services.js'
import { removeReceivedRequest } from 'modules/notifications/requests/store/actions.js'
import { clearMessengerMeta } from 'modules/conversation/store/actions.js'
import useHistory from 'hooks/useHistory.js'
import { routesByName } from 'constants/routes.js'
import { matchConnected } from 'modules/notifications/matches/services'
import useFlagManager from 'hooks/useFlagManager'

const ReceivedConversationContainer = ({ children }) => {
	const conversationId = useSelector(getCurrentConversation)
	const mateId = useSelector(getMateIdByConversationId(conversationId))
	const dispatch = useDispatch()
	const history = useHistory()
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const handleClickLike = useCallback(
		async event => {
			const id = getIdFromDataAttribute(event)

			try {
				await likeApplicant(id)
				await matchConnected(mateId)
				await dispatch(removeReceivedRequest(conversationId))
				await dispatch(clearMessengerMeta())

				history.replace(routesByName.main.notifications.requests.received)
			} catch (e) {
				setError(e.message)
				infoDialog.turnIn()
			}
		},
		[conversationId, dispatch, history, infoDialog, mateId]
	)

	const handleClickPass = useCallback(
		async event => {
			const id = getIdFromDataAttribute(event)

			try {
				await rejectApplicant(id)
				await dispatch(removeReceivedRequest(conversationId))
				await dispatch(clearMessengerMeta())

				history.replace(routesByName.main.notifications.requests.received)
			} catch (e) {
				setError(e.message)
				infoDialog.turnIn()
			}
		},
		[conversationId, dispatch, history, infoDialog]
	)

	const props = useMemo(
		() => ({
			onClickLike: handleClickLike,
			onClickPass: handleClickPass,
			userId: mateId,
			infoDialog: {
				state: infoDialog.state,
				close: infoDialog.turnOff,
				error: error
			}
		}),
		[
			error,
			handleClickLike,
			handleClickPass,
			infoDialog.state,
			infoDialog.turnOff,
			mateId
		]
	)

	return children(props)
}

export default ReceivedConversationContainer
