import React, { memo, useCallback } from 'react'
import classes from './BaseTabs.module.scss'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import useHistory from 'hooks/useHistory'
import { useRouteMatch } from 'react-router-dom'

const Tab = memo(
	({
		label = '',
		value = '',
		isSelect = false,
		className = '',
		path = '',
		onClick = () => null
	}) => {
		const history = useHistory()
		const isCurrentPath = useRouteMatch(path)

		const handleClick = useCallback(() => {
			onClick(value)
			if (path) {
				history.push(path)
			}
		}, [history, path, onClick, value])

		return (
			<div
				role="presentation"
				className={clsx(classes.tab, className, {
					[classes.selected]: isSelect || (isCurrentPath && path)
				})}
				onClick={handleClick}
			>
				{label}
			</div>
		)
	}
)

const BaseTabs = ({
	tabs = [],
	value = '',
	onChange: handleChange = () => null,
	variant = 'primary'
}) => {
	return (
		<div className={clsx(classes.root, classes[variant])}>
			{tabs.map((data, index) => (
				<Tab
					className={classes[variant]}
					key={`tab-${index}`}
					isSelect={data.value === value}
					onClick={handleChange}
					value={data.value}
					label={data.label}
					path={data.path}
				/>
			))}
		</div>
	)
}

BaseTabs.propTypes = {
	variant: PropTypes.oneOf(['secondary', 'primary']),
	value: PropTypes.any,
	onChange: PropTypes.func,
	tabs: PropTypes.array
}

export default memo(BaseTabs)
