import React, { memo, useMemo } from 'react'
import MobilePreviewProfile from 'modules/profile/ProfileMobileView/Preview/MobilePreviewProfile'
import DesktopPreviewProfile from 'modules/profile/ProfileDesktopView/Preview/DesktopPreviewProfile'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import classes from 'modules/discovery/DiscoveryView.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, primaryColor } from 'constants/colors'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import { screenFormat } from 'constants/screenFormat'
import Typography from 'components/ui/Typography'
import TextPlaceholder from 'components/ui/layout/TextPlaceholder'
import { useDispatch, useSelector } from 'react-redux'
import { getRejectReason } from 'modules/profile/store/selectors'
import UnpauseAccount from 'pages/UnpauseAccount'
import UpgradeBannerDialog from './components/UpgradeBannerDialog'
import useUpgradeNotification, {
	APPROVED,
	PENDING,
	REGISTERED,
	REJECTED,
	UNKNOWN
} from 'hooks/useUpgradeNotification'
import { resendLink } from "../auth/store/actions";

const variantByFormat = {
	mobile: MobilePreviewProfile,
	desktop: DesktopPreviewProfile
}

const DiscoveryView = ({
	formProps = {},
	applicantId,
	reportModal = {},
	onClickLike,
	onClickPass,
	onClickFilter,
	onClickBlock,
	onClickReport,
	onClickUndo,
	onClickSendMessage,
	onClickConfirmReport,
	userApproval,
	isAccountPaused,
	scrollRef,
	usersAreOver
}) => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const rejectReason = useSelector(getRejectReason)
	const { isSubscribe } = useUpgradeNotification()
	const dispatch = useDispatch()
	const myId = useSelector(({ profile }) => profile.user.id)

	const sendConfirmLink = () => {
		dispatch(resendLink({id: myId}))
	};

	const pendingText = (
		<div className={classes.withoutApproveText}>
			<div>Almost there!</div>
			You can view other MTD members once your profile is approved. Please
			contact us at <span className={classes.email}>
				hello@mytamildate.com
			</span>{' '}
			if you haven't been approved within 48 hours of registering.
		</div>
	)

	const approvedText =
		"Hold tight!  We're just loading up some more potential matches for you!"

	const rejectedText = `Sorry! We can't approve your profile just yet. Reason: ${rejectReason} Please make the changes asap to get approved.`

	const registeredText = <div className={classes.confirmEmailWrapper}>You did not confirm your e-mail, we have sent mail with link to you.
		<Button
			size="sm"
			shape="rounded"
			color="white"
			variant="outlined"
			onClick={sendConfirmLink}
		>
			<Typography>Resend link</Typography>
		</Button>

	</div>

	const usersAreOverText =
		'Please update your Preferences to discover more users!'

	const CurrentView = useMemo(() => variantByFormat[format], [format])

	const isUserApproved = userApproval === APPROVED

	const renderDesktopButtons = useMemo(
		() =>
			format === screenFormat.desktop ? (
				<div className={classes.actionsWrapper}>
					<Button
						size="sm"
						shape="rounded"
						color="white"
						variant="outlined"
						icon={
							<SpriteIcon
								name="filters"
								className={classes.filtersBtn}
								color={black3}
							/>
						}
						className={clsx(classes.actionBtn, 'mr-1')}
						onClick={onClickFilter}
					>
						<Typography>Search Preferences</Typography>
					</Button>

					{isUserApproved && applicantId ? (
						<>
							<Button
								size="sm"
								shape="rounded"
								color="white"
								variant="outlined"
								className={clsx(classes.actionBtn, 'mr-1')}
								onClick={onClickReport}
							>
								<Typography>Report</Typography>
							</Button>
							<Button
								size="sm"
								shape="rounded"
								color="white"
								variant="outlined"
								className={classes.actionBtn}
								onClick={onClickBlock}
							>
								<Typography>Block</Typography>
							</Button>
						</>
					) : null}
				</div>
			) : null,
		[
			format,
			onClickFilter,
			isUserApproved,
			applicantId,
			onClickReport,
			onClickBlock
		]
	)

	const renderMobileButtons = useMemo(
		() =>
			format === screenFormat.mobile ? (
				<>
					{isUserApproved && applicantId ? (
						<>
							<Button
								size="xl"
								shape="rounded"
								color="white"
								className={clsx(classes.btn, classes.likeBtn)}
								onClick={onClickLike}
								data-id={applicantId}
							>
								<SpriteIcon
									name="like"
									className={classes.icon}
									color={primaryColor}
								/>
							</Button>
							<Button
								size="xl"
								shape="rounded"
								color="white"
								className={clsx(classes.btn, classes.passBtn)}
								data-id={applicantId}
								onClick={onClickPass}
							>
								<SpriteIcon
									name="close"
									className={classes.icon}
									color={black3}
								/>
							</Button>
						</>
					) : null}
					<SpriteIcon
						name="filters"
						className={classes.filtersBtn}
						onClick={onClickFilter}
						color={black3}
					/>
				</>
			) : null,
		[
			applicantId,
			format,
			isUserApproved,
			onClickFilter,
			onClickLike,
			onClickPass
		]
	)

	const placeholderText = useMemo(() => {
		if (usersAreOver) return usersAreOverText
		switch (userApproval) {
			case PENDING:
				return pendingText
			case UNKNOWN:
				return null
			case APPROVED:
				return applicantId ? null : approvedText
			case REJECTED:
				return rejectedText
			case REGISTERED:
				return registeredText
			default:
				return null
		}
	}, [applicantId, pendingText, rejectedText, userApproval, usersAreOver])

	return (
		<div
			className={clsx(classes.root, {
				[classes.height]: !applicantId
			})}
		>
			{!isAccountPaused && renderMobileButtons}
			{isAccountPaused ? (
				<UnpauseAccount />
			) : applicantId && isUserApproved ? (
				<>
					<CurrentView
						onClickBlock={onClickBlock}
						onClickPass={onClickPass}
						onClickReport={onClickReport}
						onClickConfirmReport={onClickConfirmReport}
						onClickLike={onClickLike}
						onClickSendMessage={onClickSendMessage}
						onClickUndo={onClickUndo}
						reportModal={reportModal}
						userId={applicantId}
						scrollRef={scrollRef}
						{...formProps}
					/>
					{!isSubscribe && <UpgradeBannerDialog />}
				</>
			) : (
				<TextPlaceholder text={placeholderText} />
			)}
			{!isAccountPaused && renderDesktopButtons}
		</div>
	)
}

export default memo(DiscoveryView)
