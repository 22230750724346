import React, { memo } from 'react'
import clsx from 'clsx'
import classes from 'modules/conversation/Conversation.module.scss'
import { ChatBox } from '@rznv/components'
import ChatInput from './components/ChatInput'
import Message from './components/Message'
import DateDelimiter from './components/DateDelimiter'

const chatBoxStyles = {
	root: classes.chatBox,
	wrapper: classes.chatBoxWrapper
}

const components = {
	Message,
	DateDelimiter
	// ToBottomButton
}

const ConversationView = ({
	outerClasses = {},
	messages = [],
	loadMore = () => null,
	myId,
	value,
	hasNextPage = false,
	onChange = () => null,
	onClickInput = () => null,
	onPush = () => null,
	onClickPushBtn = () => null,
	hideInput = false,
	onBlur = false,
	inputRef,
	inputContainerRef,
	isConversationBlocked,
	onMouseOver = () => null,
	onFocus = () => null
}) => {
	// const transformedMessages = messages?.map(message => ({
	// 	...message,
	// 	sentAt: message.sentAt * 1000
	// }))

	return (
		<div
			className={clsx(classes.root, outerClasses.root)}
			role="presentation"
			onMouseOver={onMouseOver}
			onFocus={onFocus}
		>
			<ChatBox
				messages={isConversationBlocked ? [] : messages}
				classes={chatBoxStyles}
				hasNextPage={hasNextPage}
				myId={myId}
				loadMore={loadMore}
				overloadFactor={30}
				components={components}
			/>
			{!hideInput ? (
				<ChatInput
					value={value}
					onChange={onChange}
					onClickInput={onClickInput}
					onBlur={onBlur}
					onPush={onPush}
					onClickPushBtn={onClickPushBtn}
					inputRef={inputRef}
					inputContainerRef={inputContainerRef}
					disabled={isConversationBlocked}
				/>
			) : null}
		</div>
	)
}

export default memo(ConversationView)
