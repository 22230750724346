import _useEffect from 'hooks/_useEffect'
import { routesByName } from 'constants/routes'
import { useSelector } from 'react-redux'
import {
	getFinishedCreatingProfile,
	getMe
} from 'modules/profile/store/selectors'
import useHistory from 'hooks/useHistory'
import { useLocation } from 'react-router-dom'

const useFillEmptyValues = () => {
	const { email, phone } = useSelector(getMe)
	const history = useHistory()
	const location = useLocation()
	const finishedCreatingProfile = useSelector(getFinishedCreatingProfile)

	_useEffect(() => {
		if (email === null) {
			history.push(routesByName.enterEmail)
		}
	}, [email])

	_useEffect(() => {
		if (email !== null && !finishedCreatingProfile) {
			history.push(routesByName.createProfile)
		}
	}, [email, finishedCreatingProfile])

	_useEffect(() => {
		if (
			phone === null &&
			location.pathname !==
				routesByName.main.settings.changeIdentityWithType('phone')
		) {
			history.push(routesByName.main.settings.changeIdentityWithType('phone'))
		}
	}, [location])
}

export default useFillEmptyValues
