import React, { useCallback, useMemo, useRef } from 'react'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import SpriteIcon from '../../../ui/SpriteIcon'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import classes from 'modules/notifications/matches/components/MatchesBar/MatchesBar.module.scss'
import useFlagManager from 'hooks/useFlagManager'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const ArrowLeft = (
	<div className={clsx(classes.arrow, classes.arrowPrev)}>
		<SpriteIcon name="arrow-next2" />
	</div>
)
const ArrowRight = (
	<div className={clsx(classes.arrow, classes.arrowNext)}>
		<SpriteIcon name="arrow-next2" />
	</div>
)

const BaseHorizontalScroll = ({
	inertiaScrollingSlowdown = 1.5,
	onFistItemVisible,
	onLastItemVisible,
	onLoadMore,
	hasMore,
	...props
}) => {
	const firstItemVisible = useFlagManager(true)
	const lastItemVisible = useFlagManager(false)

	const format = useDetermineScreenFormat(breakpointsUp.md)

	const isDesktopFormat = useMemo(() => format === screenFormat.desktop, [
		format
	])

	const scrollMenuRef = useRef()

	const handleUpdate = useCallback(
		({ translate }) => {
			if (translate === scrollMenuRef.current.getOffsetAtEnd()) {
				firstItemVisible.turnOff()
				if (onLoadMore && hasMore) {
					onLoadMore()
					lastItemVisible.turnOff()
				}
				if (!hasMore) {
					lastItemVisible.turnIn()
				}
			} else if (translate === scrollMenuRef.current.getOffsetAtStart()) {
				firstItemVisible.turnIn()
			} else {
				lastItemVisible.turnOff()
				firstItemVisible.turnOff()
			}
		},
		[lastItemVisible, firstItemVisible, hasMore, onLoadMore]
	)

	const menuWidth = scrollMenuRef.current?.menuWidth
	const allItemsWidth = scrollMenuRef.current?.allItemsWidth
	const showArrows = isDesktopFormat && menuWidth < allItemsWidth

	return (
		<ScrollMenu
			inertiaScrollingSlowdown={inertiaScrollingSlowdown}
			arrowLeft={!firstItemVisible.state && showArrows ? ArrowLeft : null}
			arrowRight={!lastItemVisible.state && showArrows ? ArrowRight : null}
			hideArrows
			hideSingleArrow
			ref={scrollMenuRef}
			onUpdate={handleUpdate}
			{...props}
		/>
	)
}

BaseHorizontalScroll.propTypes = {
	onFirstItemVisible: PropTypes.func,
	onLastItemVisible: PropTypes.func
}

BaseHorizontalScroll.defaultProps = {
	onFirstItemVisible: () => null,
	onLastItemVisible: () => null
}

export default BaseHorizontalScroll
