import {
	RESEND_VERIFY_CODE,
	SEND_VERIFY_CODE,
	SIGN_UP
} from 'modules/auth/signUp/store/constants'
import {
	verifyCreate,
	verifyResend,
	signUp
} from 'modules/auth/signUp/services'
import { _get } from 'utils/lodash'
import { setAuthorizationData } from 'utils/authorize'
import { initAuthAction } from 'modules/auth/store/actions'

const verifyTokenField = 'verifyToken'

export const sendVerifyCodeActions = ({
	phone: { international: identity },
	recaptchaToken
}) => async dispatch => {
	try {
		const response = await verifyCreate({ identity, recaptchaToken })
		const expiresIn = _get(response, 'result.expiresIn', '')

		localStorage.setItem(
			verifyTokenField,
			_get(response, `result.${verifyTokenField}`, '')
		)

		dispatch({
			type: SEND_VERIFY_CODE,
			payload: { identity, expiresIn }
		})
	} catch (e) {
		console.dir(e)
		throw e
	}
}

export const resendVerifyCodeActions = ({
	recaptchaToken
}) => async dispatch => {
	try {
		const verifyToken = localStorage.getItem(verifyTokenField)
		const response = await verifyResend({ verifyToken, recaptchaToken })
		const expiresIn = _get(response, 'result.expiresIn', '')

		dispatch({ type: RESEND_VERIFY_CODE, payload: { expiresIn } })
	} catch (e) {
		console.dir(e)
		throw e
	}
}

export const signUpActions = values => async (dispatch, getState) => {
	try {
		const verifyToken = localStorage.getItem(verifyTokenField)
		const {
			signUp: { identity }
		} = getState()

		const response = await signUp({
			code: values.verify_code,
			identity,
			verifyToken
		})

		setAuthorizationData(response)

		localStorage.removeItem(verifyTokenField)

		dispatch(initAuthAction())
		dispatch({ type: SIGN_UP })
	} catch (e) {
		console.dir(e)
		throw e
	}
}
