import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './ProfileButton.module.scss'
import clsx from 'clsx'

const ProfileButton = ({
	children,
	variant = 'primary',
	className,
	name,
	onClick = () => null
}) => {
	const handleClick = useCallback(() => {
		onClick(name)
	}, [name, onClick])

	return (
		<div
			role="presentation"
			onClick={handleClick}
			className={clsx(classes.root, className, classes[variant])}
		>
			{children}
		</div>
	)
}

ProfileButton.propTypes = {
	className: PropTypes.string
}

ProfileButton.defaultProps = {
	className: ''
}

export default ProfileButton
