import {
	enterFirstName,
	firstName,
	aboutMe,
	answers,
	birthday,
	chooseEducationLevel,
	chooseGender,
	chooseGenderForDate,
	chooseGrowth,
	chooseJob,
	chooseLanguages,
	chooseReligion,
	chooseSelfLocation,
	describePersonality,
	doYouDrink,
	doYouSmoke,
	drink,
	enterBirthday,
	gender,
	growth,
	job,
	kid,
	languages,
	location,
	personalities,
	religion,
	smoke,
	study,
	wantGender
} from 'modules/profile/createProfile/config.js'
import { configFields } from 'modules/profile/store/constants'
import FormikFieldRadioButtonsGroup from 'components/wrappers/formik/FormikFieldRadioButtonsGroup'
import React from 'react'
import yup from 'libs/yup'
import { putKid } from 'modules/profile/services'
import { familyPlans } from 'modules/profile/createProfile/config'

export const editorConfig = {
	[firstName]: {
		Input: enterFirstName.Inputs,
		header: 'Enter user name',
		service: enterFirstName.handleValues,
		validationSchema: enterFirstName.validationSchema
	},
	[smoke]: {
		Input: doYouSmoke.Inputs,
		header: 'Do you smoke?',
		service: doYouSmoke.handleValues,
		validationSchema: doYouSmoke.validationSchema
	},
	[drink]: {
		Input: doYouDrink.Inputs,
		header: 'Do you drink?',
		service: doYouDrink.handleValues,
		validationSchema: doYouDrink.validationSchema
	},
	[kid]: {
		Input: ({ config, ...props }) => (
			<FormikFieldRadioButtonsGroup
				name={kid}
				options={config[configFields.haveKids].options}
				{...props}
			/>
		),
		header: 'What about kids?',
		service: data => putKid({ [kid]: data[kid] }),
		validationSchema: yup.object().shape({
			[kid]: yup.string().required('Please make a selection')
		})
	},
	[familyPlans]: {
		Input: ({ config, ...props }) => (
			<FormikFieldRadioButtonsGroup
				name={familyPlans}
				options={config[configFields.familyPlans].options}
				{...props}
			/>
		),
		header: 'Family plans?',
		service: data => putKid({ [familyPlans]: data[familyPlans] }),
		validationSchema: yup.object().shape({
			[familyPlans]: yup.string().required('Please make a selection')
		})
	},
	[birthday]: {
		Input: enterBirthday.Inputs,
		header: 'When is Your Birthday?',
		service: enterBirthday.handleValues,
		validationSchema: enterBirthday.validationSchema
	},
	[gender]: {
		Input: chooseGender.Inputs,
		header: 'What Is Your Gender?',
		service: chooseGender.handleValues,
		validationSchema: chooseGender.validationSchema
	},
	[location]: {
		Input: chooseSelfLocation.Inputs,
		header: 'Where Do You Live?',
		service: chooseSelfLocation.handleValues,
		validationSchema: chooseSelfLocation.validationSchema
	},
	[religion]: {
		Input: chooseReligion.Inputs,
		header: 'Religion',
		service: chooseReligion.handleValues,
		validationSchema: chooseReligion.validationSchema
	},
	[study]: {
		Input: chooseEducationLevel.Inputs,
		header: "What's the Highest Level you've attained?",
		service: chooseEducationLevel.handleValues,
		validationSchema: chooseEducationLevel.validationSchema
	},
	[job]: {
		Input: chooseJob.Inputs,
		header: "What's Your Job Title?",
		service: chooseJob.handleValues,
		validationSchema: chooseJob.validationSchema
	},
	[growth]: {
		Input: chooseGrowth.Inputs,
		header: 'How Tall Are You?',
		service: chooseGrowth.handleValues,
		validationSchema: chooseGrowth.validationSchema
	},
	[languages]: {
		Input: chooseLanguages.Inputs,
		header: 'What Language(s) Are You Fluent In?',
		service: chooseLanguages.handleValues,
		validationSchema: chooseLanguages.validationSchema
	},
	[wantGender]: {
		Input: chooseGenderForDate.Inputs,
		header: 'Who Do You Want To Date?',
		service: chooseGenderForDate.handleValues,
		validationSchema: chooseGenderForDate.validationSchema
	},
	[answers]: {
		Input: aboutMe.Inputs,
		header: 'About Me',
		service: aboutMe.handleValues,
		validationSchema: aboutMe.validationSchema
	},
	[personalities]: {
		Input: describePersonality.Inputs,
		header: 'How would you describe your personality?',
		service: describePersonality.handleValues,
		validationSchema: describePersonality.validationSchema
	}
}
