import React from 'react'
import classes from './Subscribe.module.scss'
import Typography from 'components/ui/Typography'
import BaseCard from 'components/ui/controls/BaseCard'
import SpriteIcon from 'components/ui/SpriteIcon'
import { primaryColor } from 'constants/colors'
import PaymentForm from 'modules/subscriptionBilling/PaymentForm'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import SuccessPaymentDialog from 'components/ui/Dialogs/SuccessPaymentDialog'
import PaymentCard from 'modules/subscriptionBilling/billing/components/PaymentCard'
import Button from 'components/ui/Button'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'

const SubscribeView = ({
	currentPlan,
	onSubmitPaymentForm,
	isOpenSuccessDialog,
	onCloseSuccessDialog,
	promoCode,
	paymentsInfo,
	onClickEdit,
	infoDialog
}) => {
	return (
		<div className={classes.root}>
			<BaseScrollbars>
				<div className={classes.content}>
					<BaseCard className={classes.selectedPlan}>
						<div className={classes.planDescription}>
							<SpriteIcon name="crown" color={primaryColor} />
							<Typography className={classes.planTitle}>
								Premium Account Upgrade ({currentPlan?.month}{' '}
								{`month${currentPlan?.month > 1 ? 's' : ''}`})
							</Typography>
						</div>
						<div className={classes.bill}>
							<div>
								<p className={classes.billItem}>Subtotal:</p>

								{(promoCode?.discount || promoCode?.discount === 0) && (
									<p className={classes.billItem}>Promotion:</p>
								)}

								<p className={classes.billItem}>Total:</p>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								<span
									style={{ fontWeight: 500, marginLeft: '10px' }}
									className={classes.billItem}
								>
									${currentPlan?.price?.toFixed(2)} CAD
								</span>

								{(promoCode?.discount || promoCode?.discount === 0) && (
									<span
										style={{
											fontWeight: 500,
											marginLeft: '13px',
											color: primaryColor
										}}
										className={classes.billItem}
									>
										-$
										{(currentPlan?.price * promoCode.discount)?.toFixed(2)} CAD
									</span>
								)}

								<span
									style={{ fontWeight: 500, marginLeft: '10px' }}
									className={classes.billItem}
								>
									$
									{(
										currentPlan?.price -
										currentPlan?.price * promoCode.discount
									)?.toFixed(2)}{' '}
									CAD
								</span>
							</div>
						</div>
					</BaseCard>
					{promoCode.Component}
					<BaseCard className={classes.cardDetails}>
						{!paymentsInfo.length ? (
							<PaymentForm
								onSubmit={onSubmitPaymentForm}
								currentPlan={currentPlan}
								promoCode={promoCode}
							/>
						) : (
							<div className={classes.selectCard}>
								{paymentsInfo.map(paymentCard => (
									<PaymentCard
										key={`payment-card-${paymentCard.pm}}`}
										onClick={onClickEdit}
										{...paymentCard}
									/>
								))}
								<Button
									onClick={onSubmitPaymentForm}
									shape="rounded"
									size="md"
									className={classes.submit}
								>
									Confirm
								</Button>
							</div>
						)}
					</BaseCard>
				</div>
			</BaseScrollbars>
			<SuccessPaymentDialog
				open={isOpenSuccessDialog}
				onClose={onCloseSuccessDialog}
			/>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.close}
				type={'error'}
				title={'Error'}
				body={infoDialog.error}
			/>
		</div>
	)
}

export default SubscribeView
