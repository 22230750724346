import { routesByName } from 'constants/routes'
import mapRouteConfig from 'utils/mapRouteConfig'
import Upgrade from 'pages/Upgrade/Upgrade'
import MainPage from 'modules/main'
import EnterEmail from 'modules/auth/EnterEmail'
import ConversationMobile from 'modules/conversation/ConversationMobile'
import RequestCreateMobile from 'modules/notifications/requests/createConversation/RequestCreateMobile'
import MatchesCreateMobile from 'modules/notifications/matches/createConversation/MatchesCreateMobile'
import ReceivedConversationMobile from 'modules/notifications/requests/receivedConversation/ReceivedConversationMobile'
import LastMatchCreateMobile from 'modules/notifications/matches/lastMatch/createConversation/LastMatchCreateMobile'
import SelectedPlanPage from 'modules/profile/createProfile/components/SelectedPlanPage'

const PrivateRouteConfig = [
	{
		path: routesByName.main.notifications.matches.mobileConversationCreate,
		component: MatchesCreateMobile,
		onlyMobile: true
	},
	{
		path: routesByName.upgrade.root,
		component: Upgrade
	},
	{
		path: routesByName.enterEmail,
		component: EnterEmail
	},
	{
		path: routesByName.conversation.root,
		component: ConversationMobile,
		onlyMobile: true
	},
	{
		path: routesByName.main.notifications.requests.sentConversationCreate,
		component: RequestCreateMobile,
		onlyMobile: true
	},
	{
		path: routesByName.main.notifications.requests.mobileReceivedConversation,
		component: ReceivedConversationMobile,
		onlyMobile: true
	},
	{
		path: routesByName.main.lastMatch.mobileConversationCreate,
		component: LastMatchCreateMobile,
		onlyMobile: true
	},
	{
		path: routesByName.selectedPlan,
		component: SelectedPlanPage
	},
	{
		path: routesByName.main.root,
		component: MainPage
	}
]

const result = mapRouteConfig(PrivateRouteConfig)

export default result
