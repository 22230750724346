import React from 'react'
import MatchesCreateContainer from './MatchesCreateContainer.js'
import CreateConversationPage from 'modules/conversation/create/CreateConversationPage.js'

const MatchesCreatePage = () => {
	return (
		<MatchesCreateContainer>
			{({ onPushMessage }) => (
				<CreateConversationPage onPushMessage={onPushMessage} />
			)}
		</MatchesCreateContainer>
	)
}

export default MatchesCreatePage
