import ProfileContainer from 'modules/profile/ProfileContainer.js'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import MobilePreviewProfile from 'modules/profile/ProfileMobileView/Preview/MobilePreviewProfile.js'
import React, { useRef, useEffect } from 'react'

const ProfilePreviewMatePage = ({ mate }) => {
	const scrollRef = useRef(null)

	useEffect(() => {
		if (scrollRef) {
			scrollRef.current.view.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		}
	}, [mate, scrollRef])

	return (
		<ProfileContainer user={mate}>
			{({
				formProps,
				onClickBlock,
				onClickReport,
				onClickUnmatch,
				onClickWeMeet,
				onClickConfirmReport,
				onClickConfirmUnmatch,
				reportModal,
				blockModal,
				undoModal,
				unmatchModal,
				onClickUnblock,
				isCurrentUserBlocked
			}) => (
				<BaseScrollbars>
					<MobilePreviewProfile
						{...formProps}
						onClickBlock={onClickBlock}
						onClickReport={onClickReport}
						reportModal={reportModal}
						unmatchModal={unmatchModal}
						blockModal={blockModal}
						undoModal={undoModal}
						onClickWeMeet={onClickWeMeet}
						onClickUnmatch={onClickUnmatch}
						onClickConfirmReport={onClickConfirmReport}
						onClickConfirmUnmatch={onClickConfirmUnmatch}
						scrollRef={scrollRef}
						onClickUnblock={onClickUnblock}
						isCurrentUserBlocked={isCurrentUserBlocked}
					/>
				</BaseScrollbars>
			)}
		</ProfileContainer>
	)
}

export default ProfilePreviewMatePage
