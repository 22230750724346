import React from 'react'
import classes from './TermsAndConditions.module.scss'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Button from 'components/ui/Button'
import { content } from './config'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'

const navigationHeaderClasses = {
	root: classes.navigationHeader
}

const TermsAndConditions = ({ open, onClose }) => {
	const format = useDetermineScreenFormat(breakpointsUp.md)
	return (
		<BaseDialog
			open={open}
			onClose={onClose}
			fullScreen={format === screenFormat.mobile}
			maxWidth="md"
		>
			<div className={classes.root}>
				<NavigationHeader
					title="Terms and Conditions"
					onClickBackIcon={onClose}
					classes={navigationHeaderClasses}
				/>
				<div className={classes.wrapper}>
					<div className={classes.text}>{content}</div>
					<Button className={classes.btn} onClick={onClose} shape="rounded">
						OK
					</Button>
				</div>
			</div>
		</BaseDialog>
	)
}

export default TermsAndConditions
