import React from 'react'
import LastMatchCreateContainer from './LastMatchCreateContainer.js'
import CreateConversationPage from 'modules/conversation/create/CreateConversationPage.js'

const LastMatchCreatePage = () => {
	return (
		<LastMatchCreateContainer>
			{({ onPushMessage }) => (
				<CreateConversationPage onPushMessage={onPushMessage} />
			)}
		</LastMatchCreateContainer>
	)
}

export default LastMatchCreatePage
