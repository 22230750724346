import FormikFieldCodeInput from 'components/wrappers/formik/FormikFieldCodeInput'
import React, { useCallback, useState } from 'react'
import FormikFieldPhoneInput from 'components/wrappers/formik/FormikFieldPhoneInput'
import Typography from 'components/ui/Typography'
import {
	resendVerifyCodeActions,
	sendVerifyCodeActions,
	signUpActions
} from 'modules/auth/signUp/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import classes from 'modules/auth/signUp/View.module.scss'
import Link from 'components/ui/Typography/Link'

import yup from 'libs/yup'
import {
	getVerifyExpire,
	getVerifyIdentity
} from 'modules/auth/signUp/store/selectors.js'
import { toggleLoader } from 'modules/app/store/actions.js'
import { _isEmpty } from '../../../utils/lodash'
import * as Sentry from '@sentry/browser'
import InfoDialog from '../../../components/ui/Dialogs/InfoDialog'
import useFlagManager from '../../../hooks/useFlagManager'

const EnterCodeDescription = () => (
	<Typography>
		Please enter the 4-digit code sent to you at{' '}
		<b>{useSelector(getVerifyIdentity)}</b> within <b>3 minutes</b> for
		security.
	</Typography>
)

const ResendCode = () => {
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')
	const dispatch = useDispatch()
	const recaptcha = window.grecaptcha

	const handleClick = useCallback(async () => {
		try {
			dispatch(toggleLoader())
			const recaptchaToken = await recaptcha.execute(
				process.env.REACT_APP_RECAPCTHA_SITE_KEY,
				{ action: 'resend_phone_verification' }
			)
			if (recaptchaToken) {
				await dispatch(resendVerifyCodeActions({ recaptchaToken }))
			}
		} catch (e) {
			const errorMsg = !_isEmpty(e?.validation)
				? e.validationErrors.identity.join('\n')
				: e.getException
			const recaptchaError =
				e.response?.data.error.code === 'HTTP429'
					? 'Too many requests, please try again in a few minutes.'
					: e.response?.data.error.message
			setError(errorMsg || recaptchaError)
			infoDialog.turnIn()
			Sentry.captureException(e)
		} finally {
			dispatch(toggleLoader())
		}
	}, [dispatch, recaptcha, infoDialog])

	return (
		<>
			<Link
				variant="secondary"
				className={classes.link}
				onClick={handleClick}
				underline
			>
				Resend Code
			</Link>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.turnOff}
				type={'error'}
				title={'Error'}
				body={error}
			/>
		</>
	)
}

export const steps = [
	{
		title: 'Verify Your Phone',
		description:
			'By verifying your phone number, you’re helping us build a trusted and secure community.',
		Input: props => (
			<FormikFieldPhoneInput
				name="phone"
				hasCountryCode
				{...props}
				showSuccessMessage
			/>
		),
		initialValues: {
			phone: {
				number: '',
				international: '',
				country: 'US'
			}
		},
		validationSchema: yup.object().shape({
			phone: yup.mixed().phone()
		}),
		handlerValues: (values = {}, dispatch = () => null) => {
			return dispatch(sendVerifyCodeActions(values))
		},
		secondaryActions: <div />
	},
	{
		title: 'Enter Your Code',
		description: <EnterCodeDescription />,
		Input: props => (
			<FormikFieldCodeInput
				timeTillDate={useSelector(getVerifyExpire)}
				name="verify_code"
				className={classes.codeInput}
				{...props}
			/>
		),
		initialValues: {
			verify_code: ''
		},
		validationSchema: yup.object().shape({
			verify_code: yup
				.string()
				.min(4, 'Code must be at least 4 characters')
				.required('Code is required')
		}),
		handlerValues: (values = {}, dispatch = () => null) =>
			dispatch(signUpActions(values)),
		secondaryActions: <ResendCode />
	}
]
