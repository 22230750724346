import React from 'react'
import classes from './ReceivedConversation.module.scss'
import ConversationMobile from 'modules/conversation/ConversationMobile.js'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, primaryColor } from 'constants/colors.js'
import ReceivedConversationContainer from 'modules/notifications/requests/receivedConversation/ReceivedConversationContainer.js'

const ReceivedConversationMobile = () => {
	return (
		<ReceivedConversationContainer>
			{({ onClickLike = () => null, userId, onClickPass = () => null }) => (
				<div className={classes.root}>
					<ConversationMobile hideInput title="Requests" />
					<>
						<Button
							size="xl"
							shape="rounded"
							color="white"
							className={clsx(classes.btn, classes.likeBtn)}
							onClick={onClickLike}
							data-id={userId}
						>
							<SpriteIcon
								name="like"
								className={classes.icon}
								color={primaryColor}
							/>
						</Button>
						<Button
							size="xl"
							shape="rounded"
							color="white"
							className={clsx(classes.btn, classes.passBtn)}
							data-id={userId}
							onClick={onClickPass}
						>
							<SpriteIcon
								name="close"
								className={classes.icon}
								color={black3}
							/>
						</Button>
					</>
				</div>
			)}
		</ReceivedConversationContainer>
	)
}

export default ReceivedConversationMobile
