import React from 'react'
import LastMatchCard from 'modules/notifications/matches/lastMatch/components/LastMatchCard'
import classes from './LastMatch.module.scss'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import TextPlaceholder from 'components/ui/layout/TextPlaceholder'

const LastMatchView = ({
	mobileFormat,
	myAvatar,
	matchAvatar,
	onClickSendMessage,
	onClickKeepBrowsing,
	myName,
	matchName,
	lastMatch,
	upgradeNotification
}) => {
	return (
		<div className={classes.root}>
			{lastMatch ? (
				<>
					{mobileFormat ? <NavigationHeader withoutBack title="Match" /> : null}

					<div className={classes.content}>
						<LastMatchCard
							myAvatar={myAvatar}
							matchAvatar={matchAvatar}
							matchName={matchName}
							myName={myName}
							onClickKeepBrowsing={onClickKeepBrowsing}
							onClickSendMessage={onClickSendMessage}
						/>
					</div>
				</>
			) : (
				<TextPlaceholder
					text={
						<p className={classes.infoText}>
							You don't have a match yet. Try these tips:
							<br />
							*Expand your filters
							<br />
							*Like others' profiles
							<br />
							*Use the 'request to chat' feature to stand out
						</p>
					}
				/>
			)}
			{upgradeNotification.unlockFeatures.component}
		</div>
	)
}

export default LastMatchView
