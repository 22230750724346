import { useCallback, useEffect, useMemo, useState } from 'react'
import { activateSubscription } from 'modules/subscriptionBilling/services'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import useFlagManager from 'hooks/useFlagManager'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLoader } from 'modules/app/store/actions'
import { getMeAction } from 'modules/profile/store/actions'
import {
	getCurrentPlan,
	getPaymentsInfo
} from 'modules/subscriptionBilling/store/selectors'
import _useAsyncDidMount from '../../../hooks/_useAsyncDidMount'

const SubscribeContainer = ({ children, promoCode }) => {
	const successDialog = useFlagManager(false)
	const paymentsInfo = useSelector(getPaymentsInfo)
	const history = useHistory()
	const dispatch = useDispatch()
	const currentPlan = useSelector(getCurrentPlan)
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')
	const [subscriptionStatus, setSubscriptionStatus] = useState(null)

	_useAsyncDidMount(async () => {
		dispatch(toggleLoader())
		dispatch(getMeAction()).then(user => {
			setSubscriptionStatus(user?.subscription?.status)
		})
	})

	useEffect(() => {
		if (subscriptionStatus !== null && subscriptionStatus !== undefined) {
			dispatch(toggleLoader())
		}
	}, [subscriptionStatus, dispatch])

	const handleSubmitPaymentForm = useCallback(async () => {
		try {
			dispatch(toggleLoader())
			await activateSubscription({
				plan_id: currentPlan?.id,
				promo_code: promoCode.value
			})
			promoCode.resetCode()
			promoCode.resetCodeForPlan()
			successDialog.turnIn()
		} catch (e) {
			setError(e.generalError)
			infoDialog.turnIn()
		} finally {
			dispatch(toggleLoader())
		}
	}, [dispatch, currentPlan.id, promoCode, successDialog, infoDialog])

	const handleCloseSuccessDialog = useCallback(() => {
		history.push(routesByName.main.discovery.root)
		dispatch(getMeAction())
	}, [dispatch, history])

	const handleClickEditCard = useCallback(
		event => {
			const id = event.currentTarget.getAttribute('data-id')

			history.push(routesByName.main.settings.billing.editPaymentMethod, {
				pm: id
			})
		},
		[history]
	)

	const props = useMemo(
		() => ({
			currentPlan,
			promoCode,
			paymentsInfo,
			onSubmitPaymentForm: handleSubmitPaymentForm,
			isOpenSuccessDialog: successDialog.state,
			onCloseSuccessDialog: handleCloseSuccessDialog,
			onClickEdit: handleClickEditCard,
			infoDialog: {
				state: infoDialog.state,
				close: infoDialog.turnOff,
				error: error
			}
		}),
		[
			currentPlan,
			handleCloseSuccessDialog,
			handleSubmitPaymentForm,
			successDialog.state,
			promoCode,
			paymentsInfo,
			handleClickEditCard,
			infoDialog.state,
			infoDialog.turnOff,
			error
		]
	)

	return children(props)
}

export default SubscribeContainer
