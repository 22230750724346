import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	createConversation,
	sendMessage
} from 'modules/conversation/store/actions.js'
import messengerApi from 'libs/messenger'
import { getUserForCreateConversation } from 'modules/conversation/store/selectors.js'
import useCallbackRef from 'hooks/useCallbackRef.js'
import { setShowNavigationBar } from 'modules/app/store/actions.js'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import { screenFormat } from 'constants/screenFormat.js'
import _useWillUnmount from 'hooks/_useWillUnmount.js'
import useOutsideClickRef from '@rooks/use-outside-click-ref'
import useFlagManager from 'hooks/useFlagManager'

const CreateConversationContainer = ({
	children,
	onPushMessage = () => null
}) => {
	const userForCreateConversation = useSelector(getUserForCreateConversation)
	const [inputNode, inputRef] = useCallbackRef()
	const [message, setMessage] = useState('')
	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile
	const intUserId = useMemo(() => Number(userForCreateConversation), [
		userForCreateConversation
	])
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const dispatch = useDispatch()

	const myId = useSelector(({ profile }) => profile.user.id)

	const pushMessage = useCallback(() => {
		try {
			setMessage(message => {
				if (message) {
					;(async () => {
						const conversation = await messengerApi.conversations.create({
							type: 'private',
							participants: [intUserId]
						})

						if (conversation?.id) {
							await dispatch(createConversation(conversation))
							dispatch(
								sendMessage(conversation.id, {
									type: 1,
									version: 0,
									// body: message?.replaceAll(`\n`, '<br/>')
									body: message?.replace(/\n/g, '<br/>')
								})
							)

							onPushMessage(conversation, intUserId)
						}
					})()
				}
				return ''
			})
		} catch (e) {
			console.dir(e)
			setError(e.getException)
			infoDialog.turnIn()
		}
	}, [dispatch, infoDialog, intUserId, onPushMessage])

	const handleChangeMessage = useCallback(({ target: { value } }) => {
		setMessage(value)
	}, [])

	const handleClickInput = useCallback(
		event => {
			if (isMobile) {
				dispatch(setShowNavigationBar(false))
			}
		},
		[dispatch, isMobile]
	)

	const handleOutsideClick = useCallback(() => {
		if (isMobile) {
			dispatch(setShowNavigationBar(true))
		}
	}, [dispatch, isMobile])

	const [inputContainerRef] = useOutsideClickRef(handleOutsideClick)

	const handleClickPushBtn = useCallback(() => {
		pushMessage()
	}, [pushMessage])

	useEffect(() => {
		if (inputNode) {
			inputNode.addEventListener('keydown', e => {
				const keyCode = e.which || e.keyCode

				if (keyCode === 13 && !e.shiftKey) {
					e.preventDefault()
					pushMessage()
				}
			})
		}
		//eslint-disable-next-line
	}, [inputNode])

	_useWillUnmount(() => {
		dispatch(setShowNavigationBar(true))
	})

	const props = useMemo(
		() => ({
			myId: myId,
			value: message,
			onChange: handleChangeMessage,
			onPush: pushMessage,
			onClickInput: handleClickInput,
			onClickPushBtn: handleClickPushBtn,
			inputRef,
			inputContainerRef,
			infoDialog: {
				state: infoDialog.state,
				close: infoDialog.turnOff,
				error: error
			}
		}),
		[
			myId,
			message,
			handleChangeMessage,
			pushMessage,
			handleClickInput,
			handleClickPushBtn,
			inputRef,
			inputContainerRef,
			infoDialog.state,
			infoDialog.turnOff,
			error
		]
	)

	return children(props)
}

export default CreateConversationContainer
