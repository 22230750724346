import React from 'react'
import classes from './Matches.module.scss'
import MatchesBar from './components/MatchesBar'
import ConversationList from './components/ConversationList'
import NotificationPlaceholder from 'modules/notifications/components/NotificationPlaceholder'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import CustomInfiniteScroll from 'components/wrappers/ui/CustomInfiniteScroll'

const placeholder =
	"You don't have any chats yet. Make the first move & say hello!"
const conversationListClasses = {
	root: classes.conversationList
}

const MatchesView = ({
	onClickClose: handleClickClose,
	onClickMatchPerson: handleClickMatchPerson,
	onClickConversation: handleClickConversation,
	matchList = [],
	conversationList = [],
	loadMoreConversations,
	loadMoreMatches,
	isLoadingNewConversations,
	hasMoreConversations,
	hasMoreMatches,
	upgradeNotification
}) => {
	return (
		<div className={classes.root}>
			{!matchList.length && !conversationList.length ? (
				<NotificationPlaceholder text={placeholder} />
			) : (
				<BaseScrollbars>
					<CustomInfiniteScroll
						hasMore={hasMoreConversations}
						loadMore={loadMoreConversations}
						isLoading={isLoadingNewConversations}
					>
						<div className={classes.content}>
							{matchList.length ? (
								<MatchesBar
									list={matchList}
									onClickMatchPerson={handleClickMatchPerson}
									loadMoreMatches={loadMoreMatches}
									hasMore={hasMoreMatches}
								/>
							) : null}
							<ConversationList
								list={conversationList}
								onClickConversation={handleClickConversation}
								classes={conversationListClasses}
							/>
						</div>
					</CustomInfiniteScroll>
				</BaseScrollbars>
			)}
			{upgradeNotification.unlockFeatures.component}
		</div>
	)
}

export default MatchesView
