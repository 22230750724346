import React from 'react'

import LastMatchContainer from './LastMatchContainer.js'
import LastMatchView from './LastMatchView.js'

const LastMatchPage = props => {
	return (
		<LastMatchContainer>
			{containerProps => <LastMatchView {...containerProps} {...props} />}
		</LastMatchContainer>
	)
}

export default LastMatchPage
