import { useSelector } from 'react-redux'
import { routesByName } from 'constants/routes'
import {
	getCurrentConversation,
	getUserForCreateConversation
} from 'modules/conversation/store/selectors.js'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import {
	getFinishedCreatingProfile,
	getPopupStatus
} from 'modules/profile/store/selectors'
import { getMateIdByConversationId } from 'modules/conversation/store/selectors'
import { getUserById } from 'modules/users/selectors'
import _useAsyncDidMount from 'hooks/_useAsyncDidMount'
import useFillEmptyValues from 'hooks/useFillEmptyValues'
import useFlagManager from 'hooks/useFlagManager'

const getContentSlotOnMobile = location => {
	const config = {
		[routesByName.main.notifications.matches.root]: 'left-sidebar',
		[routesByName.main.notifications.likes.received]: 'left-sidebar',
		[routesByName.main.notifications.likes.sent]: 'left-sidebar',
		[routesByName.main.notifications.requests.received]: 'left-sidebar',
		[routesByName.main.notifications.requests.sent]: 'left-sidebar'
	}

	const result = Object.entries(config).find(([key, value]) =>
		key.includes(location.pathname)
	)

	return result?.[1]
}

export const pagesWithProfileMate = [
	routesByName.main.notifications.matches.conversationCreate,
	routesByName.main.notifications.matches.conversation,
	routesByName.main.notifications.requests.sent,
	routesByName.main.discovery.conversation,
	routesByName.main.discovery.sendMessage,
	routesByName.main.notifications.requests.receivedConversation,
	routesByName.main.lastMatch.conversationCreate,
	routesByName.main.lastMatch.conversation
]

const MainContainer = ({ children }) => {
	const finishedCreatingProfile = useSelector(getFinishedCreatingProfile)
	const conversationId = useSelector(getCurrentConversation)
	const userForCreateConversation = useSelector(getUserForCreateConversation)
	const mateId = useSelector(getMateIdByConversationId(conversationId))
	const mate = useSelector(getUserById(mateId || userForCreateConversation))
	const popup_status = useSelector(getPopupStatus)
	const promoDialog = useFlagManager()

	const location = useLocation()

	const contentSlotOnMobile = useMemo(() => getContentSlotOnMobile(location), [
		location
	])

	const withRightSidebar = useMemo(
		() =>
			pagesWithProfileMate.includes(location.pathname) &&
			(conversationId || userForCreateConversation),
		[conversationId, location.pathname, userForCreateConversation]
	)

	_useAsyncDidMount(async () => {
		if (popup_status) {
			promoDialog.turnIn()
		}
	})

	useFillEmptyValues()

	const withMateName = useMemo(
		() =>
			(location.pathname ===
				routesByName.main.notifications.matches.conversation ||
				location.pathname ===
					routesByName.main.notifications.matches.conversationCreate) &&
			(conversationId || userForCreateConversation),
		[location.pathname, conversationId, userForCreateConversation]
	)

	const props = useMemo(
		() => ({
			finishedCreatingProfile,
			withRightSidebar,
			contentSlotOnMobile,
			mate,
			withMateName,
			popup_status,
			promoDialog
		}),
		[
			contentSlotOnMobile,
			finishedCreatingProfile,
			withRightSidebar,
			mate,
			withMateName,
			popup_status,
			promoDialog
		]
	)

	return children(props)
}

export default MainContainer
