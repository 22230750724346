import React, { memo } from 'react'
import classes from './AboutMe.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import Typography from 'components/ui/Typography'
import AnswerCard from 'modules/profile/components/AboutMe/AnswerCard'
import BaseTooltip from 'components/wrappers/ui/BaseTooltip'

const AboutMe = ({ onClickEdit = () => null, options = [], tooltip }) => {
	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.tooltip}>
					<Typography className={classes.title}>About Me</Typography>
					{tooltip && (
						<BaseTooltip
							tooltip={tooltip}
							classes={{ tooltipIcon: classes.tooltipIcon }}
							isBold
						/>
					)}
				</div>
				<SpriteIcon
					name="edit"
					className={classes.editIcon}
					onClick={onClickEdit}
				/>
			</div>

			<div className={classes.answers}>
				{options?.map(({ question, answer }, index) => (
					<AnswerCard
						key={question + answer + index}
						answer={answer}
						question={question}
					/>
				))}
			</div>
		</div>
	)
}

export default memo(AboutMe)
