import React from 'react'
import LikesContainer from 'modules/notifications/likes/LikesContainer'
import LikesView, { received } from 'modules/notifications/likes/LikesView'

const ReceivedPage = () => {
	return (
		<LikesContainer>
			{props => <LikesView {...props} selectedTab={received} />}
		</LikesContainer>
	)
}

export default ReceivedPage
