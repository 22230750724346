import React, { memo, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classes from './DesktopPreviewProfile.module.scss'
import BaseCard from 'components/ui/controls/BaseCard'
import ColoredChip from 'components/ui/ColoredChip'
import AboutUserCard from 'modules/profile/components/AboutUserCard/AboutUserCard'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import clsx from 'clsx'
import Button from 'components/ui/Button'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, commonWhite, primaryColor } from 'constants/colors'
import { _throttle } from 'utils/lodash'
import Typography from 'components/ui/Typography'
import { blobToSrc } from 'utils/blobToSrc'
import BaseImage from 'components/wrappers/ui/BaseImage'
import MenuElement from 'components/ui/MenuElement/MenuElement'
import FeedbackDialog from 'components/ui/Dialogs/FeedbackDialog'
import useUpgradeNotification from 'hooks/useUpgradeNotification'
import { routesByName } from '../../../../constants/routes'

const cardClasses = {
	root: classes.characteristicCard,
	label: classes.characteristicCardLabel,
	icon: classes.characteristicCardIcon
}
const aboutUserCardClasses = { root: 'mt-1 mb-1' }

// const horoscope = 11
const familyPlans = 8
const growth = 6
const education = 4
const languages = 7
const religion = 3
const career = 5
const location = 2
const kids = 2
const drink = 1
const age = 0
const smoke = 0
const avatar = 0

const preferNotToSay = 'Prefer not to say'

const DesktopPreviewProfile = ({
	className,
	personalities = [],
	userCharacteristics = [],
	restUserCharacteristics = [],
	answersAndQuestions = [],
	firstName,
	photos = [],
	onClickUndo,
	onClickPass,
	onClickLike,
	onClickConfirmReport,
	onClickSendMessage,
	reportModal = {},
	userId,
	scrollRef
}) => {
	const [isTopPosition, setTopPosition] = useState(true)
	const { isSubscribe } = useUpgradeNotification()
	const { pathname } = useLocation()

	const handleClickExpand = useCallback(() => {
		scrollRef.current.view.scroll({
			top: isTopPosition ? 400 : 0,
			left: 0,
			behavior: 'smooth'
		})
	}, [isTopPosition, scrollRef])

	const handleScroll = useCallback(
		_throttle(({ top }) => {
			if (top > 0) {
				setTopPosition(false)
			} else setTopPosition(true)
		}, 200),
		[]
	)

	const avatarSrc = useMemo(
		() =>
			photos[avatar] instanceof Blob
				? blobToSrc(photos[avatar])
				: photos[avatar],
		[photos]
	)

	return (
		<div className={clsx(classes.root, className)}>
			<BaseScrollbars hide innerRef={scrollRef} onScrollFrame={handleScroll}>
				<div className={classes.userPicWrapper}>
					<BaseImage src={avatarSrc} classes={{ root: classes.userPic }} />
					<div className={classes.userPicDesc}>
						<Typography className={clsx(classes.text, classes.name)}>
							{firstName}, {restUserCharacteristics[age]?.value}
						</Typography>
						<Typography className={clsx(classes.text)}>
							{restUserCharacteristics[career]?.value} •{' '}
							{restUserCharacteristics[location]?.value}
						</Typography>
					</div>
				</div>

				<div className={classes.characteristicContainer}>
					{restUserCharacteristics[growth]?.value &&
					restUserCharacteristics[growth]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[growth]?.icon}
							label={restUserCharacteristics[growth]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
					{restUserCharacteristics[education]?.value &&
					restUserCharacteristics[education]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[education]?.icon}
							label={restUserCharacteristics[education]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}

					{restUserCharacteristics[languages]?.value &&
					restUserCharacteristics[languages]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[languages]?.icon}
							label={restUserCharacteristics[languages]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
					{/*{restUserCharacteristics[horoscope]?.value &&*/}
					{/*restUserCharacteristics[horoscope]?.value !== preferNotToSay ? (*/}
					{/*	<MenuElement*/}
					{/*		icon={restUserCharacteristics[horoscope]?.icon}*/}
					{/*		label={restUserCharacteristics[horoscope]?.value}*/}
					{/*		classes={cardClasses}*/}
					{/*		justify="center"*/}
					{/*	/>*/}
					{/*) : null}*/}
					{restUserCharacteristics[religion]?.value &&
					restUserCharacteristics[religion]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[religion]?.icon}
							label={restUserCharacteristics[religion]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
				</div>
				{personalities?.length ? (
					<BaseCard className={classes.personalitiesContainer}>
						{personalities?.map(({ label }, index) => (
							<ColoredChip
								key={`colored-chip-${index}`}
								label={label}
								className={classes.coloredChip}
							/>
						))}
					</BaseCard>
				) : null}
				<div className={classes.characteristicContainer}>
					{userCharacteristics[smoke]?.value &&
					userCharacteristics[smoke]?.value !== preferNotToSay ? (
						<MenuElement
							icon={userCharacteristics[smoke]?.icon}
							label={userCharacteristics[smoke]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
					{userCharacteristics[drink]?.value &&
					userCharacteristics[drink]?.value !== preferNotToSay ? (
						<MenuElement
							icon={userCharacteristics[drink]?.icon}
							label={userCharacteristics[drink]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}

					{userCharacteristics[kids]?.value &&
					userCharacteristics[kids]?.value !== preferNotToSay ? (
						<MenuElement
							icon={userCharacteristics[kids]?.icon}
							label={userCharacteristics[kids]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
					{restUserCharacteristics[familyPlans]?.value &&
					restUserCharacteristics[familyPlans]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[familyPlans]?.icon}
							label={restUserCharacteristics[familyPlans]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
				</div>
				{answersAndQuestions?.length
					? answersAndQuestions?.map(({ answer, question, avatar }, index) => (
							<AboutUserCard
								classes={aboutUserCardClasses}
								key={question + answer + index}
								question={question}
								answer={answer}
								avatar={avatar}
							/>
					  ))
					: null}
			</BaseScrollbars>
			<div className={classes.actionPanel}>
				<Button
					shape="rounded"
					color="white"
					size="sm"
					className={classes.smBtn}
					onClick={onClickUndo}
					disabled={!isSubscribe || !onClickUndo}
				>
					<SpriteIcon name="reply" className={classes.smIcon} />
				</Button>

				<Button
					size="xl"
					shape="rounded"
					color="white"
					className={clsx(classes.xlBtn, classes.skipBtn)}
					data-id={userId}
					onClick={onClickPass}
				>
					<SpriteIcon name="close" className={classes.xlIcon} color={black3} />
				</Button>

				{pathname !== routesByName.main.notifications.likes.sent && (
					<Button
						size="xl"
						shape="rounded"
						color="white"
						className={clsx(classes.xlBtn, classes.likeBtn)}
						onClick={onClickLike}
						data-id={userId}
					>
						<SpriteIcon
							name="like"
							className={classes.xlIcon}
							color={primaryColor}
						/>
					</Button>
				)}

				<Button
					size="sm"
					shape="rounded"
					color="white"
					className={classes.smBtn}
					onClick={onClickSendMessage}
					data-id={userId}
					disabled={!isSubscribe || !onClickSendMessage}
				>
					<SpriteIcon name="message-contained" className={classes.smIcon} />
				</Button>

				<Button
					size="md"
					shape="rounded"
					color="secondary"
					className={classes.mdBtn}
					onClick={handleClickExpand}
				>
					<SpriteIcon
						name="arrow-down"
						color={commonWhite}
						className={clsx(classes.icon, {
							[classes.topArrow]: !isTopPosition
						})}
					/>
				</Button>
			</div>
			<FeedbackDialog
				open={reportModal?.state}
				onClose={reportModal?.turnOff}
				onClick={onClickConfirmReport}
			/>
		</div>
	)
}

export default memo(DesktopPreviewProfile)
