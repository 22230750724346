import React, { useMemo } from 'react'
import classes from './Requests.module.scss'
import NotificationPlaceholder from 'modules/notifications/components/NotificationPlaceholder'
import BaseTabs from 'components/ui/controls/BaseTabs/BaseTabs'
import SentItem from 'modules/notifications/requests/components/SentItem'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import ConversationItem from 'modules/notifications/matches/components/ConversationList/ConversationItem/ConversationItem'
import TextPlaceholder from 'components/ui/layout/TextPlaceholder'
import { ReactComponent as MatchesIcon } from 'assets/staticIcons/matches-icon.svg'
import clsx from 'clsx'

const placeholder = 'New Requests To Match Will Appear Here'

const noReceivedRequestsText = (
	<div>
		<MatchesIcon className="mb-4" />
		<br />
		<p className={clsx(classes.placeholderTitle, 'body-1')}>
			No Requests Received
		</p>
		<br />
		<p className={classes.placeholderText}>
			Requests are personalized messages members can send each other to stand
			out before matching.
		</p>
	</div>
)

const noSentRequestsText = (
	<div>
		<MatchesIcon className="mb-4" />
		<br />
		<p className={clsx(classes.placeholderTitle, 'body-1')}>No Requests Sent</p>
		<br />
		<p className={classes.placeholderText}>
			Requests are personalized messages members can send each other to stand
			out before matching.
		</p>
	</div>
)

export const received = 'received'
export const sent = 'sent'

const tabs = [
	{
		value: received,
		label: 'Received'
	},
	{
		value: sent,
		label: 'Sent'
	}
]

const RequestsView = ({
	receivedRequests = [],
	sentRequests = [],
	selectedTab,
	onClickReceivedMessage,
	onClickSentMessage,
	onChangeTab,
	upgradeNotification
}) => {
	const renderSentItems = useMemo(
		() =>
			sentRequests.length ? (
				sentRequests?.map(item => (
					<SentItem
						key={item.id}
						onClick={onClickSentMessage}
						smooth={!upgradeNotification.isSubscribe}
						{...item}
					/>
				))
			) : (
				<TextPlaceholder text={noSentRequestsText} />
			),
		[onClickSentMessage, sentRequests, upgradeNotification.isSubscribe]
	)

	const renderReceivedItems = useMemo(
		() =>
			receivedRequests.length ? (
				receivedRequests?.map(item => (
					<ConversationItem
						key={item.id}
						onClick={onClickReceivedMessage}
						smooth={!upgradeNotification.isSubscribe}
						{...item}
					/>
				))
			) : (
				<TextPlaceholder text={noReceivedRequestsText} />
			),
		[onClickReceivedMessage, receivedRequests, upgradeNotification.isSubscribe]
	)

	return (
		<div className={classes.root}>
			{!receivedRequests.length && !sentRequests.length ? (
				<NotificationPlaceholder text={placeholder} />
			) : (
				<div className={classes.content}>
					<div className={classes.header}>
						<BaseTabs
							variant="secondary"
							tabs={tabs}
							value={selectedTab}
							onChange={onChangeTab}
						/>
					</div>
					<BaseScrollbars>
						{upgradeNotification.textBanner}
						{
							<div className={classes.items}>
								{selectedTab === sent ? renderSentItems : renderReceivedItems}
							</div>
						}
					</BaseScrollbars>
				</div>
			)}
			{upgradeNotification.unlockFeatures.component}
		</div>
	)
}

export default RequestsView
