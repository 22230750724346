import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFlagManager from 'hooks/useFlagManager.js'
import { getMeAction, updateMe } from 'modules/profile/store/actions.js'
import { configFields } from 'modules/profile/store/constants.js'
import moment from 'moment'
import {
	birthday,
	growth,
	languages,
	// wantGender,
	location,
	smoke,
	drink,
	kid,
	gender,
	religion,
	job,
	study,
	answers,
	personalities as personalitiesField
} from 'modules/profile/createProfile/config.js'
import { editorConfig } from 'modules/profile/components/EditorDialog/config.js'
import { getMe, getProfileConfig } from 'modules/profile/store/selectors'
import _useEffect from 'hooks/_useEffect'
import { deleteMedia, postMedias } from 'modules/profile/services'
import { pushMedia, updateMedia } from 'modules/profile/store/actions'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'
import { prepareNextApplicant } from '../discovery/store/actions'
import { useLocation } from 'react-router-dom'
import { weMet } from 'modules/notifications/matches/services'
import successToastr from 'libs/toastr/successToastr'
import {
	closeDialogManager,
	setDialogManagerState
} from 'modules/dialogManager/store/actions'
import { blockUser, reportUser, unmatchUser } from 'modules/users/services'
import { familyPlans } from 'modules/profile/createProfile/config'
import { toggleLoader } from 'modules/app/store/actions'
import { unblockUser } from '../users/services'
// import { removeMatchById } from 'modules/notifications/matches/store/actions'
// import { removeConversationByParticipantId } from 'modules/conversation/store/actions'
// import { getSign } from 'horoscope'

const defaultValue = 1

const ProfileContainer = ({ children, user = null }) => {
	const [currentEditorElement, setCurrentEditorElement] = useState(location)
	const [photos, setPhotos] = useState([])

	const reportModal = useFlagManager(false)
	const unmatchModal = useFlagManager(false)

	const selfUser = useSelector(getMe)
	const config = useSelector(getProfileConfig)
	const dispatch = useDispatch()
	const history = useHistory()
	const { pathname } = useLocation()
	// const userHoroscope = getSign({
	// 	month: moment(selfUser.birthday).month() + 1,
	// 	day: moment(selfUser.birthday).date()
	// })

	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const handleChangeTab = useCallback(
		value => {
			if (value === 'Edit') {
				history.push(routesByName.main.profile.edit)
			} else {
				history.push(routesByName.main.profile.preview)
			}
		},
		[history]
	)

	const selectedTab = useMemo(
		() => (pathname === routesByName.main.profile.edit ? 'Edit' : 'Preview'),
		[pathname]
	)

	const editDialog = useFlagManager(false)

	const currentUser = useMemo(() => user || selfUser, [selfUser, user])

	const photosFromReducer = useMemo(
		() => currentUser?.photos?.sort((a, b) => a.type?.localeCompare(b?.type)),
		[currentUser]
	)

	_useEffect(() => {
		setPhotos(photosFromReducer?.map(photo => photo?.large))
	}, [currentUser])

	const handleSubmit = useCallback(
		async values => {
			try {
				dispatch(toggleLoader())
				await editorConfig[currentEditorElement].service(values)
				await dispatch(updateMe(values))
				dispatch(getMeAction())

				editDialog.turnOff()
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			} finally {
				dispatch(toggleLoader())
			}
		},
		[currentEditorElement, dispatch, editDialog, infoDialog]
	)

	const handleClickEditAboutMe = useCallback(() => {
		setCurrentEditorElement(answers)

		editDialog.turnIn()
	}, [editDialog])

	const handleClickUpdatePersonality = useCallback(() => {
		setCurrentEditorElement(personalitiesField)

		editDialog.turnIn()
	}, [editDialog])

	const handleClickUserCharacteristic = useCallback(
		name => {
			setCurrentEditorElement(name)

			editDialog.turnIn()
		},
		[editDialog]
	)

	const handleClickMenuBar = useCallback(
		name => {
			switch (name) {
				case 'info':
					history.push(routesByName.main.helpPage)
					break
				case 'preferences':
					history.push(routesByName.main.discovery.preference)
					break
				case 'settings':
					history.push(routesByName.main.settings.root)
					break
				default:
					history.push(routesByName.root)
			}
		},
		[history]
	)

	const handleChangePhoto = useCallback(
		async ({ target: { value: newPhoto, name, index } }) => {
			try {
				const type = index === 0 ? 'avatar' : 'photo'

				if (photosFromReducer[index]) {
					const [, [{ result }]] = await Promise.all([
						deleteMedia(photosFromReducer[index].id),
						postMedias([newPhoto], type)
					])
					// setUserPendingStatus().catch(console.dir)
					dispatch(updateMedia(photosFromReducer[index].id, result))
				} else {
					const [result] = await postMedias([newPhoto], type)
					const { result: photo } = result

					dispatch(pushMedia(photo))
				}
			} catch (e) {
				console.dir(e)
			}
		},
		[dispatch, photosFromReducer]
	)

	const userCharacteristics = useMemo(
		() => [
			{
				label: 'Do you smoke?',
				icon: 'smoke',
				value: config[configFields.smoke].original[currentUser.smokeId],
				name: smoke
			},
			{
				label: 'Do you drink?',
				icon: 'drink',
				value: config[configFields.drink].original[currentUser.drinkId],
				name: drink
			},
			{
				label: 'What about kids?',
				icon: 'kids',
				value: config[configFields.haveKids].original[currentUser[kid]],
				name: kid
			}
		],
		[config, currentUser]
	)

	const restUserCharacteristics = useMemo(
		() => [
			{
				label: 'Age',
				icon: 'calendar',
				value: `${moment().diff(moment(currentUser.birthday), 'years')} years`,
				name: birthday
			},
			{
				label: 'Gender',
				icon: 'gender',
				value:
					config[configFields.genders].original[
						currentUser.genderId || defaultValue
					],
				name: gender
			},
			{
				label: 'Location',
				icon: 'map-mark',
				value: currentUser[location],
				name: location
			},
			{
				label: 'Religion',
				icon: 'religion',
				value:
					config[configFields.religions].original[currentUser.religionId] ||
					currentUser?.oldInformation?.religion,
				name: religion
			},
			{
				label: 'Education',
				icon: 'education',
				value:
					config[configFields.educations].original[currentUser.studyId] ||
					currentUser?.oldInformation?.education,
				name: study
			},
			{
				label: 'Career',
				icon: 'job',
				value:
					config[configFields.jobs].original[currentUser.jobId] ||
					currentUser?.oldInformation?.profession,
				name: job
			},
			{
				label: 'Height',
				icon: 'tall',
				value:
					config[configFields.growth].original[currentUser.growthId] ||
					currentUser?.oldInformation?.height,
				name: growth
			},
			{
				label: 'Languages',
				icon: 'translate',
				value: currentUser.languages
					?.map(language => config[configFields.languages].original[language])
					.join(', '),
				name: languages
			},
			// {
			// 	label: 'Preference',
			// 	icon: 'gender',
			// 	value: `Looking for ${
			// 		config[configFields.wantGenders].original[currentUser.wantGenderId]
			// 	}`,
			// 	name: wantGender
			// },
			{
				label: 'Family plans',
				icon: 'family-plans',
				value:
					config[configFields.familyPlans].original[currentUser[familyPlans]],
				name: familyPlans
			}
			// {
			// 	label: userHoroscope,
			// 	icon: userHoroscope,
			// 	value: userHoroscope,
			// 	name: horoscope
			// }
		],
		[config, currentUser]
	)

	const personalities = useMemo(
		() =>
			currentUser.personalities?.map(id => ({
				label: config[configFields.personalities].original[id]
			})),
		[config, currentUser.personalities]
	)

	const answersAndQuestions = useMemo(
		() =>
			Object.entries(currentUser.answers || {})?.map(
				([question, answer], index) => ({
					answer,
					avatar: photos?.[index + 1],
					question: config[configFields.questions]?.original?.[question]
				})
			),
		[config, currentUser.answers, photos]
	)

	const handleClickBlock = useCallback(async () => {
		const onClose = () => dispatch(closeDialogManager())

		const onConfirm = async () => {
			try {
				await blockUser(currentUser.id)

				if (pathname === routesByName.main.discovery.root) {
					dispatch(prepareNextApplicant())
				} else {
					history.push(routesByName.main.notifications.matches.root)
				}

				onClose()
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			}
		}

		dispatch(
			setDialogManagerState({
				open: true,
				title: 'Block user?',
				body:
					"Blocked members will no longer be able to message you and won't show up when you're browsing.",
				onClose,
				onConfirm,
				confirmBtn: { title: 'Block' },
				cancelBtn: { title: 'Cancel' },
				withConfirmBtn: true,
				withCancelBtn: true
			})
		)
	}, [dispatch, currentUser.id, pathname, history, infoDialog])

	const handleClickUnblock = useCallback(async () => {
		try {
			dispatch(toggleLoader())
			await unblockUser(currentUser.id)
			history.push(routesByName.main.notifications.matches.root)
		} catch (e) {
			setError(e.generalError)
			infoDialog.turnIn()
		} finally {
			dispatch(toggleLoader())
		}
	}, [currentUser.id, dispatch, history, infoDialog])

	const handleClickReport = useCallback(() => {
		const onClose = () => dispatch(closeDialogManager())

		const onConfirm = async () => {
			try {
				reportModal.turnIn()
				onClose()
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			}
		}

		dispatch(
			setDialogManagerState({
				open: true,
				title: 'Report user?',
				body:
					"Continue with reporting this person for violating MTD's community guidelines?",
				onClose,
				onConfirm,
				confirmBtn: { title: 'Yes' },
				cancelBtn: { title: 'No' },
				withConfirmBtn: true,
				withCancelBtn: true
			})
		)
	}, [dispatch, infoDialog, reportModal])

	const handleClickConfirmReport = useCallback(
		async event => {
			try {
				const reason = event.currentTarget.getAttribute('data-reason')
				reportModal.turnOff()
				await reportUser(currentUser.id, reason)

				//TODO Remove
				dispatch(prepareNextApplicant())
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			}
		},
		[currentUser.id, dispatch, infoDialog, reportModal]
	)

	const handleClickUnmatch = useCallback(() => {
		const onClose = () => dispatch(closeDialogManager())

		const onConfirm = async () => {
			try {
				unmatchModal.turnIn()
				onClose()
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			}
		}

		dispatch(
			setDialogManagerState({
				open: true,
				title: 'Unmatch user?',
				body: 'Continue with unmatching this member?',
				onClose,
				onConfirm,
				confirmBtn: { title: 'Yes' },
				cancelBtn: { title: 'No' },
				withConfirmBtn: true
			})
		)
	}, [dispatch, infoDialog, unmatchModal])

	const handleClickConfirmUnmatch = useCallback(
		async event => {
			try {
				const reasonId = Number.parseInt(
					event.currentTarget.getAttribute('data-reason')
				)
				unmatchModal.turnOff()
				await unmatchUser(currentUser.id, reasonId)

				history.replace(routesByName.main.notifications.matches.root)
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			}
		},
		[unmatchModal, currentUser.id, history, infoDialog]
	)

	const handleClickWeMeet = useCallback(async () => {
		try {
			await weMet(currentUser.id)

			successToastr('Success')
		} catch (e) {
			setError('You have already clicked we met')
			infoDialog.turnIn()
		}
	}, [currentUser.id, infoDialog])

	const formProps = useMemo(
		() => ({
			personalities,
			firstName: currentUser.firstName,
			photos,
			lastName: currentUser.lastName,
			onClickEditAboutMe: handleClickEditAboutMe,
			onClickUpdatePersonality: handleClickUpdatePersonality,
			onClickUserCharacteristic: handleClickUserCharacteristic,
			onClickMenuBar: handleClickMenuBar,
			onChangePhotos: handleChangePhoto,
			userCharacteristics,
			answersAndQuestions,
			restUserCharacteristics
		}),
		[
			answersAndQuestions,
			handleChangePhoto,
			handleClickEditAboutMe,
			handleClickMenuBar,
			handleClickUpdatePersonality,
			handleClickUserCharacteristic,
			personalities,
			restUserCharacteristics,
			currentUser.firstName,
			currentUser.lastName,
			photos,
			userCharacteristics
		]
	)

	const props = useMemo(
		() => ({
			formProps,
			user: currentUser,
			config,
			validationSchema: editorConfig[currentEditorElement].validationSchema,
			onSubmit: handleSubmit,
			CurrentEditorElement: editorConfig[currentEditorElement].Input,
			openEditorDialog: editDialog.state,
			onCloseEditorDialog: editDialog.turnOff,
			headerEditorDialog: editorConfig[currentEditorElement].header,
			nameEditorDialog: currentEditorElement,
			onClickBlock: handleClickBlock,
			onClickUnblock: handleClickUnblock,
			onClickConfirmReport: handleClickConfirmReport,
			selectedTab,
			handleChangeTab,
			reportModal,
			unmatchModal,
			onClickConfirmUnmatch: handleClickConfirmUnmatch,
			onClickWeMeet: handleClickWeMeet,
			onClickReport: handleClickReport,
			onClickUnmatch: handleClickUnmatch,
			userApproval: selfUser.approval,
			isAccountPaused: selfUser.status === 'inactive',
			isCurrentUserBlocked: currentUser?.is_blocked,
			infoDialog: {
				state: infoDialog.state,
				close: infoDialog.turnOff,
				error: error
			}
		}),
		[
			formProps,
			currentUser,
			config,
			currentEditorElement,
			handleSubmit,
			editDialog.state,
			editDialog.turnOff,
			handleClickBlock,
			handleClickUnblock,
			handleClickConfirmReport,
			selectedTab,
			handleChangeTab,
			reportModal,
			handleClickConfirmUnmatch,
			handleClickWeMeet,
			unmatchModal,
			handleClickReport,
			handleClickUnmatch,
			selfUser.approval,
			selfUser.status,
			infoDialog,
			error
		]
	)

	return children(props)
}

export default ProfileContainer
