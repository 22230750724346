import React, { memo } from 'react'
import classes from './PaymentCard.module.scss'
import CardBrand from 'modules/subscriptionBilling/billing/components/CardBrand/CardBrand'
import Typography from 'components/ui/Typography'
import SpriteIcon from 'components/ui/SpriteIcon'

const PaymentCard = props => {
	return (
		<div className={classes.root}>
			<CardBrand brand={props.card.brand} />
			<Typography className={classes.card}>**** {props.card.last4}</Typography>
			<SpriteIcon
				name="edit"
				data-id={props.id}
				className={classes.icon}
				onClick={props.onClick}
			/>
		</div>
	)
}

export default memo(PaymentCard)
