import { routesByName } from 'constants/routes.js'
import Homepage from 'pages/Homepage'
import SignUp from 'modules/auth/signUp'
import SignInSwitch from 'modules/auth/signIn/Switch.js'
import mapRouteConfig from 'utils/mapRouteConfig'
import ComingSoon from 'pages/ComingSoon'

const GuestRouteConfig = [
	{
		path: routesByName.home,
		component: Homepage,
		exact: true
	},
	{
		path: routesByName.signUp.root,
		component: SignUp,
		exact: false
	},
	{
		path: routesByName.signIn.root,
		component: SignInSwitch,
		exact: false
	},
	{
		path: routesByName.facebook,
		component: ComingSoon,
		exact: false
	},
	{
		path: routesByName.comingSoon,
		component: ComingSoon,
		exact: false
	}
]

export default mapRouteConfig(GuestRouteConfig)
