import React from 'react'
import SpriteIcon from 'components/ui/SpriteIcon'
import classes from 'modules/settings/Settings.module.scss'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import BaseCard from 'components/ui/controls/BaseCard'
import Typography from 'components/ui/Typography'
import MenuElement from 'components/ui/MenuElement/MenuElement'
import BaseSwitch from 'components/ui/controls/BaseSwitch'
import { routesByName } from 'constants/routes'
// import PushNotificationControl from 'modules/settings/components/PushNotificationControl'
import CancelAutoRenewalDialog from 'components/ui/Dialogs/CancelAutoRenewalDialog/CancelAutoRenewalDialog'
import TermsAndConditions from 'pages/TermsAndConditionsDialog'
import PrivacyPolicy from 'pages/PrivacyPolicyDialog'
import EditorDialog from 'modules/profile/components/EditorDialog'
import { firstName } from 'modules/profile/createProfile/config'
import { editorConfig } from 'modules/profile/components/EditorDialog/config'
import { Formik } from 'formik'
import DeleteAccountDialog from 'components/ui/Dialogs/DeleteAccountDialog'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'
import DeleteReasonsDialog from 'components/ui/Dialogs/DeleteReasonsDialog'
// import PauseAccountDialog from 'components/ui/Dialogs/PauseAccountDialog'

const pauseClasses = {
	root: classes.pauseRoot
}

const SettingsView = ({
	callbacks: {
		onClickEditPhone,
		onClickEmail,
		onClickEditUserName,
		onClickPauseAccount,
		onClickDisableSubscriptions,
		onToggleNotifications,
		onClickDeleteAccount,
		onClickLogout,
		onClickUpgrade,
		onClickTermsAndConditions,
		onClickPrivacyPolicy,
		onClickSubmitUserName,
		onConfirmPauseAccount
	},
	data: {
		phone,
		email,
		isPauseAccount,
		doPushNotifications,
		hasSubscription,
		cancelledSubscription,
		initialValues,
		userStatus
	},
	dialogs: {
		autoRenewalDialog,
		termsAndConditionsDialog,
		privacyPolicyDialog,
		editUserNameDialog,
		deleteAccountDialog,
		deleteReasonsDialog
	},
	infoDialog,
	appVersion
}) => {
	const UserNameInput = editorConfig[firstName].Input
	return (
		<div className={classes.wrapper}>
			<BaseScrollbars>
				<div className={classes.content}>
					{!hasSubscription ? (
						<BaseCard className={classes.upgradeCard} onClick={onClickUpgrade}>
							<div>
								<SpriteIcon name="crown" className={classes.upgradeCardIcon} />
								<Typography className={classes.upgradeCardTitle}>
									Upgrade Account
								</Typography>
							</div>

							<Typography className={classes.upgradeCardDescription}>
								Until you upgrade your profile, you will have limited access and
								reduced exposure.
							</Typography>
						</BaseCard>
					) : null}

					<MenuElement
						label="User name"
						icon="person"
						value={initialValues[firstName]}
						onClick={onClickEditUserName}
					/>

					<MenuElement
						label="Phone number"
						icon="phone"
						value={phone}
						onClick={onClickEditPhone}
					/>

					<MenuElement
						label="Email"
						icon="message-outlined"
						value={email}
						onClick={onClickEmail}
					/>

					<MenuElement
						label="Pause my account"
						icon="block"
						// onClick={onClickPauseAccount}
						classes={pauseClasses}
						value={
							<BaseSwitch
								checked={isPauseAccount}
								onChange={onClickPauseAccount}
							/>
						}
					/>

					<Typography className={classes.delimiter}>Payment</Typography>

					<MenuElement
						label="Payment Method"
						icon="payment-card"
						to={routesByName.main.settings.billing.root}
					/>

					{hasSubscription ? (
						<>
							<MenuElement
								label="Billing History"
								icon="billing-history"
								to={routesByName.main.settings.billing.history}
							/>
							{!cancelledSubscription && (
								<MenuElement
									label="Disable Subscription"
									icon="block"
									onClick={autoRenewalDialog.turnIn}
								/>
							)}
						</>
					) : null}

					{/*<Typography className={classes.delimiter}>Notifications</Typography>*/}

					{/*<PushNotificationControl*/}
					{/*	onChange={onToggleNotifications}*/}
					{/*	value={doPushNotifications}*/}
					{/*/>*/}

					<Typography className={classes.delimiter}>Legal</Typography>

					<MenuElement
						label="Privacy Policy"
						justify="center"
						onClick={onClickPrivacyPolicy}
					/>
					<MenuElement
						label="Terms of Service"
						justify="center"
						onClick={onClickTermsAndConditions}
					/>
					{/*<MenuElement label="Safe Dating Tips" justify="center" />*/}
					<MenuElement
						label="Logout"
						icon="logout"
						justify="center"
						onClick={onClickLogout}
					/>
					<MenuElement
						label="Delete my account"
						onClick={onClickDeleteAccount}
						icon="trash"
						justify="center"
						classes={{ root: classes.deleteBtn }}
					/>
					{appVersion && (
						<div className={classes.appVersion}>v{appVersion}</div>
					)}
				</div>
			</BaseScrollbars>
			<Formik
				initialValues={initialValues}
				validationSchema={editorConfig[firstName].validationSchema}
				onSubmit={onClickSubmitUserName}
			>
				{({ submitForm, resetForm }) => (
					<EditorDialog
						open={editUserNameDialog.state}
						onClose={() => {
							editUserNameDialog.turnOff()
							resetForm()
						}}
						header={editorConfig[firstName].header}
						onClickSubmit={submitForm}
						name={firstName}
					>
						<UserNameInput className={classes.firstName} />
					</EditorDialog>
				)}
			</Formik>
			<CancelAutoRenewalDialog
				onClose={autoRenewalDialog.turnOff}
				open={autoRenewalDialog.state}
				onCancel={onClickDisableSubscriptions}
			/>
			<TermsAndConditions
				onClose={termsAndConditionsDialog.turnOff}
				open={termsAndConditionsDialog.state}
			/>
			<PrivacyPolicy
				onClose={privacyPolicyDialog.turnOff}
				open={privacyPolicyDialog.state}
			/>
			<DeleteAccountDialog
				open={deleteAccountDialog?.state}
				onClose={deleteAccountDialog?.turnOff}
				userStatus={userStatus}
			/>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.close}
				type={'error'}
				title={'Error'}
				body={infoDialog.error}
			/>
			<DeleteReasonsDialog
				open={deleteReasonsDialog.state}
				onClose={deleteReasonsDialog.turnOff}
				onConfirm={onConfirmPauseAccount}
			/>
		</div>
	)
}

export default SettingsView
