import React from 'react'
import { routesByName } from 'constants/routes'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import SignInWelcomeScreen from 'pages/SignInWelcomeScreen'
import SignIn from 'modules/auth/signIn/SignIn'
import SignInHelpPage from 'pages/SignInHelpPage'

const SignInSwitch = () => {
	const { path } = useRouteMatch()

	return (
		<Switch>
			<Route exact path={path}>
				<SignInWelcomeScreen />
			</Route>

			<Route path={routesByName.signIn.withPhone}>
				<SignIn />
			</Route>

			<Route path={routesByName.signIn.help}>
				<SignInHelpPage />
			</Route>
		</Switch>
	)
}

export default SignInSwitch
