import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './BaseAccordionList.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import clsx from 'clsx'
import useFlagManager from 'hooks/useFlagManager'
import { gray4 } from 'constants/colors'

const BaseAccordionList = ({
	title,
	classes: outerClasses = {},
	items,
	onItemClick,
	isFirst = false
}) => {
	const expanded = useFlagManager(isFirst)

	const handleItemClick = useCallback(
		({ target }) => {
			onItemClick(target)
		},
		[onItemClick]
	)

	return (
		<div className={clsx(classes.root, outerClasses.root)}>
			<div
				className={classes.title}
				onClick={expanded.toggle}
				role="presentation"
			>
				{title}
			</div>
			{expanded.state
				? items.map((item, index) => (
						<div
							className={classes.item}
							key={item + index}
							role="presentation"
							onClick={handleItemClick}
						>
							{item}
							<SpriteIcon name="arrow-next2" color={gray4} />
						</div>
				  ))
				: null}
		</div>
	)
}

BaseAccordionList.propTypes = {
	title: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.string),
	onItemClick: PropTypes.func
}

BaseAccordionList.defaultProps = {
	onItemClick: () => null
}

export default memo(BaseAccordionList)
