import { useCallback } from 'react'
import { signInWithFacebook } from 'modules/auth/signIn/store/actions'
import { useDispatch } from 'react-redux'

const useFacebookAuth = () => {
	const dispatch = useDispatch()

	const handleClickLogin = useCallback(async () => {
		try {
			window.FB.login(
				({ authResponse }) => {
					if (!authResponse) {
						return null
					}

					dispatch(signInWithFacebook(authResponse))
				},
				{ scope: 'email' }
			)
		} catch (e) {
			console.log(e)
		}
	}, [dispatch])

	return {
		login: handleClickLogin,
		signUp: handleClickLogin
	}
}

export default useFacebookAuth
