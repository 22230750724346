import React, { useMemo } from 'react'
import classes from './Settings.module.scss'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import SpriteIcon from 'components/ui/SpriteIcon'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import useHistory from 'hooks/useHistory'
import SettingsPage from 'modules/settings/index'
import { useRouteMatch, Route, useLocation, Switch } from 'react-router-dom'
import BillingPage from 'modules/subscriptionBilling/billing'
import { routesByName } from 'constants/routes'
import HistoryPage from 'modules/subscriptionBilling/history'
import PaymentMethodPage from 'modules/subscriptionBilling/paymentMethod'
import _useDidMount from 'hooks/useDidMount'
import {
	getPaymentInfo,
	getPaymentList
} from 'modules/subscriptionBilling/store/actions'
import { useDispatch } from 'react-redux'
import ChangeIdentityPage from 'modules/auth/changeIdentity'

const titles = {
	[routesByName.main.settings.root]: 'Account settings',
	[routesByName.main.settings.billing.root]: 'Payment method',
	[routesByName.main.settings.billing.addPaymentMethod]: 'Add new payment',
	[routesByName.main.settings.billing.editPaymentMethod]:
		'Edit your payment method',
	[routesByName.main.settings.billing.history]: 'Billing History',
	[routesByName.main.settings.changeIdentityWithType('phone')]: 'Change Phone',
	[routesByName.main.settings.changeIdentityWithType('email')]: 'Change Email',
	[routesByName.main.settings.billing.subscribe]: 'Payment'
}

const SettingsLayout = () => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const history = useHistory()
	const dispatch = useDispatch()
	const isMobile = useMemo(() => format === screenFormat.mobile, [format])
	const { path } = useRouteMatch()
	const location = useLocation()

	_useDidMount(() => {
		dispatch(getPaymentInfo())
		dispatch(getPaymentList())
	})

	return (
		<div className={classes.root}>
			{isMobile ? (
				<NavigationHeader
					title={titles[location.pathname] || 'Payment'}
					onClickBackIcon={history.goBack}
				/>
			) : (
				<SpriteIcon
					name="arrow-back"
					className={classes.arrowBack}
					onClick={history.goBack}
				/>
			)}
			<Switch>
				<Route path={path} exact component={SettingsPage} />
				<Route
					path={routesByName.main.settings.billing.root}
					exact
					component={BillingPage}
				/>
				<Route
					path={routesByName.main.settings.billing.history}
					component={HistoryPage}
				/>
				<Route
					path={routesByName.main.settings.billing.addPaymentMethod}
					component={PaymentMethodPage}
				/>
				<Route
					path={routesByName.main.settings.billing.editPaymentMethod}
					component={PaymentMethodPage}
				/>
				<Route
					path={routesByName.main.settings.changeIdentity}
					component={ChangeIdentityPage}
				/>
			</Switch>
		</div>
	)
}

export default SettingsLayout
