import React, { useCallback, useMemo, useState } from 'react'
import classes from './Upgrade.module.scss'
import Logo from 'components/ui/layout/Logo'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { screenFormat } from 'constants/screenFormat'
import Button from 'components/ui/Button'
import { breakpointsUp } from 'constants/breakpoints'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'
import RateCard from 'components/ui/RateCard'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import { useDispatch, useSelector } from 'react-redux'
import {
	getCurrentPlan,
	getCurrentPlanId,
	getExchangeRates,
	getSubscriptionPlans
} from 'modules/subscriptionBilling/store/selectors'
import { Route, Switch } from 'react-router-dom'
import SubscribePage from 'modules/subscriptionBilling/subscribe'
import SpriteIcon from 'components/ui/SpriteIcon'
import NativeSelect from 'components/ui/controls/NativeSelect'
import TermsAndConditions from 'pages/TermsAndConditionsDialog'
import PrivacyPolicy from 'pages/PrivacyPolicyDialog'
import useFlagManager from 'hooks/useFlagManager'
import DesktopCarouselBanner from 'components/ui/UpgradeBanners/DesktopCarouselBanner/DesktopCarouselBanner'
import './overrideClasses.scss'
import { setCurrentPlan } from 'modules/subscriptionBilling/store/actions'
import _useDidMount from 'hooks/useDidMount'
import usePromoCode from 'hooks/usePromoCode'
import { _isEmpty } from 'utils/lodash'

const cardClasses = {
	root: classes.monthlyRate,
	month: classes.month,
	rate: classes.rate,
	rateDescription: classes.rateDescription,
	price: classes.price
}

const carouselClasses = {
	root: classes.carouselRoot,
	wrapper: classes.carouselWrapper,
	slide: classes.slide,
	dotsContainer: classes.dotsContainer,
	carouselRoot: classes.carousel,
	slideDescription: classes.slideDescription
}

const currencyOptions = [
	{
		value: 'CAD',
		label: 'CAD'
	},
	{
		value: 'USD',
		label: 'USD'
	},
	{
		value: 'GBP',
		label: 'GBP'
	},
	{
		value: 'EUR',
		label: 'EUR'
	},
	{
		value: 'AUD',
		label: 'AUD'
	}
]

const Upgrade = () => {
	const history = useHistory()
	const plans = useSelector(getSubscriptionPlans)
	const [currency, setCurrency] = useState('CAD')
	const termsAndConditionsDialog = useFlagManager()
	const privacyPolicyDialog = useFlagManager()
	const exchangeRates = useSelector(getExchangeRates)
	const dispatch = useDispatch()
	const currentPlan = useSelector(getCurrentPlan)
	const currentPlanId = useSelector(getCurrentPlanId)
	const promoCode = usePromoCode(currentPlan?.id, currentPlan?.promoCode)

	_useDidMount(() => {
		if (
			!_isEmpty(plans[2]) &&
			_isEmpty(currentPlan) &&
			_isEmpty(currentPlan?.promoCode)
		) {
			dispatch(setCurrentPlan(plans[2].id))
		} else return null
	})

	const handleCurrencyChange = useCallback(async ({ target: { value } }) => {
		setCurrency(value)
	}, [])

	const format = useDetermineScreenFormat(breakpointsUp.sm)

	const handleCardClick = useCallback(
		event => {
			dispatch(setCurrentPlan(event.currentTarget.getAttribute('data-id')))
			promoCode.resetCode()
		},
		[dispatch, promoCode]
	)

	const handleContinueClick = useCallback(
		() => history.push(routesByName.upgrade.subscribe),
		[history]
	)

	const handleClickTermsAndConditions = useCallback(() => {
		termsAndConditionsDialog.turnIn()
	}, [termsAndConditionsDialog])

	const handleClickPrivacyPolicy = useCallback(() => {
		privacyPolicyDialog.turnIn()
	}, [privacyPolicyDialog])

	const handleClickBack = useCallback(() => history.goBack(), [history])

	const renderRateCards = useMemo(() => {
		const discounts = plans?.reduce((prev, curr) => {
			return [...prev, curr.discount]
		}, [])

		const bestPrice = Math.max(...discounts)

		return plans?.map(({ id, month, renew, price, discount }) => (
			<RateCard
				classes={cardClasses}
				onClick={handleCardClick}
				id={id}
				price={price}
				renewsAt={renew}
				months={month}
				discount={discount}
				key={id}
				bestPrice={bestPrice === discount}
				currency={exchangeRates[currency]}
				isActive={id === currentPlanId}
			/>
		))
	}, [currency, currentPlanId, exchangeRates, handleCardClick, plans])

	const renderDesktopUpgrade = useMemo(
		() =>
			format === screenFormat.desktop && (
				<div className={classes.root}>
					<div className={classes.logo}>
						<Logo variant="dark" withTitle />
					</div>
					<div className={classes.content}>
						<div className={classes.backIcon}>
							<SpriteIcon name="arrow-down" onClick={handleClickBack} />
						</div>
						<NativeSelect
							className={classes.select}
							options={currencyOptions}
							value={currency}
							onChange={handleCurrencyChange}
							name="currency"
						/>

						<div className={classes.rateCards}>
							<BaseScrollbars>
								<div className={classes.cardsWrapper}>{renderRateCards}</div>
							</BaseScrollbars>
						</div>
						<SubscribePage promoCode={promoCode} />
						<DesktopCarouselBanner classes={carouselClasses} withImg />
					</div>
				</div>
			),
		[
			currency,
			format,
			handleClickBack,
			handleCurrencyChange,
			promoCode,
			renderRateCards
		]
	)

	const renderMobileUpgrade = useMemo(
		() =>
			format === screenFormat.mobile && (
				<div className={classes.root}>
					<Switch>
						<Route exact path={routesByName.upgrade.root}>
							<NavigationHeader
								title="Upgrade"
								onClickBackIcon={history.goBack}
							/>
							<BaseScrollbars>
								<div className={classes.wrapper}>
									<DesktopCarouselBanner classes={carouselClasses} withImg />

									<NativeSelect
										className={classes.select}
										options={currencyOptions}
										value={currency}
										onChange={handleCurrencyChange}
										name="currency"
									/>
									<div className={classes.rateCards}>{renderRateCards}</div>

									<Button
										className={classes.continueBtn}
										shape="rounded"
										onClick={handleContinueClick}
									>
										Continue
									</Button>

									<div className={classes.logo}>
										<Logo variant="dark" withTitle />
									</div>

									<div className={classes.termsAndConditions}>
										<div
											role="presentation"
											onClick={handleClickTermsAndConditions}
										>
											Terms and Conditions
										</div>
										<div role="presentation" onClick={handleClickPrivacyPolicy}>
											Privacy Policy
										</div>
									</div>
								</div>
							</BaseScrollbars>
						</Route>
						<Route exact path={routesByName.upgrade.subscribe}>
							<NavigationHeader
								title="Payment"
								onClickBackIcon={history.goBack}
							/>
							<SubscribePage promoCode={promoCode} />
						</Route>
					</Switch>
					<TermsAndConditions
						onClose={termsAndConditionsDialog.turnOff}
						open={termsAndConditionsDialog.state}
					/>
					<PrivacyPolicy
						onClose={privacyPolicyDialog.turnOff}
						open={privacyPolicyDialog.state}
					/>
				</div>
			),
		[
			format,
			history.goBack,
			currency,
			handleCurrencyChange,
			renderRateCards,
			handleContinueClick,
			handleClickTermsAndConditions,
			handleClickPrivacyPolicy,
			promoCode,
			termsAndConditionsDialog.turnOff,
			termsAndConditionsDialog.state,
			privacyPolicyDialog.turnOff,
			privacyPolicyDialog.state
		]
	)

	return (
		<>
			{renderMobileUpgrade}
			{renderDesktopUpgrade}
		</>
	)
}

export default Upgrade
