import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from './ColoredChip.module.scss'
import clsx from 'clsx'
import randomColor from 'randomcolor'

const ColoredChip = props => {
	const { className, color, label } = props

	const fallbackColor = useMemo(
		() => randomColor({ luminosity: 'dark', hue: 'random' }),
		[]
	)

	return (
		<div
			className={clsx(classes.root, className)}
			style={{
				color: color || fallbackColor,
				borderColor: color || fallbackColor
			}}
		>
			{label}
		</div>
	)
}

ColoredChip.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	label: PropTypes.string
}

ColoredChip.defaultProps = {
	className: '',
	color: '',
	label: 'Example'
}

export default memo(ColoredChip)
