import React, { memo, useMemo } from 'react'
import classes from './ConversationItem.module.scss'
import Typography from 'components/ui/Typography'
import UserAvatar from 'components/ui/UserAvatart'
import clsx from 'clsx'
import moment from 'moment'
import BaseClampLines from 'components/wrappers/ui/BaseClampLines'
import { _get } from 'utils/lodash.js'
import { useSelector } from 'react-redux'
import { getMyId } from 'modules/profile/store/selectors'
import { getUserById } from 'modules/users/selectors.js'

const ConversationItem = ({
	id,
	lastMessage = {},
	isRead = false,
	withHint = false,
	onClick = () => null,
	participants,
	ownerId,
	smooth,
	smoothMessage,
	classes: outerClasses = {}
}) => {
	const myId = useSelector(getMyId)

	const participant = useMemo(
		() => participants.find(({ userId }) => userId !== myId),
		[myId, participants]
	)

	const user = useSelector(getUserById(participant?.userId))

	const isUserBlocked = user?.is_blocked

	const avatar = useMemo(
		() => user.photos?.find(({ type }) => type === 'avatar')?.small,
		[user.photos]
	)
	const photo = useMemo(
		() => user.photos?.find(({ type }) => type === 'photo')?.medium,
		[user.photos]
	)

	const renderHint = useMemo(() => {
		return lastMessage?.userId !== myId && withHint ? (
			<Typography className={classes.hint}>Your turn</Typography>
		) : null
	}, [lastMessage, myId, withHint])

	const body = useMemo(() => {
		try {
			// return _get(lastMessage, 'body', '')?.replaceAll(`<br/>`, ' ')
			return _get(lastMessage, 'body', '')?.replace(/<br\/>/g, ' ')
		} catch (e) {
			console.log(_get(lastMessage, 'body', ''))
		}
	}, [lastMessage])

	return (
		<div
			className={clsx(classes.root, outerClasses.root, {
				[classes.read]: isRead,
				[classes.smooth]: smooth,
				[classes.smoothMessage]: smoothMessage,
				[classes.blocked]: isUserBlocked
			})}
			onClick={onClick}
			data-id={id}
			role="presentation"
		>
			{renderHint}
			<UserAvatar
				src={avatar || photo}
				className={clsx(classes.avatar, { [classes.blocked]: isUserBlocked })}
				type="square"
			/>
			<Typography
				className={clsx(classes.name, { [classes.blocked]: isUserBlocked })}
			>
				{user.firstName || 'Loading...'}
			</Typography>
			{!isUserBlocked ? (
				<>
					<BaseClampLines text={body || ''} className={classes.message} />
					<Typography className={classes.date}>
						{moment.unix(_get(lastMessage, 'updatedAt', '') / 1000).fromNow()}
					</Typography>
				</>
			) : null}
		</div>
	)
}

export default memo(ConversationItem)
