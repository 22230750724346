import { useCallback, useMemo } from 'react'
import { received } from 'modules/notifications/requests/RequestsView'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from './store/selectors'
import { getIdFromDataAttribute } from 'utils/getIdFromDataAttribute.js'
import { screenFormat } from 'constants/screenFormat.js'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import { routesByName } from 'constants/routes.js'
import useHistory from 'hooks/useHistory.js'
import { sent } from 'modules/notifications/requests/RequestsView.js'
import { setCurrentConversation } from 'modules/conversation/store/actions.js'
import useUpgradeNotification from 'hooks/useUpgradeNotification'
import _useDidMount from 'hooks/useDidMount'
import { getRequests } from 'modules/notifications/requests/store/actions'

const paths = {
	[received]: routesByName.main.notifications.requests.received,
	[sent]: routesByName.main.notifications.requests.sent
}

const RequestsContainer = ({ children }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const upgradeNotification = useUpgradeNotification()

	const receivedRequests = useSelector(selectors.getReceivedRequests)
	const sentRequests = useSelector(selectors.getSentRequests)

	const handleChangeSelectedTab = useCallback(
		value => {
			history.push(paths[value])
		},
		[history]
	)

	const handleClickReceivedMessage = useCallback(
		event => {
			if (!upgradeNotification.isSubscribe) {
				return upgradeNotification.unlockFeatures.controller.turnIn()
			}

			const id = getIdFromDataAttribute(event)
			dispatch(setCurrentConversation(id))

			if (format === screenFormat.mobile) {
				history.push(
					routesByName.main.notifications.requests.mobileReceivedConversation
				)
			} else {
				history.push(
					routesByName.main.notifications.requests.receivedConversation
				)
			}
		},
		[
			dispatch,
			format,
			history,
			upgradeNotification.isSubscribe,
			upgradeNotification.unlockFeatures.controller
		]
	)

	const handleClickSentMessage = useCallback(
		event => {
			if (!upgradeNotification.isSubscribe) {
				return upgradeNotification.unlockFeatures.controller.turnIn()
			}
			const id = getIdFromDataAttribute(event)

			dispatch(setCurrentConversation(id))

			if (format === screenFormat.mobile) {
				history.push(routesByName.conversation.root)
			}
		},
		[
			dispatch,
			format,
			history,
			upgradeNotification.isSubscribe,
			upgradeNotification.unlockFeatures.controller
		]
	)

	_useDidMount(() => {
		dispatch(getRequests())
	})

	const props = useMemo(
		() => ({
			receivedRequests,
			sentRequests,
			onChangeTab: handleChangeSelectedTab,
			onClickSentMessage: handleClickSentMessage,
			onClickReceivedMessage: handleClickReceivedMessage,
			upgradeNotification
		}),
		[
			handleChangeSelectedTab,
			handleClickReceivedMessage,
			handleClickSentMessage,
			receivedRequests,
			sentRequests,
			upgradeNotification
		]
	)

	return children(props)
}

export default RequestsContainer
