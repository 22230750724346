import { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'modules/discovery/store/actions'
import * as discoverySelectors from 'modules/discovery/store/selectors'
import * as services from 'modules/discovery/services'
import { screenFormat } from 'constants/screenFormat'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { getIdFromDataAttribute } from 'utils/getIdFromDataAttribute.js'
import { setUserForCreateConversation } from 'modules/conversation/store/actions.js'
import useUpgradeNotification from 'hooks/useUpgradeNotification.js'
import userSelectors from 'modules/users/selectors'
import { upgradeMessageConfig } from 'libs/toastr/upgradeToastr'

const DiscoveryContainer = ({ children }) => {
	const history = useHistory()
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const dispatch = useDispatch()
	const scrollRef = useRef()

	const applicantId = useSelector(discoverySelectors.getCurrentApplicantId)
	const applicant = useSelector(userSelectors.getUserById(applicantId))
	const usersAreOver = useSelector(discoverySelectors.getUsersAreOver)

	const { showUpgradeToastr, isSubscribe } = useUpgradeNotification()

	const smoothScrollToTop = useCallback(() => {
		scrollRef.current.view.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}, [])

	const handleClickLike = useCallback(
		async event => {
			smoothScrollToTop()
			const id = getIdFromDataAttribute(event)

			dispatch(actions.likeApplicant(id))
		},
		[dispatch, smoothScrollToTop]
	)

	const handleClickReject = useCallback(
		async event => {
			smoothScrollToTop()
			const id = getIdFromDataAttribute(event)

			dispatch(actions.rejectApplicant(id))
		},
		[dispatch, smoothScrollToTop]
	)

	const handleClickUndo = useCallback(
		async event => {
			if (!isSubscribe)
				return showUpgradeToastr(upgradeMessageConfig.undoUpgrade)
			try {
				const {
					result: [personId]
				} = await services.undo()
				if (personId) {
					await dispatch(actions.preparePreviousApplicant(personId))
				}
			} catch (e) {
				console.dir(e)
			}
		},
		[dispatch, showUpgradeToastr, isSubscribe]
	)

	const handleClickSendMessage = useCallback(
		async event => {
			if (!isSubscribe) return showUpgradeToastr()

			try {
				const id = getIdFromDataAttribute(event)
				dispatch(setUserForCreateConversation(id))

				if (format === screenFormat.mobile) {
					history.push(
						routesByName.main.notifications.requests.sentConversationCreate
					)
				} else {
					history.push(routesByName.main.discovery.sendMessage)
				}
			} catch (e) {
				console.dir(e)
			}
		},
		[dispatch, format, history, showUpgradeToastr, isSubscribe]
	)

	const handleClickFilter = useCallback(() => {
		history.push(routesByName.main.discovery.preference)
	}, [history])

	const props = useMemo(
		() => ({
			callbacks: {
				onClickLike: handleClickLike,
				onClickPass: handleClickReject,
				onClickUndo: handleClickUndo,
				onClickSendMessage: handleClickSendMessage,
				onClickFilter: handleClickFilter
			},
			applicant,
			applicantId,
			isSubscribe,
			scrollRef,
			usersAreOver
		}),
		[
			applicant,
			handleClickFilter,
			handleClickLike,
			handleClickReject,
			handleClickSendMessage,
			handleClickUndo,
			isSubscribe,
			applicantId,
			usersAreOver
		]
	)

	return children(props)
}

export default DiscoveryContainer
