import React from 'react'
import MatchesCreateContainer from './MatchesCreateContainer.js'
import CreateConversationMobile from 'modules/conversation/create/CreateConversationMobile.js'

const MatchesCreateMobile = () => {
	return (
		<MatchesCreateContainer>
			{({ onPushMessage }) => (
				<CreateConversationMobile onPushMessage={onPushMessage} />
			)}
		</MatchesCreateContainer>
	)
}

export default MatchesCreateMobile
