import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { routesByName } from 'constants/routes.js'
import ReceivedPage from 'modules/notifications/likes/pages/ReceivedPage'
import SentPage from 'modules/notifications/likes/pages/SentPage'

const LikesSwitch = () => {
	const { path } = useRouteMatch()
	return (
		<Switch>
			<Route path={path} exact>
				<Redirect to={routesByName.main.notifications.likes.received} />
			</Route>
			<Route path={routesByName.main.notifications.likes.received}>
				<ReceivedPage />
			</Route>
			<Route path={routesByName.main.notifications.likes.previewReceived}>
				<ReceivedPage />
			</Route>
			<Route path={routesByName.main.notifications.likes.sent}>
				<SentPage />
			</Route>
			<Route path={routesByName.main.notifications.likes.previewSent}>
				<SentPage />
			</Route>
		</Switch>
	)
}

export default LikesSwitch
