import React from 'react'
import classes from './ChatInput.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import BaseTextArea from 'components/ui/controls/BaseTextArea'
import clsx from 'clsx'

const ChatInput = ({
	value,
	onChange = () => null,
	onClickInput = () => null,
	onClickPushBtn = () => null,
	inputRef,
	inputContainerRef,
	disabled
}) => {
	return (
		<div className={classes.root} ref={inputContainerRef}>
			<BaseTextArea
				rowsMax={6}
				value={value}
				onClick={onClickInput}
				ref={inputRef}
				onChange={onChange}
				className={clsx(classes.input, { [classes.disabled]: disabled })}
				disabled={disabled}
			/>
			<SpriteIcon
				name="send"
				className={classes.send}
				onClick={onClickPushBtn}
			/>
		</div>
	)
}

export default ChatInput
