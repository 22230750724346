import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from './RateCard.module.scss'
import clsx from 'clsx'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'

const RateCard = ({
	classes: outerClasses = {},
	months,
	onClick,
	price,
	renewsAt,
	discount,
	id,
	bestPrice,
	currency = {},
	isActive
}) => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const perMonth = useMemo(
		() => parseFloat(((price * currency.index) / months)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0],
		[currency.index, months, price]
	)

	return (
		<div
			className={clsx(
				outerClasses.root,
				{
					[classes.bestPriceCard]: bestPrice
				},
				{ [classes.active]: isActive }
			)}
			role="presentation"
			data-id={id}
			onClick={onClick}
		>
			{bestPrice && <div className={classes.mostPopular}>Most Popular</div>}
			<div
				className={clsx(
					outerClasses.month,
					months === 3 && classes.primaryText,
					{ 'mb-2': months === 1 && format === screenFormat.desktop }
				)}
			>
				{months} {`month${months > 1 ? 's' : ''}`}
				{/*{format === screenFormat.mobile && discount !== 0 && (*/}
				{/*	<div*/}
				{/*		className={clsx(classes.discount, {*/}
				{/*			[classes.bestPrice]: bestPrice*/}
				{/*		})}*/}
				{/*	>*/}
				{/*		{discount}% discount*/}
				{/*	</div>*/}
				{/*)}*/}
			</div>
			<div className={clsx(outerClasses.rate)}>
				<div className={outerClasses.rateDescription}>
					<span className={clsx(classes.price, outerClasses.price)}>
						{currency.sign}
						{perMonth}
					</span>{' '}
					<span className={classes.currency}>{currency.name}</span>
					<div>
						{discount !== 0 ? (
							<span>Save {discount}%</span>
						) : null}
						<br />
						<span>per month</span>
						<br />
						{months !== 1 ? (
							<span className={classes.sum}>
								({currency.sign}
								{(price * currency.index).toFixed(2)} total)
							</span>
						) : null}
					</div>
				</div>
			</div>
			{/*{format === screenFormat.desktop && discount !== 0 && (*/}
			{/*	<div*/}
			{/*		className={clsx(classes.discount, {*/}
			{/*			[classes.bestPrice]: bestPrice*/}
			{/*		})}*/}
			{/*	>*/}
			{/*		{discount}% discount*/}
			{/*	</div>*/}
			{/*)}*/}
		</div>
	)
}

RateCard.propTypes = {
	onClick: PropTypes.func,
	rate: PropTypes.number,
	renewAt: PropTypes.number,
	discount: PropTypes.number,
	bestPrice: PropTypes.bool,
	total: PropTypes.number
}

RateCard.defaultProps = {
	onClick: () => null,
	bestPrice: false,
	total: null
}

export default memo(RateCard)
