import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'
import appConfig from 'constants/appConfig'

export const getDiscoveryUsers = params =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/discovery/users', {
		params
	})

export const likeApplicant = userId =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + `/discovery/matches/like/${userId}`
	)
export const rejectApplicant = userId =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + `/discovery/matches/skip/${userId}`
	)

export const undo = () =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + `/discovery/matches/undo`)
