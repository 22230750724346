import React from 'react'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Button from 'components/ui/Button'
import classes from './EditorDialog.module.scss'
import Typography from 'components/ui/Typography'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import { screenFormat } from 'constants/screenFormat.js'
import SpriteIcon from 'components/ui/SpriteIcon'
import clsx from 'clsx'

const EditorDialog = ({ open, children, onClose, header, onClickSubmit }) => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)

	return (
		<BaseDialog
			open={open}
			onClose={onClose}
			classes={{ paper: classes.root }}
			maxWidth="md"
			fullScreen={format === screenFormat.mobile}
		>
			<div className={classes.wrapper}>
				<div className={classes.headerWrapper}>
					{format === screenFormat.mobile ? (
						<SpriteIcon
							name="arrow-back"
							onClick={onClose}
							className={classes.iconBack}
						/>
					) : null}

					<Typography
						className={classes.header}
					>{`Update: ${header}`}</Typography>
				</div>

				{/*<div className={classes.wrapper1}>*/}
				{/*<BaseScrollbars>*/}
				<div className={classes.innerWrapper}>
					<div className={classes.content}>{children}</div>

					<div className={classes.buttons}>
						<Button
							// variant="outlined"
							shape="rounded"
							color="white"
							size="md"
							className={clsx(classes.submitBtn, classes.confirmBtn)}
							onClick={onClickSubmit}
							type="submit"
						>
							Done
						</Button>

						<Button
							variant="outlined"
							shape="rounded"
							// color="white"
							size="md"
							className={clsx(classes.submitBtn, classes.cancelBtn)}
							onClick={onClose}
						>
							Cancel
						</Button>
					</div>
				</div>
				{/*</BaseScrollbars>*/}
				{/*</div>*/}
			</div>
		</BaseDialog>
	)
}

export default EditorDialog
