import { useCallback, useState } from 'react'
import { routesByName } from 'constants/routes.js'
import useHistory from 'hooks/useHistory.js'
import { useDispatch } from 'react-redux'
import { likeApplicant } from 'modules/discovery/services.js'
import { prepareNextApplicant } from 'modules/discovery/store/actions.js'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import { screenFormat } from 'constants/screenFormat.js'
import { setCurrentConversation } from 'modules/conversation/store/actions.js'
import { getRequests } from 'modules/notifications/requests/store/actions'
import useFlagManager from 'hooks/useFlagManager'

const RequestCreateContainer = ({ children }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const handlePushMessage = useCallback(
		async conversation => {
			try {
				const participantId = conversation.participants.find(
					({ userId }) => userId !== conversation.ownerId
				)?.userId

				await likeApplicant(participantId)
				dispatch(prepareNextApplicant())
				dispatch(getRequests())
				dispatch(setCurrentConversation(conversation.id))

				if (format === screenFormat.mobile) {
					history.replace(routesByName.conversation.root)
				} else {
					history.replace(routesByName.main.discovery.conversation)
				}
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			}
		},
		[dispatch, format, history, infoDialog]
	)

	const props = {
		onPushMessage: handlePushMessage,
		infoDialog: {
			state: infoDialog.state,
			close: infoDialog.turnOff,
			error: error
		}
	}

	return children(props)
}

export default RequestCreateContainer
