import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from './AboutUserCard.module.scss'
import BaseCard from 'components/ui/controls/BaseCard'
import Typography from 'components/ui/Typography'
import clsx from 'clsx'
import { blobToSrc } from 'utils/blobToSrc'
import BaseImage from 'components/wrappers/ui/BaseImage'

const AboutUserCard = ({
	answer,
	question,
	avatar,
	classes: outerClasses = {}
}) => {
	const avatarSrc = useMemo(
		() => (avatar instanceof Blob ? blobToSrc(avatar) : avatar),
		[avatar]
	)

	return (question && answer) || avatarSrc ? (
		<div className={clsx(classes.root, outerClasses.root)}>
			{avatarSrc ? (
				<BaseImage src={avatarSrc} classes={{ root: classes.image }} />
			) : null}
			{question && answer ? (
				<BaseCard className={classes.card}>
					<Typography className={classes.question}>{question}</Typography>
					<Typography className={classes.answer}>{answer}</Typography>
				</BaseCard>
			) : null}
		</div>
	) : null
}

AboutUserCard.propTypes = {
	className: PropTypes.string,
	answer: PropTypes.string,
	question: PropTypes.string
}

AboutUserCard.defaultProps = {
	className: '',
	answer: '',
	question: ''
}

export default memo(AboutUserCard)
