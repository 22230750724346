import React, { useMemo } from 'react'
import classes from './Likes.module.scss'
import NotificationPlaceholder from 'modules/notifications/components/NotificationPlaceholder'
import LikeCard from 'modules/notifications/likes/components/LikeCard'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import clsx from 'clsx'
import BaseTabs from 'components/ui/controls/BaseTabs/BaseTabs'
import CustomInfiniteScroll from 'components/wrappers/ui/CustomInfiniteScroll'
import FallbackPage from 'pages/FallbackPage/FallbackPage'

const placeholder = 'New Likes Will Appear Here.'
const noLikesReceived = "You don't have any likes yet"
const noLikesSent = "You haven't liked anyone yet"

export const received = 'received'
export const sent = 'sent'
export const previewReceived = 'previewReceived'
export const previewSent = 'previewSent'

const tabs = [
	{
		value: received,
		label: 'Received'
	},
	{
		value: sent,
		label: 'Sent'
	}
]

const LikesView = ({
	receivedLikes,
	sentLikes,
	onClickPreview,
	onClickPass,
	onChangeTab,
	onClickSendMessage,
	upgradeNotification,
	selectedTab,
	loadMoreReceivedLikes,
	loadMoreSentLikes,
	hasMoreReceivedLikes,
	hasMoreSentLikes,
	isLoadingReceivedLikes,
	isLoadingSentLikes
}) => {
	const likesByTab = useMemo(
		() => (selectedTab === sent ? sentLikes : receivedLikes),
		[sentLikes, receivedLikes, selectedTab]
	)

	const placeholderByTab = useMemo(
		() => (selectedTab === sent ? noLikesSent : noLikesReceived),
		[selectedTab]
	)

	return (
		<div className={classes.root}>
			{!receivedLikes?.length && !sentLikes?.length ? (
				<NotificationPlaceholder text={placeholder} />
			) : (
				<div className={classes.content}>
					<div className={classes.header}>
						<BaseTabs
							variant="secondary"
							tabs={tabs}
							value={selectedTab}
							onChange={onChangeTab}
						/>
					</div>
					<BaseScrollbars>
						{selectedTab === received && upgradeNotification.textBanner}
						<CustomInfiniteScroll
							hasMore={
								selectedTab === sent ? hasMoreSentLikes : hasMoreReceivedLikes
							}
							loadMore={
								selectedTab === sent ? loadMoreSentLikes : loadMoreReceivedLikes
							}
							isLoading={
								selectedTab === sent
									? isLoadingSentLikes
									: isLoadingReceivedLikes
							}
							className={clsx({
								[classes.placeholderWrapper]: !likesByTab?.length
							})}
						>
							<div
								className={clsx(classes.items, {
									[classes.height]: !likesByTab?.length
								})}
							>
								{likesByTab?.length ? (
									likesByTab?.map((id, index) => (
										<LikeCard
											key={`${selectedTab}-like-card-${id}-${index}`}
											onAccept={onClickPreview}
											onPass={onClickPass}
											onMessage={onClickSendMessage}
											isBlurred={
												!upgradeNotification.isSubscribe &&
												selectedTab === received
											}
											isMessageIcon={selectedTab === sent}
											id={id}
											isCloseIcon={
												selectedTab !== received &&
												upgradeNotification.isSubscribe
											}
											selectedTab={selectedTab}
										/>
									))
								) : (
									<NotificationPlaceholder text={placeholderByTab} />
								)}
							</div>
						</CustomInfiniteScroll>
					</BaseScrollbars>
					{(isLoadingReceivedLikes || isLoadingSentLikes) && <FallbackPage />}
				</div>
			)}
			{upgradeNotification.unlockFeatures.component}
		</div>
	)
}

export default LikesView
