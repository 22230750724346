import React from 'react'
import classes from './LeftSidebar.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import { black3, primaryColor, secondaryColor } from 'constants/colors'

const DiscoveryLeftSidebar = () => {
	return (
		<div className={classes.root}>
			<SpriteIcon name="loupe-with-heart" className={classes.loupeIcon} />
			<Typography className={classes.title}>Find your matches here</Typography>
			<div className={classes.btnWrapper}>
				<Button size="md" shape="rounded" color="white" className={classes.btn}>
					<SpriteIcon name="close" className={classes.icon} color={black3} />
				</Button>

				<Typography className={classes.btnLabel}>Skip this person</Typography>
			</div>
			<div className={classes.btnWrapper}>
				<Button size="md" shape="rounded" color="white" className={classes.btn}>
					<SpriteIcon
						name="like"
						className={classes.icon}
						color={primaryColor}
					/>
				</Button>

				<Typography className={classes.btnLabel}>
					Match with this person
				</Typography>
			</div>
			<div className={classes.btnWrapper}>
				<Button shape="rounded" color="white" size="md" className={classes.btn}>
					<SpriteIcon
						name="reply"
						color={secondaryColor}
						className={classes.icon}
					/>
				</Button>

				<Typography className={classes.btnLabel}>
					Undo a skipped profile
				</Typography>
			</div>
			<div className={classes.btnWrapper}>
				<Button size="md" shape="rounded" color="white" className={classes.btn}>
					<SpriteIcon
						name="message-cloud"
						className={classes.icon}
						color={secondaryColor}
					/>
				</Button>

				<Typography className={classes.btnLabel}>
					Send a customized request to match
				</Typography>
			</div>
		</div>
	)
}

export default DiscoveryLeftSidebar
