import React from 'react'
import classes from './AddButton.module.scss'
import Typography from 'components/ui/Typography'
import SpriteIcon from 'components/ui/SpriteIcon'

const AddButton = ({ onClick, label }) => {
	return (
		<div role="presentation" className={classes.root} onClick={onClick}>
			<Typography className={classes.text}>{label}</Typography>
			<div className={classes.icon}>
				<SpriteIcon name="plus" className="primary-color" />
			</div>
		</div>
	)
}

export default AddButton
