import React from 'react'
import classes from './LastMatchCard.module.scss'
import Button from 'components/ui/Button'
import { ReactComponent as DarkLogo } from 'assets/staticIcons/dark-logo.svg'
import starImage from 'assets/staticIcons/stars.png'
import clsx from 'clsx'

const LastMatchCard = ({
	myAvatar,
	matchAvatar,
	onClickKeepBrowsing = () => null,
	onClickSendMessage = () => null
}) => {
	return (
		<div className={classes.root}>
			<div className={classes.wrapper}>
				<DarkLogo className={classes.logoIcon} />

				<div className={classes.title}>
					It's a <span className={classes.colored}>match!</span>
				</div>
				<div className={classes.wrapperAvatars}>
					<img className={classes.avatar} src={myAvatar} alt="My avatar" />
					<img
						className={clsx(classes.avatar, classes.matchAvatar)}
						src={matchAvatar}
						alt="My avatar"
					/>
					<img src={starImage} alt="Stars" className={classes.bgStars} />
				</div>

				<div className={classes.wrapperButtons}>
					<Button
						variant="contained"
						shape="rounded"
						color="secondary"
						size="lg"
						className={classes.sendMessage}
						onClick={onClickSendMessage}
						type="submit"
					>
						Send a message
					</Button>

					<Button
						variant="contained"
						color="transparent"
						size="lg"
						shape="rounded"
						className={classes.close}
						onClick={onClickKeepBrowsing}
					>
						Keep browsing
					</Button>
				</div>
			</div>
		</div>
	)
}

export default LastMatchCard
