import React, { useCallback, useMemo } from 'react'
import classes from './BasePhoneInput.module.scss'
import PropTypes from 'prop-types'
import 'react-phone-input-2/lib/style.css'
import clsx from 'clsx'
import BaseInput from 'components/ui/controls/BaseInput'
import {
	AsYouType,
	getCountries,
	getExampleNumber,
	getCountryCallingCode
} from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import useFlagManager from 'hooks/useFlagManager'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { screenFormat } from 'constants/screenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import useButtonsGroupHelper from 'hooks/useButtonsGroupHelper'
import SpriteIcon from 'components/ui/SpriteIcon'
import Flags from 'country-flag-icons/react/1x1'

const priorityCountries = [
	'CA',
	'US',
	'GB',
	'AU',
	'NZ',
	'DK',
	'DE',
	'FR',
	'CH',
	'NO',
	'IN',
	'MY',
	'SG',
	'LK',
	'ZA'
]

const priorityCountriesWithFlags = getCountries().reduce((accum, country) => {
	if (priorityCountries.includes(country)) {
		return [
			...accum,
			{
				label: country,
				flag: getUnicodeFlagIcon(country),
				callingCode: '+' + getCountryCallingCode(country)
			}
		]
	}
	return accum
}, [])

const restCountries = getCountries().reduce((accum, country) => {
	if (priorityCountries.includes(country)) {
		return accum
	} else {
		return [
			...accum,
			{
				label: country,
				flag: getUnicodeFlagIcon(country),
				callingCode: '+' + getCountryCallingCode(country)
			}
		]
	}
}, [])

const countries = [...priorityCountriesWithFlags, ...restCountries]

const BasePhoneInput = ({
	className,
	onChange,
	onChangeCountry,
	onBlur,
	value,
	hasCountryCode,
	...props
}) => {
	const selectCountryDialog = useFlagManager(false)
	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile
	const { searchComponent, filteredOptions } = useButtonsGroupHelper({
		options: countries,
		withSearch: true,
		searchPlaceholder: 'Enter your country',
		filterFunction: (field, search) =>
			field.label.startsWith(search.toUpperCase()) ||
			field.callingCode.startsWith(search)
	})

	const country = useMemo(
		() => countries.find(country => country?.label === value.country),
		[value.country]
	)
	const phone = useMemo(() => {
		if (/(.?\d){4,}/.test(value.number)) {
			return new AsYouType(value.country).input(value.number)
		}
		return value.number
	}, [value])

	const handleChangeCountry = useCallback(
		event => {
			const countryLabel = event.currentTarget.getAttribute('data-country')
			onChangeCountry(countryLabel)
			onChange({ target: { value: value.number } }, countryLabel)
			selectCountryDialog.turnOff()
		},
		[onChange, onChangeCountry, selectCountryDialog, value.number]
	)

	const handleChange = useCallback(
		event => {
			onChange(event, value.country)
		},
		[onChange, value.country]
	)

	const handleBlur = useCallback(
		event => {
			onBlur(event, value.country)
		},
		[onBlur, value.country]
	)

	const placeholder = useMemo(
		() => getExampleNumber(value?.country, examples)?.formatNational(),
		[value.country]
	)

	const CurrentCountryFlag = useMemo(() => Flags[country.label], [
		country.label
	])

	return (
		<div className={clsx(className, classes.root)}>
			<div
				className={clsx(classes.countrySelect, 'mr-1', {
					[classes.error]: props.error && props.touched,
					[classes.verified]: !props.error && props.touched
				})}
				role="presentation"
				onClick={selectCountryDialog.turnIn}
			>
				<CurrentCountryFlag className={classes.flag} /> {country.label}
			</div>
			<BaseInput
				{...props}
				type="tel"
				onChange={handleChange}
				onBlur={handleBlur}
				placeholder={placeholder}
				value={phone}
			/>
			<BaseDialog
				open={selectCountryDialog.state}
				onClose={selectCountryDialog.turnOff}
				maxWidth="md"
				fullScreen={isMobile}
			>
				<div className={classes.countriesList}>
					{isMobile && (
						<SpriteIcon
							name="arrow-back"
							className={classes.backIcon}
							onClick={selectCountryDialog.turnOff}
							color="#E5E5E5"
						/>
					)}
					{searchComponent}
					{filteredOptions.map((country, index) => {
						const CountryFlag = Flags[country.label]
						return (
							<div
								className={classes.country}
								role="presentation"
								key={country.callingCode + country.label}
								data-country={country.label}
								onClick={handleChangeCountry}
							>
								<CountryFlag className={classes.flag} /> {country.label}{' '}
								{country.callingCode}
							</div>
						)
					})}
				</div>
			</BaseDialog>
		</div>
	)
}

BasePhoneInput.propTypes = {
	availableCountries: PropTypes.array,
	className: PropTypes.string,
	value: PropTypes.exact({
		number: PropTypes.string,
		international: PropTypes.string,
		country: PropTypes.string
	}),
	onChange: PropTypes.func,
	onChangeCountry: PropTypes.func,
	onBlur: PropTypes.func
}

BasePhoneInput.defaultProps = {
	className: '',
	value: {
		number: '',
		international: '',
		country: 'UK'
	},
	onChange: () => null,
	onChangeCountry: () => null,
	onBlur: () => null
}

export default BasePhoneInput
