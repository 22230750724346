import React from 'react'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Typography from '../Typography'
import Button from '../Button'
import classes from './styles.module.scss'

const SuccessPaymentDialog = ({ open = false, onClose }) => {
	return (
		<BaseDialog
			open={open}
			rootClass={classes.dialog}
			maxWidth="xs"
			onClose={onClose}
		>
			<div className={classes.root}>
				<div className={classes.modal}>
					<Typography className={classes.modalTitle}>Success!</Typography>
					<Typography className={classes.modalSubtitle}>
						Congrats on your premium myTamilDate membership.
					</Typography>
					<div className={classes.buttons}>
						<Button onClick={onClose} shape="rounded" className={classes.btn}>
							Let's Meet Someone Great
						</Button>
					</div>
				</div>
			</div>
		</BaseDialog>
	)
}

export default SuccessPaymentDialog
