import React from 'react'

const accordionTitles = {
	about: 'About myTamilDate',
	gettingStarted: 'Getting Started',
	matching: 'Matching and Messaging',
	browsing: 'Browsing',
	profile: 'Profile and Account Settings',
	premium: 'Premium Memberships',
	getInTouch: 'Get in touch'
}

export const listItems = [
	{
		title: accordionTitles.about,
		items: [
			'What is myTamilDate?',
			"How's myTamilDate different from other dating services?",
			'Have people found love on myTamilDate?',
			'Do I have to be Tamil to use myTamilDate?',
			'Is myTamilDate available in my country?'
		]
	},
	{
		title: accordionTitles.gettingStarted,
		items: [
			// 'Logging in',
			'How can I quickly access myTamilDate from my phone like I do with other apps?'
		]
	},
	{
		title: accordionTitles.matching,
		items: [
			'Share your success story.',
			"How come I can't message someone?",
			'How do I view who liked me?',
			'Why did one of my matches disappear?'
			// "I'm not getting any matches.",
			// 'How do I block someone?',
			// 'How do I unmatch with someone?'
		]
	},
	{
		title: accordionTitles.browsing,
		items: [
			"I keep seeing people I've x'd.",
			"There aren't enough people in my area."
			// 'How can I filter the type of profiles shown to me?'
		]
	},
	{
		title: accordionTitles.profile,
		items: [
			// 'How do I change my phone number?',
			// 'How do I change my pictures?',
			'How do I edit my profile?',
			// 'Why was my account banned?',
			'I want to delete my account.'
		]
	},
	{
		title: accordionTitles.premium,
		items: [
			'What do I get with a Premium Membership?',
			'How can I upgrade my membership?',
			'How can I pay for my Premium Membership?',
			'Can I give someone a premium membership to myTamilDate as a gift?',
			// "I upgraded my account but it doesn't work.",
			// "I'm not able to upgrade my account.",
			// 'How can I cancel auto-renewal on my Premium Membership subscription?',
			'How can I cancel my Premium Membership subscription?',
			'I canceled my membership, but was charged again anyway.',
			"I'd like a refund."
		]
	},
	{
		title: accordionTitles.getInTouch,
		items: ['Contact us', 'Report Technical Issue']
	}
]

export const itemsDescription = {
	'What is myTamilDate?':
		"myTamilDate.com has been the most trusted dating community for single Tamils around the world for close to a decade! We're the premiere dating platform for diaspora Tamils and have the largest membership base in Canada, USA, UK & more.\n\n\n\n" +
		"Proven Success: We've been helping single Tamils meet, date and marry for years. On their own terms & their own timelines.\n\n" +
		"Meaningful Connections: No mindless swiping! View a person's profile in detail before making a decision to match or not.\n\n" +
		'Trust & Authenticity: We manually verify every profile in addition to requiring phone verification.\n\n' +
		"Flexible Platform: We're built for mobile first and enhanced for desktop too! The best part? You don't have to download yet another app.\n\n" +
		'Safety & Protection: We have a track record for creating a safe and trusted community, including your experience on the platform & the protection of your personal data.\n\n' +
		"Personalized Service: We care about your dating life like a good friend would! Need help with your profile? Need messaging tips & date ideas? We've got you covered!",
	"How's myTamilDate different from other dating services?":
		"We're the only dating community catering specifically to Tamil singles in a relevant & meaningful way. We're inclusive, anti-casteist, anti-colourism and empower you to take control of your love life, when and how it suits you! \n\n" +
		"Dating helps you meet a life partner who shares similar values and interests - cultural background, religion and language also play a big role. If you've decided that you want to meet someone Tamil, you're most likely to have success here vs. anywhere else.\n\n",
	'Have people found love on myTamilDate?':
		'Absolutely! Countless Tamil singles have met, dated and married their soulmates through myTamilDate and continue to do so all the time. Here are some of their stories.',
	'Do I have to be Tamil to use myTamilDate?':
		"This is an inclusive community and everyone is welcome! myTamilDate.com makes it easy to meet Tamil singles specifically, since mainstream dating apps put everyone into a general Asian/Indian bucket. If you're looking to meet some awesome Tamil singles, this is the place to be!",
	'Is myTamilDate available in my country?':
		"You can access myTamilDate.com from anywhere in the world. Your country's local rules and laws around internet use and access will apply.",
	'Logging in':
		"I can't login.\n\n" +
		"I'm not receiving my SMS verification code: SAM\n\n" +
		"I'm unable to login with my Facebook: SAM",
	'How can I quickly access myTamilDate from my phone like I do with other apps?':
		'Android: How to add to Home Screen \n\n' +
		'Visit mytamildate.com in your browser.\n\n' +
		'In the browser bar, tap on the "home" icon with a plus (+) icon inside it.\n\n' +
		'A banner should appear on your device. Click on "Add to Home screen".\n\n' +
		'If you choose not to add it to your Home screen at this point, you can do so later using the Add to Home screen icon in the main Chrome menu.\n\n' +
		"Regardless of which browser you are using, when you choose to add the app to your Home screen, you'll see it appear along with a short title, in the same way that regular apps like Instagram do.\n\n" +
		'Tapping this icon will now launch the app fullscreen and give you the best user experience\n\n' +
		'\n\n' +
		'Note: If your device does not block pop-ups, then you may be prompted by a pop-up banner instead asking you to "Add to Home screen". You must be in regular browsing mode and not private/incognito.\n\n' +
		'\n\n' +
		'iOS: How to add to Home Screen \n\n' +
		'Visit mytamildate.com in your browser.\n\n' +
		'Within Safari, press the Share icon and then “Add to Home Screen.”\n\n' +
		'Tapping this icon will now launch the app fullscreen and give you the best user experience\n\n',
	'Share your success story.':
		"We love hearing about your success! Many couples have met and married through myTamilDate and some of them have taken advantage of the free photo/video shoot we offer myTamilDate couples. Are you interested? Please complete this form and we'll be in touch!",
	"How come I can't message someone?":
		"Messaging is one of myTamilDate's Premium Membership benefits.\n\n" +
		"If you're already a premium member, there are a few reasons why you might not be able to message someone: you don't have a mutual match which opens up messaging or they've blocked you if you've already matched.\n\n",
	'How do I view who liked me?':
		"You can see who tapped heart (liked) your profile by clicking on the message icon at the bottom of your screen and clicking on the ‘Likes' tab. Seeing who liked you is available to Premium Members only. Upgrade your account now to unlock this feature.",
	'Why did one of my matches disappear?':
		'There could be various reasons for this: 1) The user might have paused or deleted their account Or 2) They have chosen to unmatch with you Or 3) They have blocked your profile',
	"I keep seeing people I've x'd.":
		"We try to show you new profiles which fit your criteria and sometimes repeat profiles in case you've changed your mind! You can also try expanding your filters to see more unique profiles.",
	"There aren't enough people in my area.":
		"Our goal is to have a broad base of members from key countries with large Tamil communities and new members join daily, but in the meantime, we recommend giving someone from another location a chance. It has worked for others! Here's the story of a couple from Paris and Toronto who met on myTamilDate and got engaged.",
	'How do I change my pictures?':
		"Click on your profile icon at the bottom right hand of your screen on mobile (top left on desktop) > select the image you'd like to replace and follow the steps",
	'How do I edit my profile?':
		'Click on your profile icon at the bottom right hand of your screen on mobile (top left on desktop) > from here you can edit your prompts and other details ',
	'I want to delete my account.':
		"You're in full control of your account! There are 2 ways you can handle this:\n\n" +
		'Just need a break from dating but have plans to come back? Pause your account. This will ensure that all of your details are saved, but your profile will be hidden and will not be visible to others until you unpause your account.\n\n' +
		'You have no plans to ever use your myTamilDate account in the future? Delete your account permanently. This will wipe out all of your details and you will no longer be able to recover your profile details or messages. If you choose to come back, you will need to register for a brand new account.',
	'What do I get with a Premium Membership?':
		"By becoming a Premium Member you will increase your chances of meeting that special someone dramatically. The following features are only available to myTamilDate's Premium Members:\n\n" +
		'Send & receive unlimited messages\n\n' +
		'See who liked you\n\n' +
		"Send & receive special requests to members you haven't matched with and stand out\n\n" +
		"Access all premium filters to help you zone in on the exact matches you're looking for\n\n" +
		"Undo matches you've passed on if you change your mind\n\n",
	'How can I upgrade my membership?':
		'There are various call-outs within myTamilDate to help make it easy for you to upgrade. One of those sections is your own profile page which you can access by clicking on your icon at the bottom right of your screen. You can also upgrade from this link.',
	'How can I pay for my Premium Membership?':
		"myTamilDate's platform uses Stripe's secure and globally trusted payment system to process all subscriptions. All major credit cards are accepted for payments.",
	'Can I give someone a premium membership to myTamilDate as a gift?':
		'For sure! Giving someone the gift of love is a thoughtful and unique gift! You can buy a myTamilDate gift certificate at www.tcmrkt.com.',
	'How can I cancel my Premium Membership subscription?':
		"Click on your profile icon at the bottom right hand of your screen on mobile (top left on desktop) > scroll to the bottom > select ‘Account Settings' > select ‘Membership' and follow the instructions found under ‘Cancel Membership'",
	'I canceled my membership, but was charged again anyway.':
		"Sometimes tech products can get buggy and are difficult to anticipate, but we'll always fix any errors that we're responsible for. Please submit a request here.",
	"I'd like a refund.":
		"We like commitment and have a strict no refund policy, which we advise you of at the time of your Premium Membership subscription. We'll consider exceptions on a case by case basis. Please submit a request here.",
	'Contact us': (
		<>
			Have a question about myTamilDate? Contact us at{' '}
			<span className="link">hello@mytamildate.com</span>
		</>
	),
	'Report Technical Issue': (
		<>
			Have any technical issues? Please fill in the following form:{' '}
			<a
				href="https://forms.gle/qzAXWJW2NnVKECUL8"
				target="_blank"
				rel="noopener noreferrer"
				className="link"
			>
				https://forms.gle/qzAXWJW2NnVKECUL8
			</a>
		</>
	)
}
