import {
	RESEND_CONFIRMATION_CODE,
	SEND_CONFIRMATION_CODE,
	SIGN_IN
} from 'modules/auth/signIn/store/constants'
import {
	confirmationCreate,
	confirmationResend,
	facebookAuth,
	signIn
} from 'modules/auth/signIn/services'
import { _get } from 'utils/lodash'
import { setAuthorizationData } from 'utils/authorize'
import { AUTH_INIT } from 'modules/auth/store/constants'
import { attachTokenToMessengerApiInstance } from 'libs/messenger'
import { routesByName } from 'constants/routes'
import { history } from 'store/store'
import { toggleLoader } from 'modules/app/store/actions'

export const createConfirmationCodeActions = ({
	identity,
	recaptchaToken
}) => async dispatch => {
	try {
		const response = await confirmationCreate({ identity, recaptchaToken })

		const expiresIn = _get(response, 'result.expiresIn', '')

		dispatch({
			type: SEND_CONFIRMATION_CODE,
			payload: { identity, expiresIn }
		})
	} catch (e) {
		console.dir(e)
		throw e
	}
}

export const resendConfirmationCodeActions = ({ recaptchaToken }) => async (
	dispatch,
	getState
) => {
	try {
		const {
			signIn: { identity }
		} = getState()

		const response = await confirmationResend({ identity, recaptchaToken })

		const expiresIn = _get(response, 'result.expiresIn', '')

		dispatch({ type: RESEND_CONFIRMATION_CODE, payload: { expiresIn } })
	} catch (e) {
		console.dir(e)
		throw e
	}
}

export const signInActions = ({ code }) => async (dispatch, getState) => {
	try {
		const {
			signIn: { identity }
		} = getState()

		const response = await signIn({ code, identity })

		setAuthorizationData(response)
		attachTokenToMessengerApiInstance()

		dispatch({ type: AUTH_INIT, payload: { logined: true } })
		dispatch({ type: SIGN_IN })
		history.push(routesByName.main.discovery.root)
	} catch (e) {
		console.dir(e)
		throw e
	}
}

export const signInWithFacebook = data => async dispatch => {
	try {
		dispatch(toggleLoader())
		const { accessToken } = data
		if (!accessToken) return

		const response = await facebookAuth(accessToken)

		setAuthorizationData(response)
		attachTokenToMessengerApiInstance()

		dispatch({ type: AUTH_INIT, payload: { logined: true } })
		dispatch({ type: SIGN_IN })
		history.push(routesByName.root)
	} catch (e) {
		console.dir(e)
		throw e
	} finally {
		dispatch(toggleLoader())
	}
}
