import React, { memo } from 'react'
import classes from './MobilePreviewProfile.module.scss'
import UserPreviewAvatar from 'modules/profile/components/UserPreviewAvatar'
import ColoredChip from 'components/ui/ColoredChip'
import BaseCard from 'components/ui/controls/BaseCard'
import AboutUserCard from 'modules/profile/components/AboutUserCard/AboutUserCard'
import MenuElement from 'components/ui/MenuElement/MenuElement'
import FeedbackDialog from 'components/ui/Dialogs/FeedbackDialog'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'

const cardClasses = {
	root: classes.characteristicCard,
	label: classes.characteristicCardLabel,
	icon: classes.characteristicCardIcon
}
const aboutUserCardClasses = { root: classes.aboutUserCard }

// const horoscope = 11
const familyPlans = 8
const growth = 6
const education = 4
const languages = 7
const religion = 3
const career = 5
const location = 2
const kids = 2
const drink = 1
const age = 0
const smoke = 0
const avatar = 0

const preferNotToSay = 'Prefer not to say'

const MobilePreviewProfile = ({
	personalities = [],
	photos = [],
	userCharacteristics = [],
	restUserCharacteristics = [],
	answersAndQuestions = [],
	onClickBlock,
	onClickReport,
	onClickConfirmReport,
	onClickUndo,
	onClickWeMeet,
	onClickUnmatch,
	onClickConfirmUnmatch,
	onClickSendMessage,
	firstName,
	userId,
	reportModal,
	unmatchModal,
	scrollRef,
	onClickUnblock,
	isCurrentUserBlocked
}) => {
	return (
		<BaseScrollbars innerRef={scrollRef}>
			<div className={classes.root}>
				<UserPreviewAvatar
					className="mb-1"
					avatar={photos[avatar]}
					firstName={firstName}
					career={restUserCharacteristics[career]?.value}
					location={restUserCharacteristics[location]?.value}
					age={restUserCharacteristics[age]?.value}
					onClickBlock={onClickBlock}
					onClickUnblock={onClickUnblock}
					onClickReport={onClickReport}
					onClickUndo={onClickUndo}
					onClickSendMessage={onClickSendMessage}
					onClickWeMeet={onClickWeMeet}
					onClickUnmatch={onClickUnmatch}
					userId={userId}
					isCurrentUserBlocked={isCurrentUserBlocked}
				/>
				<div className={classes.characteristicContainer}>
					{restUserCharacteristics[growth]?.value &&
					restUserCharacteristics[growth]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[growth]?.icon}
							label={restUserCharacteristics[growth]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}

					{restUserCharacteristics[education]?.value &&
					restUserCharacteristics[education]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[education]?.icon}
							label={restUserCharacteristics[education]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
					{restUserCharacteristics[languages]?.value &&
					restUserCharacteristics[languages]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[languages]?.icon}
							label={restUserCharacteristics[languages]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
					{/*{restUserCharacteristics[horoscope]?.value &&*/}
					{/*restUserCharacteristics[horoscope]?.value !== preferNotToSay ? (*/}
					{/*	<MenuElement*/}
					{/*		icon={restUserCharacteristics[horoscope]?.icon}*/}
					{/*		label={restUserCharacteristics[horoscope]?.value}*/}
					{/*		classes={cardClasses}*/}
					{/*		justify="center"*/}
					{/*	/>*/}
					{/*) : null}*/}
					{restUserCharacteristics[religion]?.value &&
					restUserCharacteristics[religion]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[religion]?.icon}
							label={restUserCharacteristics[religion]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
				</div>
				{personalities?.length ? (
					<BaseCard className={classes.personalitiesContainer}>
						{personalities?.map(({ label }, index) => (
							<ColoredChip
								key={`colored-chip-${index}`}
								label={label}
								className={classes.coloredChip}
							/>
						))}
					</BaseCard>
				) : null}
				<div className={classes.characteristicContainer}>
					{userCharacteristics[smoke]?.value &&
					userCharacteristics[smoke]?.value !== preferNotToSay ? (
						<MenuElement
							icon={userCharacteristics[smoke]?.icon}
							label={userCharacteristics[smoke]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
					{userCharacteristics[drink]?.value &&
					userCharacteristics[drink]?.value !== preferNotToSay ? (
						<MenuElement
							icon={userCharacteristics[drink]?.icon}
							label={userCharacteristics[drink]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}

					{userCharacteristics[kids]?.value &&
					userCharacteristics[kids]?.value !== preferNotToSay ? (
						<MenuElement
							icon={userCharacteristics[kids]?.icon}
							label={userCharacteristics[kids]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
					{restUserCharacteristics[familyPlans]?.value &&
					restUserCharacteristics[familyPlans]?.value !== preferNotToSay ? (
						<MenuElement
							icon={restUserCharacteristics[familyPlans]?.icon}
							label={restUserCharacteristics[familyPlans]?.value}
							classes={cardClasses}
							justify="center"
						/>
					) : null}
				</div>
				{answersAndQuestions?.map(({ answer, question, avatar }, index) => (
					<AboutUserCard
						classes={aboutUserCardClasses}
						key={question + answer + index}
						question={question}
						answer={answer}
						avatar={avatar}
					/>
				))}
				<FeedbackDialog
					open={reportModal?.state}
					onClose={reportModal?.turnOff}
					onClick={onClickConfirmReport}
				/>
				<FeedbackDialog
					open={unmatchModal?.state}
					onClose={unmatchModal?.turnOff}
					onClick={onClickConfirmUnmatch}
					type="unmatch"
				/>
			</div>
		</BaseScrollbars>
	)
}

export default memo(MobilePreviewProfile)
