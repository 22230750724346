import React, { useCallback } from 'react'
import BasePhoneInput from 'components/wrappers/ui/BasePhoneInput'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import parsePhoneNumber from 'libphonenumber-js'

const FormikFieldPhoneInput = ({ name, ...props }) => {
	const [, meta, helpers] = useField(name)

	const handleChange = useCallback(
		({ target: { value } }, country) => {
			const phoneNumber = parsePhoneNumber(value, country)
			helpers.setValue({
				international: phoneNumber ? phoneNumber.formatInternational() : '',
				number: value,
				country
			})
		},
		[helpers]
	)

	const handleChangeCountry = useCallback(
		country => {
			helpers.setValue({ ...meta.value, country })
		},
		[helpers, meta]
	)

	return (
		<BaseFormikWrapper
			component={BasePhoneInput}
			onChange={handleChange}
			onChangeCountry={handleChangeCountry}
			name={name}
			{...props}
		/>
	)
}

FormikFieldPhoneInput.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldPhoneInput
