import React from 'react'
import clsx from 'clsx'
import classes from './ConversationList.module.scss'
import Typography from 'components/ui/Typography'
import ConversationItem from './ConversationItem'
import { _isEmpty } from 'utils/lodash.js'
import TextPlaceholder from 'components/ui/layout/TextPlaceholder'
import useUpgradeNotification from 'hooks/useUpgradeNotification'

const ConversationList = ({
	list = [],
	onClickConversation: handleClickConversation,
	classes: outerClasses = {}
}) => {
	const upgradeNotification = useUpgradeNotification()

	return (
		<div className={clsx(classes.root, outerClasses.root)}>
			<Typography className={classes.title}>Messages</Typography>

			{_isEmpty(list) ? (
				<TextPlaceholder text="You don't have any chats yet." />
			) : (
				<div className={classes.container}>
					{list?.map((conversation, index) => (
						<ConversationItem
							key={`match-${index}`}
							withHint
							onClick={handleClickConversation}
							smoothMessage={!upgradeNotification.isSubscribe}
							{...conversation}
						/>
					))}
					{upgradeNotification.carouselBanner}
				</div>
			)}
		</div>
	)
}

export default ConversationList
