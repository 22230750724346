import React from 'react'
import classes from './LeftSidebar.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import Typography from 'components/ui/Typography'
import useUpgradeNotification from 'hooks/useUpgradeNotification'

const LastMatchLeftSidebar = () => {
	const upgradeNotification = useUpgradeNotification()

	return (
		<div className={classes.root}>
			<SpriteIcon name="loupe-with-heart" className={classes.loupeIcon} />
			<Typography className={classes.title}>Find your matches here</Typography>
			{upgradeNotification.carouselBanner}
		</div>
	)
}

export default LastMatchLeftSidebar
