import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import BaseCodeInput from 'components/wrappers/ui/BaseCodeInput'
import PropTypes from 'prop-types'

const FormikFieldCodeInput = props => {
	return <BaseFormikWrapper component={BaseCodeInput} {...props} />
}

FormikFieldCodeInput.propTypes = {
	name: PropTypes.string.isRequired
}

FormikFieldCodeInput.defaultProps = {
	name: ''
}

export default FormikFieldCodeInput
