import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useHistory from 'hooks/useHistory'
import { getMeAvatar } from 'modules/profile/store/selectors'
import { getUserById } from 'modules/users/selectors'
import useUpgradeNotification from 'hooks/useUpgradeNotification'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import {
	clearMessengerMeta,
	setUserForCreateConversation
} from 'modules/conversation/store/actions'
import { routesByName } from 'constants/routes'
import LastMatchCard from 'modules/notifications/matches/lastMatch/components/LastMatchCard'
import BaseDialog from '../../../wrappers/ui/BaseDialog'
import classes from './MatchDialog.module.scss'
import useConnectToEchoServer from 'libs/echo/useConnectToEchoServer'
import { NEW_MATCH } from 'libs/echo/events'
import channelsByName from 'libs/echo/channels'
import { setPersonForMatch } from 'modules/notifications/matches/store/actions'
import { getPersonForMatch } from 'modules/notifications/matches/store/selectors'
import useFlagManager from 'hooks/useFlagManager'
import { _isEmpty } from 'utils/lodash'
import * as Sentry from '@sentry/browser'

const MatchDialog = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const myAvatar = useSelector(getMeAvatar)
	const upgradeNotification = useUpgradeNotification()
	const myId = useSelector(({ profile }) => profile.user.id)
	const personForMatchId = useSelector(getPersonForMatch)
	const personForMatch = useSelector(getUserById(personForMatchId))
	const matchDialog = useFlagManager()
	const format = useDetermineScreenFormat(breakpointsUp.sm)

	const handleNewMatch = useCallback(
		async data => {
			try {
				await dispatch(setPersonForMatch(data))
				matchDialog.turnIn()
			} catch (e) {
				Sentry.captureException(e)
			}
		},
		[dispatch, matchDialog]
	)

	useConnectToEchoServer({
		channelName: channelsByName.matchesById(myId),
		events: {
			[NEW_MATCH]: handleNewMatch
		}
	})

	const handleUpgrade = useCallback(() => {
		history.push(routesByName.upgrade.root)
	}, [history])

	const handleClickSendMessage = useCallback(async () => {
		matchDialog.turnOff()
		if (!upgradeNotification.isSubscribe) return handleUpgrade()

		try {
			dispatch(clearMessengerMeta())
			dispatch(setUserForCreateConversation(personForMatchId))

			if (format === screenFormat.mobile) {
				history.push(
					routesByName.main.notifications.matches.mobileConversationCreate
				)
			} else {
				history.push(routesByName.main.notifications.matches.conversationCreate)
			}
		} catch (e) {
			Sentry.captureException(e)
		}
	}, [
		dispatch,
		format,
		matchDialog,
		history,
		handleUpgrade,
		upgradeNotification.isSubscribe,
		personForMatchId
	])

	const handleClickKeepBrowsing = useCallback(() => {
		matchDialog.turnOff()
	}, [matchDialog])

	return (
		<BaseDialog
			open={matchDialog.state && !_isEmpty(personForMatch)}
			classesPaper={
				format === screenFormat.desktop ? classes.paper : classes.paperMobile
			}
			fullScreen={format === screenFormat.mobile}
			onClose={matchDialog.turnOff}
		>
			<div className={classes.root}>
				<div className={classes.content}>
					<LastMatchCard
						myAvatar={myAvatar?.large}
						matchAvatar={
							personForMatch?.photos?.find(({ type }) => type === 'avatar')
								?.large
						}
						onClickKeepBrowsing={handleClickKeepBrowsing}
						onClickSendMessage={handleClickSendMessage}
					/>
				</div>
				{upgradeNotification.unlockFeatures.component}
			</div>
		</BaseDialog>
	)
}

export default memo(MatchDialog)
