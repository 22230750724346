import React from 'react'
import { Form } from 'formik'
import Button from 'components/ui/Button'
import classes from './View.module.scss'
import Heading from 'components/ui/Typography/Heading'
import Typography from 'components/ui/Typography'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'
import { screenFormat } from 'constants/screenFormat'
import { Link } from 'react-router-dom'
import { routesByName } from 'constants/routes'

const View = ({
	title = '',
	description = '',
	format,
	Input,
	secondaryActions,
	containerClass,
	rootClass,
	actionsClass
}) => {
	return (
		<Form className={clsx(classes.root, rootClass)}>
			<div className={clsx(classes.container, containerClass)}>
				<Heading variant="heading-4" className={clsx(classes.title, 'mb-2')}>
					{title}
				</Heading>
				{description ? (
					<Typography variant="body-1" className={classes.description}>
						{description}
					</Typography>
				) : null}
				<Input />
				<div className={classes.login}>
					<Typography className={clsx(classes.loginText)}>
						Already have an account?
						<Link
							className={clsx(classes.loginText, classes.loginLink)}
							to={routesByName.signIn.root}
						>
							{' '}
							Sign in here
						</Link>
					</Typography>
				</div>
			</div>

			<div className={clsx(classes.buttonsSection, actionsClass)}>
				{secondaryActions}

				<Button
					type="submit"
					size="xl"
					shape="rounded"
					className={classes.btn}
					iconPlacement="end"
					icon={<SpriteIcon name="arrow-next2" size="md" className="ml-2" />}
				>
					{format === screenFormat.desktop ? 'Next' : null}
				</Button>
			</div>
		</Form>
	)
}
export default View
