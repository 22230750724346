import { useCallback, useContext, useRef } from 'react'
import _useEffect from '@rznv/components/src/ChatBox/hooks/_useEffect'
// import Echo from 'api/Echo/index.js'
import { EchoContext } from './Provider.js'

const useConnectToEchoServer = ({ channelName, events = {} }) => {
	const channel = useRef(null)
	const Echo = useContext(EchoContext)
	const instance = useRef(null)
	const channelNameRef = useRef(null)

	const connectToEcho = useCallback(
		async channelName => {
			try {
				instance.current = await Echo.connect()
				channel.current = await instance.current.channel(channelName)
				channelNameRef.current = channelName
				Object.entries(events).forEach(([name, callback]) =>
					channel.current.listen(name, callback)
				)
			} catch (e) {
				console.dir(e)
			}
		},
		[Echo, events]
	)

	_useEffect(() => {
		if (channelName) {
			connectToEcho(channelName)
		}
		return () => {
			if (channelNameRef.current) {
				instance.current.leave(channelNameRef.current)
			}
		}
	}, [channelName])
}

export default useConnectToEchoServer
