import React, {memo, useCallback, useState} from 'react'
import classes from 'components/ui/Dialogs/DeleteReasonsDialog/DeleteReasonsDialog.module.scss'
import BaseSwitch from 'components/ui/controls/BaseSwitch'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Button from 'components/ui/Button'
import clsx from 'clsx'

const labelClasses = {
	labelRoot: classes.labelRoot
}

const DeleteReasonsDialog = ({
	open = false,
	onClose,
	onConfirm,
	action = 'pause'
}) => {
	const [activeSwitch, setActiveSwitch] = useState(null)

	const handleChange = useCallback(
		event => {
			setActiveSwitch(Number(event.target.value))
		},
		[]
	)

	const reasons = {
		'I met somebody on myTamilDate!': 4,
		'I got too many messages from other members': 3,
		'I met somebody elsewhere': 5,
		'It`s too expensive': 2,
		'The site is hard to use': 1,
		'There aren`t enough people in my area': 0,
		'Other': 6
	};

	return (
		<>
			<BaseDialog open={open} maxWidth="lg" onClose={onClose}>
				<div className={classes.root}>
					<div className={classes.modal}>
						<div className={classes.modalHeader}>
							<div className={classes.modalTitle}>
								Sorry we didn’t have what you were looking for this time!
							</div>
							<div className={classes.modalSubtitle}>
								We'd love to get your feedback so we can improve.
							</div>
						</div>
						{Object.keys(reasons)?.map((reason, index) => (
							<div className={classes.reason} key={reason + index}>
								<BaseSwitch
									label={reason}
									onChange={handleChange}
									checked={activeSwitch === reasons[reason]}
									labelPlacement="start"
									spaceBetween={true}
									classes={labelClasses}
									value={reasons[reason]}
								/>
							</div>
						))}
					</div>

					<Button
						shape="rounded"
						onClick={() => onConfirm(activeSwitch)}
						data-reason={activeSwitch}
						className={classes.btn}
						disabled={activeSwitch===null}
					>
						{action === 'pause' ? 'Pause Account' : 'Delete Account'}
					</Button>
					<Button
						onClick={onClose}
						shape="rounded"
						className={clsx(classes.btn, classes.cancelBtn)}
					>
						Cancel
					</Button>
				</div>
			</BaseDialog>
		</>
	)
}

export default memo(DeleteReasonsDialog)
