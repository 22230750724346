import React, { useCallback, useMemo, useRef } from 'react'
import classes from './ProfileDesktopView.module.scss'
import MobileEditProfile from 'modules/profile/ProfileMobileView/Edit'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import DesktopPreviewProfile from 'modules/profile/ProfileDesktopView/Preview'
import clsx from 'clsx'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import ProfileButton from 'modules/profile/components/ProfileButton'
import SpriteIcon from 'components/ui/SpriteIcon'

const editContentStyle = {
	height: 'inherit'
}
const previewContentStyle = {
	height: '600px'
}

const ProfileDesktopView = ({
	className,
	onClickEditAboutMe = () => null,
	onClickUpdatePersonality = () => null,
	onClickUserCharacteristic = () => null,
	onClickMenuBar = () => null,
	onClickHelp = () => null,
	onSavePhotos = () => null,
	onChangePhotos = () => null,
	userCharacteristics,
	selectedTab,
	personalities,
	firstName,
	lastName,
	photos,
	restUserCharacteristics,
	answersAndQuestions = []
}) => {
	const commonProps = {
		userCharacteristics,
		restUserCharacteristics,
		personalities,
		answersAndQuestions,
		firstName,
		lastName,
		photos
	}

	const scrollRef = useRef()

	const renderPreviewTab = useMemo(() => {
		return selectedTab === 'Preview' ? (
			<DesktopPreviewProfile
				className={classes.preview}
				{...commonProps}
				scrollRef={scrollRef}
			/>
		) : null
	}, [selectedTab, commonProps])

	const renderEditTab = useMemo(() => {
		return selectedTab === 'Edit' ? (
			<MobileEditProfile
				onClickEditAboutMe={onClickEditAboutMe}
				onClickUpdatePersonality={onClickUpdatePersonality}
				onClickUserCharacteristic={onClickUserCharacteristic}
				onClickMenuBar={onClickMenuBar}
				onChangePhotos={onChangePhotos}
				onSavePhotos={onSavePhotos}
				onClickHelp={onClickHelp}
				{...commonProps}
			/>
		) : null
	}, [
		onSavePhotos,
		onChangePhotos,
		commonProps,
		onClickEditAboutMe,
		onClickMenuBar,
		onClickUpdatePersonality,
		onClickUserCharacteristic,
		onClickHelp,
		selectedTab
	])
	const history = useHistory()

	const handleClickBack = useCallback(() => history.goBack(), [history])

	const handleChangeTab = useCallback(
		value => {
			if (value === 'Edit') {
				history.push(routesByName.main.profile.edit)
			} else {
				history.push(routesByName.main.profile.preview)
			}
		},
		[history]
	)

	return (
		<div className={clsx(classes.root, className)}>
			<SpriteIcon
				name="arrow-back"
				className={classes.backIcon}
				onClick={handleClickBack}
			/>

			{selectedTab === 'Edit' ? (
				<div
					className={classes.content}
					style={
						selectedTab === 'Edit' ? editContentStyle : previewContentStyle
					}
				>
					<BaseScrollbars>{renderEditTab}</BaseScrollbars>
				</div>
			) : null}

			{renderPreviewTab}

			<div className={classes.navigation}>
				<ProfileButton
					className="mb-1"
					variant={selectedTab === 'Edit' ? 'primary' : 'gray'}
					onClick={handleChangeTab}
					name="Edit"
				>
					Edit
				</ProfileButton>
				<ProfileButton
					className="mb-1"
					variant={selectedTab === 'Preview' ? 'primary' : 'gray'}
					onClick={handleChangeTab}
					name="Preview"
				>
					Preview
				</ProfileButton>
			</div>
		</div>
	)
}

export default ProfileDesktopView
