import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { commonWhite, primaryColor } from 'constants/colors'

export const Accordion = withStyles({
	root: {
		border: '0px',
		boxShadow: 'none',
		width: '100%',
		// '&:not(:last-child)': {
		//   borderBottom: 0,
		// },
		// '&:before': {
		//   display: 'none',
		// },
		'&$expanded': {
			margin: 'auto'
		}
	},
	expanded: {}
})(MuiAccordion)

export const AccordionSummary = withStyles({
	root: {
		backgroundColor: commonWhite,
		borderBottom: `1px solid ${primaryColor}`,
		marginBottom: -1,
		minHeight: 56,
		maxHeight: 56,
		'&$expanded': {
			minHeight: 56,
			maxHeight: 56
		}
	},
	// content: {
	//   '&$expanded': {
	//     margin: '12px 0',
	//   },
	// },
	expanded: {}
})(MuiAccordionSummary)

export const AccordionDetails = withStyles(theme => ({
	root: {
		padding: '20px'
	}
}))(MuiAccordionDetails)

const BaseAccordion = ({
	children,
	header,
	expanded,
	name,
	onToggle,
	...props
}) => {
	const handleToggle = useCallback(
		(event, isExpanded) => {
			if (name) {
				onToggle(name, event, isExpanded)
			} else onToggle(event, isExpanded)
		},
		[name, onToggle]
	)

	return (
		<Accordion onChange={handleToggle} {...props}>
			<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
				{header}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	)
}

BaseAccordion.propTypes = {
	onChange: PropTypes.func,
	header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	expanded: PropTypes.bool,
	name: PropTypes.string
}
BaseAccordion.defaultProps = {
	onChange: () => null,
	expanded: false,
	name: ''
}

export default BaseAccordion
