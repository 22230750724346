import React, { memo } from 'react'
import classes from './MobileEditProfile.module.scss'
import BaseSelectImages from 'components/ui/controls/BaseSelectImages'
import BaseCard from 'components/ui/controls/BaseCard'
import AboutMe from 'modules/profile/components/AboutMe'
import Personalities from 'modules/profile/components/Personalities/Personalities.js'
import MenuElement from 'components/ui/MenuElement'
import BaseAccordion from 'components/ui/controls/BaseAccordion/BaseAccordion.js'

const accordionClasses = { root: `mt-3 mb-3` }
const menuCardClasses = { root: classes.menuCard }

const photoTooltip = {
	title: '',
	text:
		'Any changes made to the photos section will be sent for Admin review and approval.  Your account will be in a pending status until it gets approved.  You should receive an update within 24 hours.  If not, please reach out to hello@mytamildate.com.  Thank you for your patience!'
}

const answersTooltip = {
	title: '',
	text:
		'Any changes made to the "About Me" section will be sent for Admin review and approval.  Your account will be in a pending status until it gets approved.  You should receive an update within 24 hours.  If not, please reach out to hello@mytamildate.com.  Thank you for your patience!'
}

const MobileEditProfile = ({
	onClickEditAboutMe,
	onClickUpdatePersonality,
	onClickUserCharacteristic,
	personalities,
	userCharacteristics = [],
	restUserCharacteristics = [],
	onClickMenuBar,
	answersAndQuestions = [],
	onChangePhotos = () => null,
	onSavePhotos = () => null,
	photos = []
}) => {
	return (
		<div className={classes.root}>
			<BaseSelectImages
				variant="secondary"
				name="photos"
				onSaveCropper={onSavePhotos}
				onChange={onChangePhotos}
				value={photos}
				className={classes.selectImages}
				hasEditIcon
				tooltip={photoTooltip}
			/>

			<BaseCard className={classes.aboutMe}>
				<AboutMe
					onClickEdit={onClickEditAboutMe}
					options={answersAndQuestions}
					tooltip={answersTooltip}
				/>
			</BaseCard>

			<BaseCard className={classes.personalities}>
				<Personalities
					onClickUpdate={onClickUpdatePersonality}
					personalities={personalities}
				/>
			</BaseCard>

			{userCharacteristics?.map((data, index) => (
				<MenuElement
					{...data}
					onClick={onClickUserCharacteristic}
					key={`characteristic-${data.name}-${index}`}
				/>
			))}
			<BaseAccordion header="More about me" classes={accordionClasses}>
				<div>
					{restUserCharacteristics?.map((data, index) => (
						<MenuElement
							{...data}
							onClick={onClickUserCharacteristic}
							key={`characteristic-${data.name}-${index}`}
						/>
					))}
				</div>
			</BaseAccordion>
			<div className={classes.menuCardContainer}>
				<MenuElement
					label="Preferences"
					icon="filters"
					classes={menuCardClasses}
					onClick={onClickMenuBar}
					name="preferences"
					justify="center"
				/>
				<MenuElement
					label="Help & Support"
					icon="info"
					classes={menuCardClasses}
					onClick={onClickMenuBar}
					name="info"
					justify="center"
				/>
				<MenuElement
					label="Account settings"
					icon="settings"
					justify="center"
					classes={menuCardClasses}
					onClick={onClickMenuBar}
					name="settings"
				/>
			</div>
		</div>
	)
}

export default memo(MobileEditProfile)
