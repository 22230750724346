import React from 'react'
import PaymentMethodContainer from 'modules/subscriptionBilling/paymentMethod/PaymentMethodContainer'
import PaymentMethodView from 'modules/subscriptionBilling/paymentMethod/PaymentMethodView'

const PaymentMethodPage = ({ mode }) => {
	return (
		<PaymentMethodContainer mode={mode}>
			{PaymentMethodView}
		</PaymentMethodContainer>
	)
}

export default PaymentMethodPage
