import React from 'react'
import ClampLines from 'react-clamp-lines'

const BaseClampLines = ({
	buttons = false,
	innerElement = 'p',
	lines = 2,
	ellipsis = '...',
	...props
}) => {
	return (
		<ClampLines
			buttons={buttons}
			innerElement={innerElement}
			lines={lines}
			ellipsis={ellipsis}
			{...props}
		/>
	)
}

export default BaseClampLines
