import { useCallback } from 'react'
import { routesByName } from 'constants/routes.js'
import useHistory from 'hooks/useHistory.js'
import { useDispatch } from 'react-redux'
import errorToastr from 'libs/toastr/errorToastr.js'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import { screenFormat } from 'constants/screenFormat.js'
import { setCurrentConversation } from 'modules/conversation/store/actions.js'
import { matchConnected } from 'modules/notifications/matches/services'
import {
	getLastMatch,
	getMatches
} from 'modules/notifications/matches/store/actions'
import { getConversations } from 'modules/conversation/store/actions'

const LastMatchCreateContainer = ({ children }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const format = useDetermineScreenFormat(breakpointsUp.sm)

	const handlePushMessage = useCallback(
		async (conversation, mateId) => {
			try {
				await dispatch(setCurrentConversation(conversation.id))
				await matchConnected(mateId)
				await dispatch(getMatches())
				await dispatch(getLastMatch())
				await dispatch(getConversations())

				if (format === screenFormat.desktop) {
					history.replace(routesByName.main.lastMatch.conversation)
				} else {
					history.replace(routesByName.conversation.root)
				}
			} catch (e) {
				errorToastr(e.generalError)
			}
		},
		[dispatch, format, history]
	)

	const props = {
		onPushMessage: handlePushMessage
	}

	return children(props)
}

export default LastMatchCreateContainer
