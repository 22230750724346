import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './SignInWelcomeScreen.module.scss'
import Header from 'components/ui/layout/GuestHeader'
import { btnSize } from 'pages/Homepage/config'
import { btnNames } from './config'
import Heading from 'components/ui/Typography/Heading'
import Button from 'components/ui/Button'
import SpriteIcon from 'components/ui/SpriteIcon'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { blue, primaryColor } from 'constants/colors'
import PrivacyPolicy from 'components/ui/layout/PrivacyPolicy'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'
import useFacebookAuth from 'hooks/useFacebookAuth'
import MetaTags from 'react-meta-tags'

const SignInWelcomeScreen = () => {
	const format = useDetermineScreenFormat()
	const history = useHistory()
	const { login } = useFacebookAuth()

	const handleClickSignInByPhoneNumber = useCallback(() => {
		history.push(routesByName.signIn.withPhone)
	}, [history])

	return (
		<>
			<MetaTags>
				<title>myTamilDate | Sign In</title>
				<meta
					name="description"
					content="Login to your myTamilDate account to start chatting with Single Tamils."
				/>
				<meta name="keywords" content="my tamil date login" />
			</MetaTags>
			<div className={classes.root}>
				<Header variantLogo="light" classes={{ root: classes.header }} />
				<div className={classes.content}>
					<Heading
						variant="heading-3"
						area="slideTitle"
						className={classes.heading}
					>
						You didn't come this far,
						<br />
						to only come this far.
					</Heading>

					<div className={classes.actions}>
						<Button
							area="facebookBtn"
							shape="rounded"
							color="white"
							size="md"
							stretch
							shadow
							className="mb-1"
							{...btnSize[format]}
							onClick={login}
							icon={
								<SpriteIcon
									name="facebook-logo"
									color={primaryColor}
									size="sm"
									className="mr-2"
								/>
							}
						>
							{btnNames.loginWithFacebook}
						</Button>

						<Button
							shape="rounded"
							size="md"
							stretch
							shadow
							area="phoneBtn"
							onClick={handleClickSignInByPhoneNumber}
							className={classes.signInBtn}
							{...btnSize[format]}
						>
							{btnNames.loginWithPhone}
						</Button>

						{/*<NavLink className={classes.needHelp} to="/">*/}
						{/*	Need help?*/}
						{/*</NavLink>*/}
					</div>
				</div>
				<PrivacyPolicy linkColor={blue} className={classes.privacyPolicy} />
			</div>
		</>
	)
}

SignInWelcomeScreen.propTypes = {
	className: PropTypes.string
}

SignInWelcomeScreen.defaultProps = {
	className: ''
}

export default SignInWelcomeScreen
