import moment from 'moment'

export const isExpired = expiresIn => {
	if (!expiresIn) return true

	const then = moment(expiresIn)
	const now = moment()

	return then < now
}
