import React, { useCallback, useMemo, useState } from 'react'
import classes from './SignIn.module.scss'
import Heading from 'components/ui/Typography/Heading'
import { Form, Formik } from 'formik'
import yup from 'libs/yup'
import Typography from 'components/ui/Typography'
import FormikFieldPhoneInput from 'components/wrappers/formik/FormikFieldPhoneInput'
import FormikFieldInput from 'components/wrappers/formik/FormikFieldInput'
import clsx from 'clsx'
import Link from 'components/ui/Typography/Link'
import Button from 'components/ui/Button'
import SpriteIcon from 'components/ui/SpriteIcon'
import { screenFormat } from 'constants/screenFormat'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import Header from 'components/ui/layout/GuestHeader'
import View from 'modules/auth/signIn/View'
import { steps } from 'modules/auth/signUp/config'
import { useDispatch, useSelector } from 'react-redux'
import {
	createConfirmationCodeActions,
	resendConfirmationCodeActions,
	signInActions
} from 'modules/auth/signIn/store/actions'
import FormikFieldCodeInput from 'components/wrappers/formik/FormikFieldCodeInput'
import { breakpointsUp } from 'constants/breakpoints'
import { toggleLoader } from 'modules/app/store/actions'
import {
	getConfirmationExpire,
	getConfirmationIdentity
} from 'modules/auth/signIn/store/selectors'
import _useDidMount from 'hooks/useDidMount.js'
// import { isExpired } from 'modules/auth/utils.js'
import useFlagManager from 'hooks/useFlagManager'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'
import { _isEmpty } from 'utils/lodash'
import * as Sentry from '@sentry/browser'

const headerClasses = { root: classes.header }

const ResendCode = () => {
	const dispatch = useDispatch()
	const recaptcha = window.grecaptcha
	const [isDisabledResend, setIsDisabledResend] = useState(true)

	setTimeout(() => {
		setIsDisabledResend(false)
	}, 1000 * 60)

	const handleClick = useCallback(async () => {
		if (!isDisabledResend) {
			try {
				dispatch(toggleLoader())
				const recaptchaToken = await recaptcha.execute(
					process.env.REACT_APP_RECAPCTHA_SITE_KEY,
					{ action: 'resend_login_verification' }
				)
				if (recaptchaToken) {
					await dispatch(
						resendConfirmationCodeActions({
							recaptchaToken
						})
					)
				}
			} catch (e) {
				Sentry.captureException(e)
			} finally {
				dispatch(toggleLoader())
			}
		}
	}, [dispatch, isDisabledResend, recaptcha])

	return (
		<>
			<Link
				variant="secondary"
				className={clsx(classes.link, isDisabledResend && classes.disabled)}
				onClick={handleClick}
				underline
			>
				Resend Code
			</Link>
		</>
	)
}

export const EnterCodeDescription = ({ identity, className }) => (
	<Typography className={className}>
		Please enter the 4-digit code sent to you at{' '}
		<b>{useSelector(getConfirmationIdentity) || identity}</b> within{' '}
		<b>3 minutes</b> for security.
	</Typography>
)

const config = {
	enterCredentials: {
		validationSchema: yup.object().shape({
			phone: yup
				.mixed()
				.when('email', (email, schema) => (email ? schema : schema.phone())),
			email: yup.string().email('Please enter a valid email address.')
		}),
		initialValues: {
			phone: {
				number: '',
				country: 'US'
			},
			email: ''
		}
	},
	codeVerification: {
		title: steps[1].title,
		description: <EnterCodeDescription />,
		Input: props => (
			<FormikFieldCodeInput
				timeTillDate={useSelector(getConfirmationExpire)}
				className={classes.codeInput}
				name="code"
				{...props}
			/>
		),
		initialValues: {
			code: ''
		},
		validationSchema: yup.object().shape({
			code: yup
				.string()
				.min(4, 'Code must be at least 4 characters')
				.required('Code is required')
		}),
		secondaryActions: <ResendCode />
	}
}

const enterCredentials = 'enterCredentials'
const codeVerification = 'codeVerification'

const SignIn = () => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const [step, setStep] = useState(enterCredentials)
	const dispatch = useDispatch()
	// const expiresIn = useSelector(getConfirmationExpire)
	// const identity = useSelector(getConfirmationIdentity)
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	_useDidMount(() => {
		// Add reCaptcha
		const script = document.createElement('script')
		script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${process.env.REACT_APP_RECAPCTHA_SITE_KEY}`
		document.body.appendChild(script)
	})

	const handleSubmit = useCallback(
		async values => {
			try {
				dispatch(toggleLoader())
				if (step === enterCredentials) {
					const recaptcha = window.grecaptcha
					const recaptchaToken = await recaptcha.execute(
						process.env.REACT_APP_RECAPCTHA_SITE_KEY,
						{ action: 'login_verification' }
					)
					if (recaptchaToken) {
						await dispatch(
							createConfirmationCodeActions({
								identity: values.phone.international || values.email,
								recaptchaToken
							})
						)
					}
					setStep(codeVerification)
				}

				if (step === codeVerification) {
					await dispatch(signInActions(values))
				}
			} catch (e) {
				const errorMsg = !_isEmpty(e?.validation)
					? e.validationErrors.identity.join('\n')
					: e.getException
				const recaptchaError =
					e.response?.data.error.code === 'HTTP429'
						? 'Too many requests, please try again in a few minutes.'
						: e.response?.data.error.message
				setError(errorMsg || recaptchaError)
				infoDialog.turnIn()
			} finally {
				dispatch(toggleLoader())
			}
		},
		[dispatch, infoDialog, step]
	)

	const renderEnterCredentialsForm = useMemo(
		() => (
			<Form className={classes.form}>
				<div className={classes.content}>
					<div>
						<div className={classes.element}>
							<Heading variant="heading-4" className={classes.label}>
								Enter Phone Number
							</Heading>
							<FormikFieldPhoneInput
								name="phone"
								hasCountryCode
								showSuccessMessage
							/>
						</div>

						<div className={classes.delimiterWrapper}>
							<Typography
								className={clsx('my-4', classes.text, classes.separator)}
							>
								or
							</Typography>
						</div>

						<div className={classes.element}>
							<Heading variant="heading-4" className={classes.label}>
								Enter Your Email
							</Heading>
							<FormikFieldInput
								placeholder="example@example.com"
								className="mt-2"
								name="email"
								showSuccessMessage
							/>
						</div>
					</div>
				</div>

				<div className={classes.actions}>
					<Button
						type="submit"
						size="xl"
						shape="rounded"
						className={classes.btn}
						shadow
						iconPlacement="end"
						icon={<SpriteIcon name="arrow-next2" size="md" className="ml-2" />}
					>
						{format === screenFormat.desktop ? 'Next' : null}
					</Button>
				</div>
			</Form>
		),
		[format]
	)

	const renderCodeVerificationForm = useMemo(
		() => (
			<View
				format={format}
				containerClass={classes.codeVerificationContainer}
				rootClass={classes.codeVerificationRoot}
				actionsClass={classes.codeVerificationActions}
				{...config[codeVerification]}
			/>
		),
		[format]
	)

	return (
		<>
			<Formik
				initialValues={config[step].initialValues}
				validationSchema={config[step].validationSchema}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				<div className={classes.root}>
					<Header
						hasBack={format === screenFormat.mobile}
						hideLogoOnMobile
						classes={headerClasses}
						variantLogo="dark"
					/>
					{step === enterCredentials
						? renderEnterCredentialsForm
						: renderCodeVerificationForm}
				</div>
			</Formik>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.turnOff}
				type={'error'}
				title={'Error'}
				body={error}
			/>
		</>
	)
}

export default SignIn
