import React, { memo } from 'react'
import classes from './MatchItem.module.scss'
import Typography from 'components/ui/Typography'
import UserAvatar from 'components/ui/UserAvatart'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { getAvatarByUserId, getUserById } from 'modules/users/selectors'

const MatchItem = ({
	id = null,
	onClickMatchPerson = () => null,
	classes: outerClasses = {}
}) => {
	const user = useSelector(getUserById(id))
	const avatar = useSelector(getAvatarByUserId(id))

	return (
		<div
			data-id={id}
			className={clsx(classes.root, outerClasses.root)}
			onClick={onClickMatchPerson}
			role="presentation"
		>
			<UserAvatar src={avatar} className={classes.avatar} />

			<Typography className={classes.name}>
				{user.firstName || 'Loading...'}
			</Typography>
		</div>
	)
}

export default memo(MatchItem)
