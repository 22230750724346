import { useCallback, useMemo, useState } from 'react'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import { verifyChange, verifyCreate } from 'modules/auth/signUp/services'
import { toggleLoader } from 'modules/app/store/actions'
import { useDispatch } from 'react-redux'
import useFlagManager from 'hooks/useFlagManager'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import { getMeAction } from 'modules/profile/store/actions'
import { capitalize } from 'modules/profile/createProfile/utils'
import { useParams } from 'react-router-dom'
import { _isEmpty } from 'utils/lodash'
import _useDidMount from '../../../hooks/useDidMount'

const ChangeIdentityContainer = ({ children }) => {
	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile
	const dispatch = useDispatch()
	const history = useHistory()
	const showCode = useFlagManager(false)
	const [verifyData, setVerifyData] = useState()
	const { type } = useParams()
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const identityType = useMemo(() => (!showCode.state ? type : 'verify_code'), [
		showCode.state,
		type
	])

	_useDidMount(() => {
		// Add reCaptcha
		const script = document.createElement('script')
		script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${process.env.REACT_APP_RECAPCTHA_SITE_KEY}`
		document.body.appendChild(script)
	})

	const handleSubmit = useCallback(
		async values => {
			const identity =
				identityType === 'phone'
					? values[identityType]?.international
					: values[identityType]
			try {
				dispatch(toggleLoader())

				if (identityType === 'verify_code') {
					await verifyChange({
						...verifyData,
						code: values[identityType],
						expiresIn: undefined
					})

					await dispatch(getMeAction())
					setError('')
					infoDialog.turnIn()
					return null
					// return history.push(routesByName.main.settings.root)
				}
				const recaptcha = window.grecaptcha
				const recaptchaToken = await recaptcha.execute(
					process.env.REACT_APP_RECAPCTHA_SITE_KEY,
					{ action: 'init_phone_verification' }
				)
				if (recaptchaToken) {
					const { result } = await verifyCreate({
						identity,
						recaptchaToken
					})

					setVerifyData({ identity, ...result })
					showCode.turnIn()
				}
			} catch (e) {
				const errorMsg = !_isEmpty(e?.validation)
					? e.validationErrors.identity.join('\n')
					: e.getException
				const recaptchaError =
					e.response?.data.error.code === 'HTTP429'
						? 'Too many requests, please try again in a few minutes.'
						: e.response?.data.error.message
				setError(errorMsg || recaptchaError)
				infoDialog.turnIn()
			} finally {
				dispatch(toggleLoader())
			}
		},
		[identityType, dispatch, showCode, verifyData, infoDialog]
	)

	const props = useMemo(
		() => ({
			isMobile,
			identityType,
			verifyData,
			onSubmit: handleSubmit,
			infoDialog: {
				state: infoDialog.state,
				close: infoDialog.turnOff,
				error: error,
				type: error ? 'error' : 'success',
				body: error ? error : `${capitalize(type)} was changed successfully`,
				onConfirm: () => history.push(routesByName.main.settings.root)
			}
		}),
		[
			isMobile,
			identityType,
			verifyData,
			handleSubmit,
			infoDialog.state,
			infoDialog.turnOff,
			error,
			type,
			history
		]
	)

	return children(props)
}

export default ChangeIdentityContainer
