export const getUserById = id => ({
	users: {
		itemsMap: { [id]: user }
	}
}) => user || {}

export const getUserByIdWithBindConfig = id => ({
	users: {
		itemsMap: { [id]: user = {} }
	},
	profile: { config }
}) => ({
	...user,
	job: config.jobs.original[user.jobId],
	gender: config.genders.original[user.genderId],
	drink: config.drink.original[user.drinkId],
	wantGender: config.wantGenders.original[user.wantGenderId],
	growth: config.growth.original[user.growthId],
	haveKidId: config.haveKids.original[user.haveKidId],
	wantKidId: config.familyPlans.original[user.wantKidId],
	religion: config.religions.original[user.religionId],
	study: config.educations.original[user.studyId],
	languages: user.languages?.map(id => config.languages.original[id]),
	personalities: user.personalities?.map(
		id => config.personalities.original[id]
	)
})

export const getAvatarByUserId = (id, size = 'medium') => ({
	users: {
		itemsMap: { [id]: user }
	}
}) => user?.photos?.find(({ type }) => type === 'avatar')?.[size]

export const getPhotoByUserId = (id, size = 'medium') => ({
	users: {
		itemsMap: { [id]: user }
	}
}) => user.photos?.find(({ type }) => type === 'photo')?.[size]

const userSelectors = {
	getUserById,
	getUserByIdWithBindConfig,
	getAvatarByUserId,
	getPhotoByUserId
}

export default userSelectors
