import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getPaymentsInfo } from 'modules/subscriptionBilling/store/selectors'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'

const BillingContainer = ({ children }) => {
	const paymentsInfo = useSelector(getPaymentsInfo)
	const history = useHistory()

	const handleClickEditCard = useCallback(
		event => {
			const id = event.currentTarget.getAttribute('data-id')

			history.push(routesByName.main.settings.billing.editPaymentMethod, {
				pm: id
			})
		},
		[history]
	)

	const handleClickAddCard = useCallback(
		event => {
			history.push(routesByName.main.settings.billing.addPaymentMethod, {
				pm: null
			})
		},
		[history]
	)

	const props = useMemo(
		() => ({
			paymentsInfo,
			onClickEdit: handleClickEditCard,
			onClickAdd: handleClickAddCard
		}),
		[handleClickAddCard, handleClickEditCard, paymentsInfo]
	)
	return children(props)
}

export default BillingContainer
