import React, { useCallback, useMemo, useState } from 'react'
import Button from 'components/ui/Button'
import classes from './Homepage.module.scss'
import { BaseSlickCarousel } from 'components/wrappers'
import Typography from 'components/ui/Typography'
import Footer from 'components/ui/layout/Footer'
import Header from 'components/ui/layout/GuestHeader'
import Heading from 'components/ui/Typography/Heading'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'
import clsx from 'clsx'
import { slides, slidesForMobile, titles } from 'pages/Homepage/config'
import { Link } from 'react-router-dom'
import { secondaryColor } from 'constants/colors'
import PrivacyPolicy from 'components/ui/layout/PrivacyPolicy'
import './overrideClasses.scss'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import MetaTags from 'react-meta-tags'

const Homepage = () => {
	const [slide, setSlide] = useState(0)
	const history = useHistory()
	const isMobileFormat =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile
	const currentSlides = isMobileFormat ? slidesForMobile : slides

	const firstSlideOnMobile = isMobileFormat && slide === 0

	const handleChangeSlide = useCallback(currentSlide => {
		setSlide(currentSlide)
	}, [])

	const handleClickSignIn = useCallback(() => {
		history.push(routesByName.signIn.root)
	}, [history])

	const handleClickSignUpByPhoneNumber = useCallback(() => {
		history.push(routesByName.signUp.root)
	}, [history])

	const headerClasses = useMemo(
		() => ({
			root: clsx(classes.header, classes[`slide-${slide}`])
		}),
		[slide]
	)

	return (
		<>
			<MetaTags>
				<title>myTamilDate | Meet Tamil Singles</title>
				<meta
					name="description"
					content="Meet Tamil singles from Canada, US, UK & more! Connect & Chat. Like and Match. Sign up for a free account."
				/>
				<meta
					name="keywords"
					content="tamil dating, tamil singles, meet tamil singles, tamil relationships, my tamil date sign up"
				/>
			</MetaTags>
			<div className={classes.root}>
				<Header
					hasSuccessStoriesBtn
					variantLogo={firstSlideOnMobile ? 'light' : 'dark'}
					classes={headerClasses}
				/>
				<div className={clsx(classes.content, classes[`slide-${slide}`])}>
					{!firstSlideOnMobile ? (
						<div className={classes.slideTitle}>
							<Heading variant="heading-3" className={clsx(classes.heading)}>
								{titles[slide]}
							</Heading>
						</div>
					) : null}

					<div
						className={clsx(classes.carouselWrapper, classes[`slide-${slide}`])}
					>
						<BaseSlickCarousel
							classes={{
								root: classes.carousel,
								carouselContainer: classes.carouselContainer,
								dotsContainer: classes.dotsContainer,
								prevArrow: classes.prevArrow,
								nextArrow: classes.nextArrow
							}}
							arrows={!isMobileFormat}
							dots={isMobileFormat}
							onChangeSlide={handleChangeSlide}
							area="carousel"
						>
							{currentSlides.map((image, index) => (
								<img
									key={image}
									src={image}
									className={clsx(classes.image, classes[`slide-${index}`])}
									alt=""
								/>
							))}
						</BaseSlickCarousel>

						{slide === 0 ? (
							<div className={classes.firstSlideText}>
								<Typography className={classes.firstSlideTitle}>
									Meet <br /> Tamil Singles
								</Typography>
								<Typography className={classes.firstSlideDescription}>
									Shana (France) & Ramesh (Canada) Met Here.
								</Typography>
								<Link to={routesByName.successStories}>
									<Typography
										className={clsx(
											classes.firstSlideDescription,
											'white-color'
										)}
									>
										Read their story.
									</Typography>
								</Link>
							</div>
						) : null}
					</div>
					<div className={clsx(classes.facebookBtn, classes[`slide-${slide}`])}>
						<Button
							area="facebookBtn"
							shape="rounded"
							size="md"
							stretch
							shadow
							className={classes.btn}
							onClick={handleClickSignUpByPhoneNumber}
						>
							Get Started
						</Button>
					</div>

					<div className={clsx(classes.phoneBtn, classes[`slide-${slide}`])}>
						<Button
							shape="rounded"
							size="md"
							color="orange"
							variant="outlined"
							stretch
							shadow
							onClick={handleClickSignIn}
							className={classes.btn}
						>
							Sign in
						</Button>
					</div>

					{/*<div className={classes.login}>*/}
					{/*	<Typography className={clsx(classes.loginText)}>*/}
					{/*		Already a member?*/}
					{/*		<Link*/}
					{/*			className={clsx(classes.loginText, classes.loginLink)}*/}
					{/*			to={routesByName.signIn.root}*/}
					{/*		>*/}
					{/*			{' '}*/}
					{/*			Login*/}
					{/*		</Link>*/}
					{/*	</Typography>*/}
					{/*</div>*/}
					<PrivacyPolicy
						className={classes.privacy}
						linkColor={secondaryColor}
					/>
				</div>
				<Footer withSocialLinks={false} withPrivacyPolicy={!isMobileFormat} />
			</div>
		</>
	)
}

export default Homepage
