import React from 'react'
import Header from 'components/ui/layout/GuestHeader'
import classes from './SuccessStories.module.scss'
import Heading from 'components/ui/Typography/Heading'
import { stories } from 'pages/SuccessStories/config'
import InfoBlock from 'components/ui/InfoBlock'
import clsx from 'clsx'

const SuccessStories = () => {
	return (
		<div className={classes.root}>
			<Header variantLogo="dark" hasCloseIcon hideLogoOnMobile />
			<div className={classes.content}>
				<Heading variant="heading-4" className={classes.heading}>
					Success Stories
				</Heading>

				<div className={clsx('mt-4', classes.stories)}>
					{stories.map((story, index) => (
						<InfoBlock key={'story-' + index} {...story} />
					))}
				</div>
			</div>
		</div>
	)
}

export default SuccessStories
