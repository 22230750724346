import React, { memo, useCallback, useMemo } from 'react'
import classes from './ProfileMobileView.module.scss'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import useHistory from 'hooks/useHistory'
import MobileEditProfile from 'modules/profile/ProfileMobileView/Edit'
import MobilePreviewProfile from 'modules/profile/ProfileMobileView/Preview'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'

const tabs = [
	{
		value: 'Edit',
		label: 'Edit'
	},
	{
		value: 'Preview',
		label: 'Preview'
	}
]

const ProfileMobileView = ({
	onClickEditAboutMe = () => null,
	onClickUpdatePersonality = () => null,
	onClickUserCharacteristic = () => null,
	onClickMenuBar = () => null,
	onChangePhotos = () => null,
	onClickHelp = () => null,
	userCharacteristics,
	personalities,
	restUserCharacteristics,
	answersAndQuestions = [],
	firstName,
	selectedTab = 'Edit',
	onChangeTab = () => null,
	photos = [],
	lastName
}) => {
	const history = useHistory()

	const handleClickBackIcon = useCallback(() => {
		history.goBack()
	}, [history])

	const commonProps = {
		userCharacteristics,
		restUserCharacteristics,
		personalities,
		answersAndQuestions,
		firstName,
		photos,
		lastName
	}

	const renderPreviewTab = useMemo(() => {
		return selectedTab === 'Preview' ? (
			<MobilePreviewProfile {...commonProps} />
		) : null
	}, [selectedTab, commonProps])

	const renderEditTab = useMemo(() => {
		return selectedTab === 'Edit' ? (
			<MobileEditProfile
				onClickEditAboutMe={onClickEditAboutMe}
				onClickUpdatePersonality={onClickUpdatePersonality}
				onClickUserCharacteristic={onClickUserCharacteristic}
				onClickMenuBar={onClickMenuBar}
				onChangePhotos={onChangePhotos}
				onClickHelp={onClickHelp}
				{...commonProps}
			/>
		) : null
	}, [
		onChangePhotos,
		commonProps,
		onClickEditAboutMe,
		onClickMenuBar,
		onClickUpdatePersonality,
		onClickUserCharacteristic,
		onClickHelp,
		selectedTab
	])

	return (
		<div className={classes.mobileContainer}>
			<NavigationHeader
				selectedTab={selectedTab}
				onChangeTab={onChangeTab}
				tabs={tabs}
				title="My Profile"
				onClickBackIcon={handleClickBackIcon}
			/>

			<BaseScrollbars>
				{renderPreviewTab}
				{renderEditTab}
			</BaseScrollbars>
		</div>
	)
}

export default memo(ProfileMobileView)
