import React, { useCallback } from 'react'
import classes from './AboutPage.module.scss'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import Footer from 'components/ui/layout/Footer'
import Button from 'components/ui/Button'
import Logo from 'components/ui/layout/Logo'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'
import Link from 'components/ui/Typography/Link'
import { commonWhite } from 'constants/colors'
import useFlagManager from 'hooks/useFlagManager'
import TermsAndConditions from 'pages/TermsAndConditionsDialog'
import PrivacyPolicyPage from 'pages/PrivacyPolicyDialog'
import Typography from 'components/ui/Typography'

const links = [
	{
		href: routesByName.about,
		title: 'About Us'
	},
	{
		href: routesByName.contact,
		title: 'Contact Us'
	},
	{
		href: routesByName.faq,
		title: 'FAQ'
	}
]

const socialNetworksLinks = [
	{
		iconName: 'facebook-logo',
		href: 'https://www.facebook.com/mytamildate/'
	},
	{
		iconName: 'instagram-logo',
		href: 'https://www.instagram.com/mytamildate/?hl=en'
	},
	{
		iconName: 'twitter',
		href: 'https://twitter.com/mytamildate'
	}
]

const AboutPage = () => {
	const isMobileFormat =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile
	const history = useHistory()
	const termsDialog = useFlagManager()
	const policyDialog = useFlagManager()
	const format = useDetermineScreenFormat(breakpointsUp.md)

	const handleClickRegister = useCallback(
		() => history.push(routesByName.signUp.root),
		[history]
	)

	const handleClickLogin = useCallback(
		() => history.push(routesByName.signIn.root),
		[history]
	)

	return (
		<BaseScrollbars>
			<div className={classes.root}>
				<Footer
					className={classes.nav}
					withSocialLinks={false}
					withPrivacyPolicy={!isMobileFormat}
				/>
				<div className={clsx(classes.heading)}>
					<p className={classes.title}>About Us</p>
					<hr className={classes.line} />
					<p
						className={clsx(
							classes.description,
							classes.centered,
							classes.withPadding
						)}
					>
						myTamilDate.com has been the most trusted dating community for
						single Tamils around the world for close to a decade! We’re the
						premiere dating site for diaspora Tamils and have the largest
						membership base in Canada, USA, UK & more.
					</p>
					<Button
						shape="rounded"
						className={classes.btn}
						onClick={handleClickRegister}
					>
						Register now
					</Button>
				</div>
				<div className={classes.loginWrapper}>
					<div className={classes.imgWrapper}>
						<img
							src={'assets/upgradeSlides/mobile/3.jpg'}
							className={clsx(classes.img, classes.img1)}
							alt={'Sanjutha & Roban'}
						/>
						<div className={classes.imgTitle}>
							Sanjutha & Roban Met Here.
							<br />
							<a
								href="https://tiethethali.com/sanjutha-roban/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Typography className={clsx(classes.storyLink, 'white-color')}>
									Read their story.
								</Typography>
							</a>
						</div>
					</div>
					<div className={classes.login}>
						<p className={clsx(classes.title, classes.secondary)}>
							Proven Success
						</p>
						<Logo />
						<p className={clsx(classes.description, classes.centered)}>
							We’ve been helping single Tamils meet, date and marry for years.
							On their own terms & their own timelines.
						</p>
						<p className={classes.infoText}>Already a member?</p>
						<Button
							shape="rounded"
							className={clsx(classes.btn, classes.secondary)}
							onClick={handleClickLogin}
						>
							Login Here
						</Button>
					</div>
					<div className={classes.imgWrapper}>
						<img
							src={'assets/upgradeSlides/mobile/2.jpg'}
							className={clsx(classes.img, classes.img2)}
							alt={'Lavanya & Vitharan'}
						/>
						<div className={classes.imgTitle}>
							Lavanya & Vitharan Met Here.
							<br />
							<a
								href="https://tamilculture.com/how-a-message-on-mytamildatecom-led-to-an-engagement-for-lavanya-vitharan"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Typography className={clsx(classes.storyLink, 'white-color')}>
									Read their story.
								</Typography>
							</a>
						</div>
					</div>
				</div>
				<div className={classes.connection}>
					<div className={classes.connectionWrapper}>
						<p
							className={clsx(classes.title, {
								[classes.centered]: isMobileFormat
							})}
						>
							Meaningful Connections
						</p>
						<p
							className={clsx(classes.description, {
								[classes.centered]: isMobileFormat
							})}
						>
							No mindless swiping! View a person’s profile in detail before
							making a decision to match or not.
						</p>
					</div>
					<img
						src={'assets/couple.jpg'}
						alt={'couple'}
						className={clsx(classes.img, classes.img3)}
					/>
				</div>
				<div className={classes.benefitsWrapper}>
					<p className={classes.benefitsTitle}>Other Benefits of myTamilDate</p>
					<div className={classes.benefits}>
						<div className={classes.benefitsItem}>
							<div className={classes.benefitTitle}>
								<SpriteIcon className={classes.icon} name="security" />{' '}
								<p>Trust & Authenticity</p>
							</div>
							<div className={classes.benefitDescription}>
								We manually verify every profile in addition to requiring phone
								verification.
							</div>
						</div>
						<div className={classes.benefitsItem}>
							<div className={classes.benefitTitle}>
								<SpriteIcon className={classes.icon} name="devices" />{' '}
								<p>Flexible Platform</p>
							</div>
							<div className={classes.benefitDescription}>
								We’re built for mobile first and enhanced for desktop too! The
								best part? You don’t have to download yet another app.
							</div>
						</div>
						<div className={classes.benefitsItem}>
							<div className={classes.benefitTitle}>
								<SpriteIcon className={classes.icon} name="screen_lock" />{' '}
								<p>Safety & Protection</p>
							</div>
							<div className={classes.benefitDescription}>
								We have a track record for creating a safe and trusted
								community, including your experience on the platform & the
								protection of your personal data.
							</div>
						</div>
						<div className={classes.benefitsItem}>
							<div className={classes.benefitTitle}>
								<SpriteIcon className={classes.icon} name="supervised_user" />{' '}
								<p>Personalized Service</p>
							</div>
							<div className={classes.benefitDescription}>
								We care about your dating life like a good friend would! Need
								help with your profile? Need messaging tips & date ideas? We’ve
								got you covered!
							</div>
						</div>
					</div>
				</div>
				<div className={classes.founders}>
					<div className={classes.readMore}>
						<p className={clsx(classes.title, classes.centered)}>
							Meet The Founders!
						</p>
						<hr className={classes.line} />
						<p
							className={clsx(
								classes.description,
								classes.centered,
								classes.withPadding
							)}
						>
							FROM BESTIES TO BUSINESS PARTNERS: The founders of mytamildate
							have been helping single tamils find love for close to a decade
						</p>
						<Button shape="rounded" className={classes.btn}>
							<a
								href={
									'https://tamilculture.com/from-besties-to-business-partners-the-founders-of-mytamildate-have-been-helping-single-tamils-find-love-for-close-to-a-decade'
								}
								target="_blank"
								rel="noopener noreferrer"
								className={classes.link}
							>
								Read more
							</a>
						</Button>
					</div>
					<img
						src={'assets/founders.png'}
						alt={'founders'}
						className={clsx(classes.img, classes.img4)}
					/>
				</div>
				{!isMobileFormat && <Footer className={classes.footer} />}
				{isMobileFormat && (
					<div className={classes.mobileFooter}>
						<Logo withTitle classes={{ root: classes.logo }} />
						<div className={classes.mobileNavBar}>
							{links.map(({ href, title }, index) => (
								<Link
									key={title + index}
									href={href}
									className={classes.mobileNavLink}
								>
									{title}
								</Link>
							))}
							<span
								className={clsx(classes.infoText, classes.mobileNavLink)}
								role="presentation"
								onClick={termsDialog.turnIn}
							>
								Terms & Conditions
							</span>
							<span
								className={clsx(classes.infoText, classes.mobileNavLink)}
								role="presentation"
								onClick={policyDialog.turnIn}
							>
								Privacy
							</span>
						</div>
						<div className={classes.iconContainer}>
							{socialNetworksLinks.map(({ iconName, href }, index) => (
								<Link
									key={iconName + index}
									href={href}
									target="_blank"
									rel="noopener noreferrer"
								>
									<SpriteIcon
										name={iconName}
										color={commonWhite}
										size={format === screenFormat.mobile ? 'sm' : 'md'}
										className={classes.socialIcon}
									/>
								</Link>
							))}
						</div>
					</div>
				)}
				<TermsAndConditions
					open={termsDialog.state}
					onClose={termsDialog.turnOff}
				/>
				<PrivacyPolicyPage
					open={policyDialog.state}
					onClose={policyDialog.turnOff}
				/>
			</div>
		</BaseScrollbars>
	)
}

export default AboutPage
