import React from 'react'
import ConversationContainer from 'modules/conversation/ConversationContainer.js'
import ReceivedConversationContainer from 'modules/notifications/requests/receivedConversation/ReceivedConversationContainer.js'
import ReceivedConversationView from 'modules/notifications/requests/receivedConversation/ReceivedConversationView.js'

const ReceivedConversationPage = () => {
	return (
		<ReceivedConversationContainer>
			{({ onClickLike, onClickPass, userId, infoDialog }) => (
				<ConversationContainer>
					{props => (
						<ReceivedConversationView
							{...props}
							onClickLike={onClickLike}
							onClickPass={onClickPass}
							userId={userId}
							infoDialog={infoDialog}
						/>
					)}
				</ConversationContainer>
			)}
		</ReceivedConversationContainer>
	)
}

export default ReceivedConversationPage
