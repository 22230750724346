import React, { memo, useState } from 'react'
import classes from 'components/ui/Dialogs/FeedbackDialog/FeedbackDialog.module.scss'
import BaseSwitch from 'components/ui/controls/BaseSwitch'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import { useSelector } from 'react-redux'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import {
	getReportReasons,
	getUnmatchReasons
} from 'modules/app/store/selectors'

const labelClasses = {
	labelRoot: classes.labelRoot
}

const FeedbackDialog = ({
	open = false,
	onClose,
	onClick,
	type = 'report'
}) => {
	const [activeSwitch, setActiveSwitch] = useState(0)
	const reasons = useSelector(
		type === 'report' ? getReportReasons : getUnmatchReasons
	)

	return (
		<BaseDialog
			open={open}
			maxWidth="lg"
			rootClass={classes.dialog}
			onClose={onClose}
		>
			<div className={classes.root}>
				<div className={classes.modal}>
					<div className={classes.modalHeader}>
						<div className={classes.modalTitle}>Give us your feedback</div>
						<div className={classes.modalSubtitle}>
							help us improve your experience
						</div>
					</div>
					{type === 'report'
						? reasons?.map((reason, index) => (
								<div className={classes.reason} key={reason + index}>
									<BaseSwitch
										label={reasons[index]}
										onChange={() => setActiveSwitch(index)}
										checked={activeSwitch === index}
										labelPlacement="start"
										spaceBetween={true}
										classes={labelClasses}
									/>
								</div>
						  ))
						: Object.entries(reasons || {})?.map(([id, reason]) => (
								<div className={classes.reason} key={reason + id}>
									<BaseSwitch
										label={reason}
										onChange={() => setActiveSwitch(id)}
										checked={activeSwitch === id}
										labelPlacement="start"
										spaceBetween={true}
										classes={labelClasses}
									/>
								</div>
						  ))}
				</div>

				<div className={classes.actions}>
					<Button
						shape="rounded"
						onClick={onClick}
						data-reason={
							type === 'report' ? reasons[activeSwitch] : activeSwitch
						}
						className={classes.btn}
					>
						Submit
					</Button>
					<Button
						onClick={onClose}
						shape="rounded"
						className={clsx(classes.btn, classes.cancelBtn)}
					>
						Cancel
					</Button>
				</div>
			</div>
		</BaseDialog>
	)
}

export default memo(FeedbackDialog)
