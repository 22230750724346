import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import PropTypes from 'prop-types'
import ReactCodeInput from 'react-code-input'
import classes from './CodeInput.module.scss'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import BaseInput from 'components/ui/controls/BaseInput'
import { sm } from 'utils/simulateEvent'
import moment from 'moment'
import clsx from 'clsx'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import InfoDialog from '../../../ui/Dialogs/InfoDialog'
import useFlagManager from '../../../../hooks/useFlagManager'

const CodeInput = ({
	inputMode,
	name,
	type,
	className,
	onChange,
	timeTillDate,
	error,
	touched,
	fields,
	value
}) => {
	const [countdown, setCountdown] = useState('00:00')
	const interval = useRef()
	const infoDialog = useFlagManager()
	const [timerError, setTimerError] = useState('')

	const hasError = useMemo(() => error && touched, [error, touched])

	useEffect(() => {
		if (moment(timeTillDate) < moment()) {
			clearInterval(interval.current)
			setCountdown('00:00')
			setTimerError('Sorry, this code has expired!')
			infoDialog.turnIn()
		}
		// eslint-disable-next-line
	}, [countdown, timeTillDate])

	useEffect(
		() => {
			if (timeTillDate) {
				interval.current = setInterval(() => {
					const then = moment(timeTillDate)
					const now = moment()
					const countdown = moment(then - now).format('mm:ss')
					setCountdown(countdown)
				}, 1000)

				return () => {
					if (interval.current) {
						clearInterval(interval.current)
					}
				}
			}
		},
		//eslint-disable-next-line
		[timeTillDate]
	)

	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile

	const inputStyle = useMemo(
		() => ({
			fontFamily: 'Montserrat, sans-serif',
			MozAppearance: 'textfield',
			borderRadius: '8px',
			border: '1.5px solid #FC8C66',
			fontWeight: isMobile ? '600' : '700',
			fontSize: isMobile ? '20px' : '26px',
			lineHeight: isMobile ? '26px' : '35px',
			width: '100%',
			height: isMobile ? '50px' : '60px',
			boxSizing: 'border-box',
			color: '#3E3E3E',
			backgroundColor: '#F0F0F0',
			textAlign: 'center'
		}),
		[isMobile]
	)

	const inputStyleInvalid = useMemo(
		() => ({
			fontFamily: 'Montserrat, sans-serif',
			MozAppearance: 'textfield',
			borderRadius: '8px',
			border: '2px solid #FF0000',
			fontWeight: isMobile ? '600' : '700',
			fontSize: isMobile ? '20px' : '26px',
			lineHeight: isMobile ? '26px' : '35px',
			width: '100%',
			height: isMobile ? '50px' : '60px',
			boxSizing: 'border-box',
			color: '#3E3E3E',
			backgroundColor: '#F0F0F0',
			textAlign: 'center'
		}),
		[isMobile]
	)

	const handleChange = useCallback(
		value => {
			onChange(sm(name, value))
		},
		[name, onChange]
	)

	const codeInputRef = useRef()

	useEffect(() => {
		if (value) {
			codeInputRef.current.state.input = [
				...value.split(''),
				...Array(fields - value.length).fill('')
			]
		}
		//eslint-disable-next-line
	}, [value])

	return (
		<div className={clsx(className, classes.root)}>
			<ReactCodeInput
				ref={codeInputRef}
				inputMode={inputMode}
				name={name}
				inputStyle={inputStyle}
				type={type}
				className={classes.inputsContainer}
				onChange={handleChange}
				isValid={!hasError}
				touched={touched}
				fields={fields}
				inputStyleInvalid={inputStyleInvalid}
				value={value}
			/>
			<BaseInput
				value={countdown}
				rootClass={classes.timer}
				inputClass={classes.input}
				readOnly
			/>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.turnOff}
				type={'error'}
				title={'Error'}
				body={timerError}
			/>
		</div>
	)
}

CodeInput.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	inputMode: PropTypes.string,
	onChange: PropTypes.func,
	timeTillDate: PropTypes.string,
	fields: PropTypes.number,
	type: PropTypes.oneOf(['text', 'number', 'password', 'tel'])
}

CodeInput.defaultProps = {
	className: '',
	timeTillDate: moment().toISOString(),
	inputMode: 'numeric',
	type: 'text',
	name: '',
	fields: 4,
	value: '',
	onChange: () => null
}

export default memo(CodeInput)
