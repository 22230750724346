import React, { useMemo } from 'react'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import classes from './Preference.module.scss'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import MenuElement from 'components/ui/MenuElement'
import Typography from 'components/ui/Typography'
import clsx from 'clsx'
import Button from 'components/ui/Button'
import Logo from 'components/ui/layout/Logo'
import FilterEditor from './components/FilterEditorDialog'
import { componentsLibrary } from './componentsLibrary.js'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'

const filterClasses = {
	root: classes.filter
}

const logoClasses = {
	icon: classes.icon,
	root: 'mt-2'
}

const PreferenceView = ({
	callbacks: {
		onSaveFilters = () => null,
		onCancelFilters = () => null,
		onClickBack = () => null
	},
	editFilter = {},
	data: {
		commonFilterOptions,
		premiumFilterOptions,
		isMobile,
		config,
		upgradeNotification
	}
}) => {
	const CurrentEditorElement = useMemo(
		() => componentsLibrary[editFilter.name],
		[editFilter.name]
	)

	const history = useHistory()

	return (
		<div className={classes.root}>
			{isMobile ? (
				<NavigationHeader
					title="Search Preferences"
					onClickBackIcon={onClickBack}
				/>
			) : null}

			<BaseScrollbars>
				<div className={classes.wrapper}>
					{/*{*/}
					{/*	isMobile &&*/}
					{/*	<Button*/}
					{/*		shape="rounded"*/}
					{/*		variant="outlined"*/}
					{/*		color="grey"*/}
					{/*		className={classes.btn}*/}
					{/*		size="md"*/}
					{/*		onClick={onCancelFilters}*/}
					{/*		shadow*/}
					{/*	>*/}
					{/*		Reset*/}
					{/*	</Button>*/}
					{/*}*/}
					{commonFilterOptions?.map((data, index) => (
						<MenuElement
							{...data}
							onClick={editFilter.onOpenCommonFilter}
							classes={filterClasses}
							key={`characteristic-${data.name}-${index}`}
						/>
					))}

					{!upgradeNotification.isSubscribe ? (
						<div className={classes.delimiterWrapper}>
							<Typography
								className={clsx('mt-3 mb-2 text-center', classes.delimiter)}
							>
								Premium Filters -{' '}
								<Typography
									role="presentation"
									onClick={() => history.push(routesByName.upgrade.root)}
								>
									Upgrade for Access
								</Typography>
							</Typography>
						</div>
					) : null}

					{premiumFilterOptions?.map((data, index) => (
						<MenuElement
							{...data}
							onClick={editFilter.onOpenPremiumFilter}
							classes={filterClasses}
							disabled={!upgradeNotification.isSubscribe}
							key={`characteristic-${data.name}-${index}`}
						/>
					))}
					{!isMobile && (
						<div className={classes.buttons}>
							<Button
								shape="rounded"
								// className={clsx(classes.btn, 'mr-2')}
								className={classes.btn}
								onClick={onSaveFilters}
								size="md"
								shadow
							>
								Save
							</Button>
							{/*<Button*/}
							{/*	shape="rounded"*/}
							{/*	variant="outlined"*/}
							{/*	color="grey"*/}
							{/*	className={classes.btn}*/}
							{/*	size="md"*/}
							{/*	onClick={onCancelFilters}*/}
							{/*	shadow*/}
							{/*>*/}
							{/*	Reset*/}
							{/*</Button>*/}
						</div>
					)}
					{isMobile ? (
						<Logo variant="dark" withTitle classes={logoClasses} />
					) : null}

					{upgradeNotification.purpleBanner}
					{upgradeNotification.unlockFeatures.component}
				</div>
			</BaseScrollbars>

			<FilterEditor
				open={editFilter.open}
				onClose={editFilter.onClose}
				title={editFilter.title}
				name={editFilter.name}
			>
				<CurrentEditorElement
					config={config}
					name={editFilter.name}
					value={editFilter.value}
					onChange={editFilter.onChange}
				/>
			</FilterEditor>
		</div>
	)
}

export default PreferenceView
