import React, { memo, useCallback } from 'react'
import classes from './PromoCode.module.scss'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import { ReactComponent as Logo } from 'assets/staticIcons/dark-logo.svg'
import clsx from 'clsx'
import Button from 'components/ui/Button'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import SpriteIcon from 'components/ui/SpriteIcon'
import {
	halfOf50,
	lastChance50,
	promoCodes,
	tryNow33
} from 'components/ui/Dialogs/PromoDialog/PromoCode/config'
import PropTypes from 'prop-types'
import infoToastr from 'libs/toastr/infoToastr'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { checkPromoWithPlan } from 'modules/subscriptionBilling/services'
import { useDispatch } from 'react-redux'
import { toggleLoader } from 'modules/app/store/actions'
import { _get } from 'utils/lodash'
import { setCurrentPlan } from 'modules/subscriptionBilling/store/actions'

const PromoCode = ({ open, onClose, code, onConfirm }) => {
	const history = useHistory()
	const dispatch = useDispatch()

	const handleCopyCode = useCallback(() => {
		infoToastr('Code copied to clipboard!', '', { timeOut: 3000 })
	}, [])

	const handleUpgrade = useCallback(async () => {
		try {
			dispatch(toggleLoader())
			const { result } = await checkPromoWithPlan(code)
			if (result) {
				const priceId = _get(result, 'price_id', null)
				if (priceId) {
					dispatch(setCurrentPlan(priceId, code))
				}
			}
		} catch (e) {
			console.dir(e)
		} finally {
			dispatch(toggleLoader())
			history.push(routesByName.upgrade.root)
		}
	}, [code, dispatch, history])

	return (
		<BaseDialog open={open} maxWidth="sm" onClose={onClose}>
			<div className={classes.root}>
				<div className={classes.modal}>
					<div className={classes.modalHeader}>
						<SpriteIcon
							name="close"
							className={classes.closeIcon}
							onClick={onClose}
						/>
						<p className={classes.modalTitle}>
							We would like to keep you on the site. Here's a special offer!
						</p>
						<div className={classes.logoContainer}>
							<Logo className={classes.logo} />
						</div>
					</div>
					<div className={classes.modalContent}>
						<p className={classes.modalText}>{promoCodes[code]}</p>
						<div className={classes.codeBlock}>
							<span className={classes.code}>{code}</span>
						</div>
						<CopyToClipboard onCopy={handleCopyCode} text={code}>
							<span className={classes.copyCode}>copy to clipboard</span>
						</CopyToClipboard>
						<Button
							className={classes.btn}
							shape="rounded"
							onClick={handleUpgrade}
							color={'secondary'}
						>
							Upgrade membership
						</Button>
						<Button
							shape="rounded"
							className={clsx(classes.btn, classes.outlined)}
							onClick={onConfirm}
						>
							{code === halfOf50 ? 'Finish' : 'No, thanks'}
						</Button>
					</div>
				</div>
			</div>
		</BaseDialog>
	)
}

PromoCode.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	code: PropTypes.oneOf([lastChance50, tryNow33, halfOf50, null])
}

PromoCode.defaultProps = {
	open: false,
	onClose: () => null,
	code: halfOf50
}

export default memo(PromoCode)
