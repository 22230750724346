import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './Message.module.scss'
import { ReactComponent as MessageAppendix } from './appendix.svg'
import * as moment from 'moment'
import UserAvatar from 'components/ui/UserAvatart'
import { useSelector } from 'react-redux'
import { getAvatarByUserId } from 'modules/users/selectors'

const Message = ({
	children,
	isOwn,
	id,
	lastInLineForCurrentUser,
	data = {}
}) => {
	const avatar = useSelector(getAvatarByUserId(data.userId))

	const createMarkup = useCallback(() => {
		return { __html: children }
	}, [children])

	return (
		<div
			className={clsx(classes.wrapper, {
				[classes.self]: isOwn,
				[classes.another]: !isOwn,
				[classes.lastMessageInCurrentLine]: lastInLineForCurrentUser
			})}
		>
			{avatar && !isOwn && lastInLineForCurrentUser ? (
				<UserAvatar src={avatar} className={classes.avatar} type="square" />
			) : !lastInLineForCurrentUser && !isOwn ? (
				<div className={classes.avatar} />
			) : null}
			<div
				id={id}
				className={clsx(classes.root, {
					[classes.self]: isOwn,
					[classes.another]: !isOwn
				})}
			>
				<span
					className={classes.message}
					dangerouslySetInnerHTML={createMarkup()}
				/>
				{lastInLineForCurrentUser && (
					<MessageAppendix
						className={clsx(classes.appendix, {
							[classes.selfAppendix]: isOwn,
							[classes.anotherAppendix]: !isOwn
						})}
					/>
				)}
				<div className={classes.sentAt}>
					{moment.unix(data.sentAt / 1000).format('hh:mm')}
				</div>
			</div>
			{/*{lastInLineForCurrentUser && (*/}
			{/*	<span className={classes.messageStatus}>Read</span>*/}
			{/*)}*/}
		</div>
	)
}

Message.propTypes = {
	isSelf: PropTypes.bool,
	body: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default memo(Message)
