import React from 'react'
import classes from './FAQPage.module.scss'
import GuestHeader from 'components/ui/layout/GuestHeader'
import { pageContent } from 'pages/FAQPage/config'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'

const FAQPage = () => {
	return (
		<div className={classes.root}>
			<BaseScrollbars>
				<GuestHeader variantLogo="light" classes={{ root: classes.header }} />
				<div className={classes.content}>
					{pageContent.map(({ title, description }) => (
						<div key={title}>
							<p className={classes.title}>{title}</p>
							<p className={classes.description}>{description}</p>
						</div>
					))}
				</div>
			</BaseScrollbars>
		</div>
	)
}

export default FAQPage
