import React, { memo, useCallback } from 'react'
import classes from './DeleteAccountDialog.module.scss'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import useFlagManager from 'hooks/useFlagManager'
import SpriteIcon from 'components/ui/SpriteIcon'
import Button from 'components/ui/Button'
import { deleteAccount, pauseAccount } from 'modules/profile/services'
import { updateMe } from 'modules/profile/store/actions'
import { useDispatch } from 'react-redux'
import { logOut } from 'modules/auth/store/actions'
import { toggleLoader } from 'modules/app/store/actions'
import clsx from 'clsx'
import DeleteReasonsDialog from 'components/ui/Dialogs/DeleteReasonsDialog'

const DeleteAccountDialog = ({
	open = false,
	onClose,
	onConfirm,
	userStatus
}) => {
	const isPauseSelected = useFlagManager()
	const isDeleteSelected = useFlagManager()
	const dispatch = useDispatch()
	const feedbackDialog = useFlagManager()

	const handleExpandPauseReasons = useCallback(() => {
		isPauseSelected.turnIn()
		isDeleteSelected.turnOff()
		feedbackDialog.turnIn()
	}, [isDeleteSelected, isPauseSelected, feedbackDialog])

	const handleExpandDeleteReasons = useCallback(() => {
		isPauseSelected.turnOff()
		isDeleteSelected.turnIn()
		feedbackDialog.turnIn()
	}, [isDeleteSelected, isPauseSelected, feedbackDialog])

	const handlePauseAccount = useCallback(async () => {
		const status = userStatus === 'active' ? 'inactive' : 'active'
		try {
			dispatch(toggleLoader())
			await pauseAccount(status)
			dispatch(updateMe({ status }))
		} catch (e) {
			console.dir(e)
		} finally {
			onClose()
			feedbackDialog.turnOff()
			dispatch(toggleLoader())
		}
	}, [dispatch, feedbackDialog, onClose, userStatus])
	const openForm = () => {
	  	window.open('https://forms.gle/JsXnTmf3Byf7BGKK8', '_blank')
	}

	const handleDeleteAccount = useCallback(async (reasonId) => {
		if (reasonId === 4) openForm()

		try {
			dispatch(toggleLoader())
			await deleteAccount({reason_id: reasonId})
			dispatch(logOut())
		} catch (e) {
			console.dir(e)
		} finally {
			onClose()
			feedbackDialog.turnOff()
			dispatch(toggleLoader())
		}
	}, [dispatch, feedbackDialog, onClose])

	return (
		<>
			<BaseDialog
				open={open}
				maxWidth="sm"
				onClose={onClose}
				classesPaper={classes.paper}
			>
				<SpriteIcon
					name="close"
					className={clsx(classes.icon, classes.close)}
					onClick={onClose}
				/>
				<div className={classes.root}>
					<div className={classes.modal}>
						<div className={classes.modalHeader}>
							<SpriteIcon name="disabled" className={classes.icon} />
							<div className={classes.modalDescription}>
								<div className={classes.modalTitle}>
									Pause your account instead
								</div>
								<div className={classes.deleteReasons}>
									<div className={classes.modalSubtitle}>
										Just need a break?
										<br />
										Pause your account and hide your profile, and come back
										anytime.
									</div>
									<Button
										shape="rounded"
										className={classes.btn}
										onClick={handleExpandPauseReasons}
										shadow
									>
										Pause
									</Button>
								</div>
							</div>
						</div>
						<div className={clsx(classes.modalHeader, 'mt-4')}>
							<SpriteIcon name="trash" className={classes.icon} />
							<div className={classes.modalDescription}>
								<div className={classes.modalTitle}>Delete your account?</div>
								<div className={classes.deleteReasons}>
									<div className={classes.modalSubtitle}>
										You cannot recover your account once deleted, are you sure
										you want to delete your account?
									</div>
									<Button
										shape="rounded"
										className={classes.btn}
										onClick={handleExpandDeleteReasons}
										shadow
									>
										Delete
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</BaseDialog>
			<DeleteReasonsDialog
				open={feedbackDialog.state}
				onClose={feedbackDialog.turnOff}
				action={isPauseSelected.state ? 'pause' : 'delete'}
				onConfirm={
					isPauseSelected.state
						? (reasonId) => handlePauseAccount(reasonId)
						: (reasonId) => handleDeleteAccount(reasonId)
				}
			/>
		</>
	)
}

export default memo(DeleteAccountDialog)
