import React, { useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PrivateLayout from 'components/ui/layout/PrivateLayout/PrivateLayout'
import { routesByName } from 'constants/routes'
import { pagesWithProfileMate } from 'modules/main/MainContainer.js'
import NotificationRightSidebar from 'modules/notifications/NotificationsRightSidebar'
import ProfileSwitch from 'modules/profile/ProfileSwitch'
import DiscoveryLeftSidebar from 'modules/discovery/components/LeftSidebar/LeftSidebar'
import ProfileLeftSidebar from 'modules/profile/components/LeftSidebar'
import SettingsLayout from 'modules/settings/SettingsLayout'
import SettingsLeftSidebar from 'modules/settings/components/LeftSidebar'
import HelpPage from 'pages/HelpPage'
import HelpPageLeftSideBar from 'pages/HelpPage/LeftSideBar'
import LikesPreview from 'modules/notifications/likes/LikesPreview'
import RequestCreatePage from 'modules/notifications/requests/createConversation/RequestCreatePage.js'
import MatchesCreatePage from 'modules/notifications/matches/createConversation/MatchesCreatePage.js'
import ReceivedConversationPage from 'modules/notifications/requests/receivedConversation'
import LastMatchLeftSidebar from 'modules/notifications/matches/lastMatch/components/LeftSidebar/LeftSidebar.js'
import LastMatchPage from 'modules/notifications/matches/lastMatch'
import LastMatchCreatePage from 'modules/notifications/matches/lastMatch/createConversation/LastMatchCreatePage.js'
import PreferencePage from 'modules/preferences'
// import componentLoader from 'utils/componentLoader'
import DiscoveryPage from 'modules/discovery'
import NotificationLeftSidebar from 'modules/notifications/NotificationsLeftSidebar'
import ConversationPage from 'modules/conversation'
import PromoCode from 'components/ui/Dialogs/PromoDialog/PromoCode'
import {
	halfOf50,
} from 'components/ui/Dialogs/PromoDialog/PromoCode/config'
import MatchDialog from '../../components/ui/Dialogs/MatchDialog'
// import EmailNotifications from '../notifications/emails';

// const DiscoveryPage = React.lazy(() => import('modules/discovery'))
// const NotificationLeftSidebar = React.lazy(() =>
// 	import('modules/notifications/NotificationsLeftSidebar')
// )
// const ConversationPage = React.lazy(() => import('modules/conversation'))
// const FallbackPage = React.lazy(() => import('pages/FallbackPage/FallbackPage'))

// const DiscoveryPage = React.lazy(() =>
// 	componentLoader(() => import('modules/discovery'))
// )
// const NotificationLeftSidebar = React.lazy(() =>
// 	componentLoader(() =>
// 		import('modules/notifications/NotificationsLeftSidebar')
// 	)
// )
// const ConversationPage = React.lazy(() =>
// 	componentLoader(() => import('modules/conversation'))
// )
// const FallbackPage = React.lazy(() =>
// 	componentLoader(() => import('pages/FallbackPage/FallbackPage'))
// )

// const EmailNotifications = React.lazy(() =>
// 	componentLoader(() => import('../notifications/emails'))
// )
const EmailNotifications = React.lazy(() => import('../notifications/emails'))

const MainView = ({
	contentSlotOnMobile,
	withRightSidebar,
	mate,
	withMateName,
	popup_status,
	promoDialog
}) => {
	const promoCode = useMemo(() => {
		switch (popup_status) {
			case 1:
				return halfOf50
			case 2:
				return halfOf50
			default:
				return null
		}
	}, [popup_status])

	const isMateBlocked = useMemo(() => mate?.is_blocked, [mate.is_blocked])

	return (
		<>
			<PrivateLayout
				leftSidebar={
					<Switch>
						<Route
							path={routesByName.main.discovery.root}
							component={DiscoveryLeftSidebar}
						/>
						<Route
							path={routesByName.main.lastMatch.root}
							component={LastMatchLeftSidebar}
						/>
						<Route
							path={routesByName.main.notifications.root}
							component={NotificationLeftSidebar}
						/>
						<Route
							path={routesByName.main.profile.root}
							component={ProfileLeftSidebar}
						/>
						<Route
							path={routesByName.main.settings.root}
							component={SettingsLeftSidebar}
						/>
						<Route
							path={routesByName.main.helpPage}
							component={HelpPageLeftSideBar}
						/>
						<Route path="*">
							<Redirect to={routesByName.main.discovery.root} />
						</Route>
					</Switch>
				}
				content={
					<Switch>
						<Route
							path={routesByName.main.discovery.root}
							component={DiscoveryPage}
							exact
						/>
						<Route
							path={routesByName.main.discovery.sendMessage}
							component={RequestCreatePage}
						/>
						<Route path={routesByName.main.discovery.conversation}>
							<ConversationPage isConversationBlocked={isMateBlocked} />
						</Route>
						<Route path={routesByName.main.notifications.matches.conversation}>
							<ConversationPage isConversationBlocked={isMateBlocked} />
						</Route>
						<Route
							path={routesByName.main.notifications.matches.conversationCreate}
							component={MatchesCreatePage}
						/>
						<Route path={routesByName.main.notifications.requests.sent}>
							<ConversationPage isConversationBlocked={isMateBlocked} />
						</Route>
						<Route
							path={routesByName.main.profile.root}
							component={ProfileSwitch}
						/>
						<Route
							path={routesByName.main.settings.root}
							component={SettingsLayout}
						/>
						<Route
							path={routesByName.main.lastMatch.root}
							exact
							component={LastMatchPage}
						/>
						<Route
							path={routesByName.main.notifications.likes.received}
							component={LikesPreview}
						/>
						<Route
							path={routesByName.main.notifications.likes.sent}
							component={LikesPreview}
						/>
						<Route
							path={routesByName.main.notifications.likes.previewReceived}
							component={LikesPreview}
						/>
						<Route
							path={routesByName.main.notifications.likes.previewSent}
							component={LikesPreview}
						/>
						<Route path={routesByName.main.helpPage} component={HelpPage} />
						<Route
							path={
								routesByName.main.notifications.requests.receivedConversation
							}
							component={ReceivedConversationPage}
						/>
						<Route
							path={routesByName.main.lastMatch.conversationCreate}
							component={LastMatchCreatePage}
						/>
						<Route
							path={routesByName.main.lastMatch.conversation}
							component={ConversationPage}
						/>
						<Route
							path={routesByName.main.discovery.preference}
							component={PreferencePage}
						/>
						<Route
							path={routesByName.main.notifications.emails}
							component={EmailNotifications}
						/>
						{/*<Route path="*">*/}
						{/*	<Redirect to={routesByName.main.discovery.root} />*/}
						{/*</Route>*/}
					</Switch>
				}
				rightSidebar={
					<Switch>
						{pagesWithProfileMate.map(path => (
							<Route
								path={path}
								key={path}
								component={NotificationRightSidebar}
							/>
						))}
					</Switch>
				}
				withRightSidebar={withRightSidebar}
				contentSlotOnMobile={contentSlotOnMobile}
				mate={mate}
				withMateName={withMateName}
			/>
			<PromoCode
				code={promoCode}
				open={promoDialog.state && !!promoCode}
				onClose={promoDialog.turnOff}
				onConfirm={promoDialog.turnOff}
			/>
			<MatchDialog />
		</>
	)
}

export default MainView
