import React from 'react'
import LastMatchCreateContainer from './LastMatchCreateContainer.js'
import CreateConversationMobile from 'modules/conversation/create/CreateConversationMobile.js'

const LastMatchCreateMobile = () => {
	return (
		<LastMatchCreateContainer>
			{({ onPushMessage }) => (
				<CreateConversationMobile onPushMessage={onPushMessage} />
			)}
		</LastMatchCreateContainer>
	)
}

export default LastMatchCreateMobile
