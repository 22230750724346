import React, { useMemo, useRef } from 'react'
import LikesContainer from 'modules/notifications/likes/LikesContainer'
import ProfileContainer from 'modules/profile/ProfileContainer'
import MobilePreviewProfile from 'modules/profile/ProfileMobileView/Preview/MobilePreviewProfile'
import DesktopPreviewProfile from 'modules/profile/ProfileDesktopView/Preview/DesktopPreviewProfile'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import { screenFormat } from 'constants/screenFormat'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import discoveryClasses from 'modules/discovery/DiscoveryView.module.scss'
import likesClasses from './Likes.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, primaryColor } from 'constants/colors'
import Typography from 'components/ui/Typography'
import userSelectors from 'modules/users/selectors'
import * as selectors from 'modules/notifications/likes/store/selectors'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { routesByName } from '../../../constants/routes'

const variantByFormat = {
	mobile: MobilePreviewProfile,
	desktop: DesktopPreviewProfile
}

const LikesPreview = () => {
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const scrollRef = useRef()
	const userId = useSelector(selectors.getCurrentUserId)
	const user = useSelector(userSelectors.getUserById(userId))
	const { pathname } = useLocation()

	const CurrentView = useMemo(() => variantByFormat[format], [format])

	return (
		<>
			{userId && (
				<LikesContainer>
					{({ onClickLike, onClickPass }) => (
						<ProfileContainer user={user}>
							{({
								formProps,
								onClickBlock,
								onClickReport,
								onClickConfirmReport,
								reportModal
							}) => (
								<BaseScrollbars>
									<div className={likesClasses.preview}>
										{format === screenFormat.mobile ? (
											<>
												{pathname !==
													routesByName.main.notifications.likes.previewSent && (
													<Button
														size="xl"
														shape="rounded"
														color="white"
														className={clsx(
															discoveryClasses.btn,
															discoveryClasses.likeBtn
														)}
														onClick={onClickLike}
														data-id={user.id}
													>
														<SpriteIcon
															name="like"
															className={discoveryClasses.icon}
															color={primaryColor}
														/>
													</Button>
												)}
												<Button
													size="xl"
													shape="rounded"
													color="white"
													className={clsx(
														discoveryClasses.btn,
														discoveryClasses.passBtn
													)}
													data-id={user.id}
													onClick={onClickPass}
												>
													<SpriteIcon
														name="close"
														className={discoveryClasses.icon}
														color={black3}
													/>
												</Button>
											</>
										) : null}
										<CurrentView
											onClickBlock={onClickBlock}
											onClickPass={onClickPass}
											onClickReport={onClickReport}
											onClickConfirmReport={onClickConfirmReport}
											onClickLike={onClickLike}
											reportModal={reportModal}
											userId={user?.id}
											scrollRef={scrollRef}
											{...formProps}
										/>
										{format === screenFormat.desktop ? (
											<div className={discoveryClasses.actionsWrapper}>
												<Button
													size="sm"
													shape="rounded"
													color="white"
													variant="outlined"
													className={clsx(discoveryClasses.actionBtn, 'mr-1')}
													onClick={onClickReport}
												>
													<Typography>Report</Typography>
												</Button>
												<Button
													size="sm"
													shape="rounded"
													color="white"
													variant="outlined"
													className={discoveryClasses.actionBtn}
													onClick={onClickBlock}
												>
													<Typography>Block</Typography>
												</Button>
											</div>
										) : null}
									</div>
								</BaseScrollbars>
							)}
						</ProfileContainer>
					)}
				</LikesContainer>
			)}
		</>
	)
}

export default LikesPreview
