import React, { memo } from 'react'
import ConversationView from 'modules/conversation/ConversationView.js'
import classes from './ReceivedConversation.module.scss'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, primaryColor } from 'constants/colors.js'
import TextPlaceholder from 'components/ui/layout/TextPlaceholder'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'

const ReceivedConversationView = ({
	onClickLike = () => null,
	userId = '',
	onClickPass = () => null,
	conversationId,
	infoDialog,
	...props
}) => {
	return conversationId ? (
		<div className={classes.root}>
			<ConversationView hideInput {...props} />
			<>
				<Button
					size="xl"
					shape="rounded"
					color="white"
					className={clsx(classes.btn, classes.likeBtn)}
					onClick={onClickLike}
					data-id={userId}
				>
					<SpriteIcon
						name="like"
						className={classes.icon}
						color={primaryColor}
					/>
				</Button>
				<Button
					size="xl"
					shape="rounded"
					color="white"
					className={clsx(classes.btn, classes.passBtn)}
					data-id={userId}
					onClick={onClickPass}
				>
					<SpriteIcon name="close" className={classes.icon} color={black3} />
				</Button>
			</>
			{infoDialog && (
				<InfoDialog
					open={infoDialog.state}
					onClose={infoDialog.close}
					type={'error'}
					title={'Error'}
					body={infoDialog.error}
				/>
			)}
		</div>
	) : (
		<TextPlaceholder text="Select received request" />
	)
}

export default memo(ReceivedConversationView)
