import React from 'react'
import PaymentForm from 'modules/subscriptionBilling/PaymentForm'
import classes from './PaymentMethod.module.scss'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import BaseCard from 'components/ui/controls/BaseCard'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'

const PaymentMethodView = ({
	onDeletePM,
	onSubmit,
	billingState,
	infoDialog,
	currentPlan,
}) => {
	return (
		<div className={classes.root}>
			<BaseScrollbars>
				<div className={classes.content}>
					<BaseCard className={classes.card}>
						<PaymentForm
							onDelete={onDeletePM}
							onSubmit={onSubmit}
							billingState={billingState}
							currentPlan={currentPlan}
						/>
					</BaseCard>
				</div>
			</BaseScrollbars>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.close}
				type={'error'}
				title={'Error'}
				body={infoDialog.error}
			/>
		</div>
	)
}

export default PaymentMethodView
