import React, { useCallback, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import classes from './UpgradeBannerDialog.module.scss'
import Button from 'components/ui/Button'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import SpriteIcon from '../../../../components/ui/SpriteIcon'
import { routesByName } from '../../../../constants/routes'
import useHistory from 'hooks/useHistory.js'
import _useEffect from 'hooks/_useEffect'
import { setIsShownUpgradeBanner } from '../../../app/store/actions'
import { ReactComponent as LightLogo } from 'assets/staticIcons/light-logo.svg'
import {
	getCurrentPlan,
	getCurrentPlanId,
	getSubscriptionPlans
} from '../../../subscriptionBilling/store/selectors'
import { setCurrentPlan } from '../../../subscriptionBilling/store/actions'
import usePromoCode from '../../../../hooks/usePromoCode'
import useDetermineScreenFormat from '../../../../hooks/useDetermineScreenFormat'
import { breakpointsUp } from '../../../../constants/breakpoints'
import { screenFormat } from '../../../../constants/screenFormat'

const UpgradeBannerDialog = ({
	isShownUpgradeBanner,
	countToShowUpgradeBanner,
	setIsShownUpgradeBanner
}) => {
	const history = useHistory()
	const [isShownDialog, setIsShownDialog] = useState(false)

	const plans = useSelector(getSubscriptionPlans)
	const currentPlan = useSelector(getCurrentPlan)
	const currentPlanId = useSelector(getCurrentPlanId)
	const promoCode = usePromoCode(currentPlan?.id, currentPlan?.promoCode)
	const dispatch = useDispatch()
	const format = useDetermineScreenFormat(breakpointsUp.lg)

	_useEffect(() => {
		if (countToShowUpgradeBanner === 25 && !isShownUpgradeBanner) {
			setIsShownUpgradeBanner()
			setIsShownDialog(true)
		}
	}, [countToShowUpgradeBanner])

	const PlanItem = ({
		id,
		month,
		price,
		discount,
		mostPopular,
		isActive,
		onClick
	}) => {
		return (
			<div
				className={clsx(
					classes.planItem,
					mostPopular && classes.mostPopular,
					isActive && classes.active
				)}
				data-id={id}
				role="presentation"
				onClick={onClick}
			>
				<div className={classes.planItemInner}>
					<div className={classes.period}>
						{month}
						{month > 1 ? ' months' : ' month'}
					</div>

					<div className={classes.price}>
						${price / month}
						<div className={classes.currency}>cad</div>
					</div>

					<div className={classes.bottomInfo}>
						<div className={classes.perMonth}>per month</div>

						{month > 1 && (
							<div className={classes.total}>{`($${price} total)`}</div>
						)}

						{discount > 0 && (
							<div className={classes.discount}>{discount}% discount</div>
						)}
					</div>
				</div>

				{mostPopular && (
					<div className={classes.mostPopularBadge}>Most Popular</div>
				)}
			</div>
		)
	}

	const handleSelectPlan = useCallback(
		event => {
			dispatch(setCurrentPlan(event.currentTarget.getAttribute('data-id')))
			promoCode.resetCode()
		},
		[dispatch, promoCode]
	)

	const renderPlanItems = useMemo(
		() =>
			plans.map((plan, index) => (
				<PlanItem
					key={`${index}-${plans.id}`}
					id={plan.id}
					month={plan.month}
					discount={plan.discount}
					price={plan.price}
					mostPopular={plans.length - 1 === index}
					isActive={plan.id === currentPlanId}
					onClick={handleSelectPlan}
				/>
			)),
		[plans, currentPlanId, handleSelectPlan]
	)

	const renderButtons = () => (
		<div className={classes.buttons}>
			<Button
				variant="contained"
				shape="rounded"
				color="white"
				size="lg"
				className={clsx(classes.submitBtn, classes.confirmBtn)}
				onClick={() => history.push(routesByName.upgrade.root)}
				type="submit"
				iconPlacement="start"
				icon={<SpriteIcon name="lock-empty-bg" size="md" className="mr-1" />}
			>
				Unlock Premium
			</Button>

			<Button
				variant="contained"
				color="transparent"
				size="lg"
				shape="rounded"
				className={classes.cancelBtn}
				onClick={() => setIsShownDialog(false)}
			>
				Not now
			</Button>
		</div>
	)

	return (
		<BaseDialog
			open={isShownDialog}
			fullScreen={true}
			onClose={() => {}}
			classesPaper={classes.paper}
		>
			<div className={classes.wrapper}>
				<div className={classes.innerWrapper}>
					<div className={classes.content}>
						<div className={classes.info}>
							<LightLogo className={classes.logoIcon} />
							<div className={classes.title}>Upgrade for more features!</div>
							<div className={classes.description}>
								Premium members can see who liked them, send unlimited messages
								& more!
							</div>

							{format === screenFormat.desktop && renderButtons()}
						</div>

						<div className={classes.plans}>{renderPlanItems}</div>

						{format === screenFormat.mobile && renderButtons()}
					</div>
				</div>
			</div>
		</BaseDialog>
	)
}

const mapStateToProps = state => ({
	isShownUpgradeBanner: state.app.isShownUpgradeBanner,
	countToShowUpgradeBanner: state.app.countToShowUpgradeBanner
})

const actionsToProps = {
	setIsShownUpgradeBanner
}

export default connect(mapStateToProps, actionsToProps)(UpgradeBannerDialog)
