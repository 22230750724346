import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMe } from 'modules/profile/store/selectors'
import useHistory from 'hooks/useHistory'
import { screenFormat } from 'constants/screenFormat'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { routesByName } from 'constants/routes'
import useFlagManager from 'hooks/useFlagManager'
import { cancelSubscription } from 'modules/subscriptionBilling/services'
import { logOut } from 'modules/auth/store/actions'
import { getMeAction } from 'modules/profile/store/actions'
import { toggleLoader } from 'modules/app/store/actions'
import { updateMe } from 'modules/profile/store/actions.js'
import {
	closeDialogManager,
	setDialogManagerState
} from 'modules/dialogManager/store/actions'
import { editorConfig } from 'modules/profile/components/EditorDialog/config'
import { firstName, lastName } from 'modules/profile/createProfile/config'
import { pauseAccount } from 'modules/profile/services'
import { getAppVersion } from '../app/store/selectors'
import * as Sentry from '@sentry/browser'

const SettingsContainer = ({ children }) => {
	const user = useSelector(getMe)
	const dispatch = useDispatch()
	const history = useHistory()
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const autoRenewalDialog = useFlagManager(false)
	const termsAndConditionsDialog = useFlagManager(false)
	const privacyPolicyDialog = useFlagManager(false)
	const editUserNameDialog = useFlagManager(false)
	const deleteAccountDialog = useFlagManager(false)
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')
	const deleteReasonsDialog = useFlagManager()
	const appVersion = useSelector(getAppVersion)

	const isMobile = useMemo(() => format === screenFormat.mobile, [format])

	const handleClickEditUserName = useCallback(() => {
		editUserNameDialog.turnIn()
	}, [editUserNameDialog])

	const handleSubmitUserName = useCallback(
		async values => {
			try {
				dispatch(toggleLoader())
				await editorConfig[firstName].service(values)
				await dispatch(updateMe(values))
				dispatch(getMeAction())

				editUserNameDialog.turnOff()
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			} finally {
				dispatch(toggleLoader())
			}
		},
		[dispatch, editUserNameDialog, infoDialog]
	)

	const handleClickEditPhone = useCallback(() => {
		history.push(routesByName.main.settings.changeIdentityWithType('phone'))
	}, [history])

	const handleClickEmail = useCallback(() => {
		history.push(routesByName.main.settings.changeIdentityWithType('email'))
	}, [history])

	const handleConfirmPauseAccount = useCallback(async () => {
		try {
			dispatch(toggleLoader())
			await pauseAccount('inactive')
			dispatch(updateMe({ status: 'inactive' }))
		} catch (e) {
			Sentry.captureException(e)
		} finally {
			dispatch(closeDialogManager())
			deleteReasonsDialog.turnOff()
			dispatch(toggleLoader())
		}
	}, [deleteReasonsDialog, dispatch])

	const handleClickPauseAccount = useCallback(async () => {
		const onConfirm = async status => {
			try {
				dispatch(toggleLoader())
				await pauseAccount(status)
				dispatch(updateMe({ status }))
			} catch (e) {
				Sentry.captureException(e)
			} finally {
				dispatch(closeDialogManager())
				dispatch(toggleLoader())
			}
		}

		const status = user.status
		if (status === 'inactive') {
			dispatch(
				setDialogManagerState({
					open: true,
					title: 'Unpause account',
					body: 'Welcome back! Your profile is now active.',
					onClose: () => dispatch(closeDialogManager()),
					onConfirm: () => onConfirm('active'),
					withCancelBtn: false,
					withConfirmBtn: true
				})
			)
		} else {
			dispatch(
				setDialogManagerState({
					open: true,
					title: 'Pause your account?',
					body:
						'Your profile will be hidden and other members will not be able to see or message you. You can reactivate your account anytime.',
					onClose: () => dispatch(closeDialogManager()),
					onConfirm: () => {
						dispatch(closeDialogManager())
						deleteReasonsDialog.turnIn()
					},
					withCancelBtn: true,
					withConfirmBtn: true
				})
			)
		}
	}, [deleteReasonsDialog, dispatch, user.status])

	const handleClickDisableSubscriptions = useCallback(async () => {
		try {
			dispatch(toggleLoader())
			await cancelSubscription()
			autoRenewalDialog.turnOff()
			await dispatch(getMeAction())
		} catch (e) {
			setError(e.message)
			infoDialog.turnIn()
		} finally {
			dispatch(toggleLoader())
		}
	}, [autoRenewalDialog, dispatch, infoDialog])

	const handleToggleNotifications = useCallback(() => {
		// eslint-disable-next-line
		console.log(`handleToggleNotifications`)
	}, [])

	const handleClickDeleteAccount = useCallback(() => {
		// const onConfirm = () => {
		// 	dispatch(closeDialogManager())
		// }
		deleteAccountDialog.turnIn()

		// dispatch(
		// 	setDialogManagerState({
		// 		open: true,
		// 		title: 'Delete your account?',
		// 		body:
		// 			'You cannot recover your account once deleted, are you sure you want to delete your account?',
		// 		onClose: () => dispatch(closeDialogManager()),
		// 		onConfirm
		// 	})
		// )
	}, [deleteAccountDialog])

	const handleClickLogout = useCallback(() => {
		const onConfirm = async () => {
			dispatch(logOut())
		}

		dispatch(
			setDialogManagerState({
				open: true,
				title: 'Logout',
				body: 'Are you sure you want to confirm the action?',
				onClose: () => dispatch(closeDialogManager()),
				onConfirm,
				withConfirmBtn: true,
				withCancelBtn: true,
				confirmBtn: { title: 'Yes' },
				cancelBtn: { title: 'No' }
			})
		)
	}, [dispatch])

	const handleUpgrade = useCallback(() => {
		history.push(routesByName.upgrade.root)
	}, [history])

	const handleClickTermsAndConditions = useCallback(() => {
		termsAndConditionsDialog.turnIn()
	}, [termsAndConditionsDialog])

	const handleClickPrivacyPolicy = useCallback(() => {
		privacyPolicyDialog.turnIn()
	}, [privacyPolicyDialog])

	const props = useMemo(
		() => ({
			callbacks: {
				onClickEditPhone: handleClickEditPhone,
				onClickEmail: handleClickEmail,
				onClickEditUserName: handleClickEditUserName,
				onClickSubmitUserName: handleSubmitUserName,
				onClickPauseAccount: handleClickPauseAccount,
				onClickDisableSubscriptions: handleClickDisableSubscriptions,
				onToggleNotifications: handleToggleNotifications,
				onClickDeleteAccount: handleClickDeleteAccount,
				onClickLogout: handleClickLogout,
				onClickUpgrade: handleUpgrade,
				onClickBack: history.goBack,
				onClickTermsAndConditions: handleClickTermsAndConditions,
				onClickPrivacyPolicy: handleClickPrivacyPolicy,
				onConfirmPauseAccount: handleConfirmPauseAccount
			},
			data: {
				phone: user.phone,
				email: user.email,
				initialValues: {
					[firstName]: user[firstName],
					[lastName]: user[lastName] || ''
				},
				hasSubscription: user.subscriptionStatus,
				cancelledSubscription: user?.subscription?.is_cancelled,
				isPauseAccount: user.status === 'inactive',
				doPushNotifications: false,
				userStatus: user.status
			},
			dialogs: {
				autoRenewalDialog,
				termsAndConditionsDialog,
				privacyPolicyDialog,
				editUserNameDialog,
				deleteAccountDialog,
				deleteReasonsDialog
			},
			infoDialog: {
				state: infoDialog.state,
				close: infoDialog.turnOff,
				error: error
			},
			isMobile,
			appVersion
		}),
		[
			handleClickEditPhone,
			handleClickEmail,
			handleClickEditUserName,
			handleSubmitUserName,
			handleClickPauseAccount,
			handleClickDisableSubscriptions,
			handleToggleNotifications,
			handleClickDeleteAccount,
			handleClickLogout,
			handleUpgrade,
			history.goBack,
			handleClickTermsAndConditions,
			handleClickPrivacyPolicy,
			user,
			autoRenewalDialog,
			termsAndConditionsDialog,
			privacyPolicyDialog,
			editUserNameDialog,
			deleteAccountDialog,
			isMobile,
			appVersion,
			infoDialog.state,
			infoDialog.turnOff,
			error,
			deleteReasonsDialog,
			handleConfirmPauseAccount
		]
	)

	return children(props)
}

export default SettingsContainer
