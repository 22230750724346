import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getMessagesByConversationId,
	getNextPageMessages,
	sendMessage
} from 'modules/conversation/store/actions.js'
import _useEffect from '@rznv/components/src/ChatBox/hooks/_useEffect.js'
import { _get } from 'utils/lodash.js'
import channelsByName from 'libs/echo/channels'
import { MESSAGE_SENT } from 'libs/echo/events'
import {
	pushReceivedMessage,
	setIsLastMessageRead
} from 'modules/conversation/store/actions'
import useConnectToEchoServer from 'libs/echo/useConnectToEchoServer'
import { getCurrentConversation } from 'modules/conversation/store/selectors.js'
import useCallbackRef from 'hooks/useCallbackRef.js'
import _useWillUnmount from 'hooks/_useWillUnmount'
import { setShowNavigationBar } from 'modules/app/store/actions.js'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat.js'
import { screenFormat } from 'constants/screenFormat.js'
import { breakpointsUp } from 'constants/breakpoints.js'
import useOutsideClickRef from '@rooks/use-outside-click-ref'
import { getIsLastMessageRead } from 'modules/conversation/store/selectors'

const ConversationContainer = ({ children }) => {
	const conversationId = useSelector(getCurrentConversation)
	const [inputNode, inputRef] = useCallbackRef()
	const conversationIdRef = useRef()
	const [message, setMessage] = useState('')
	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile

	const intConversationId = useMemo(() => Number(conversationId), [
		conversationId
	])

	const isLastMessageRead = useSelector(getIsLastMessageRead(intConversationId))

	const channelName = useMemo(
		() =>
			intConversationId
				? channelsByName.conversationById(intConversationId)
				: null,
		[intConversationId]
	)

	const dispatch = useDispatch()
	const { messages = [], meta } = useSelector(
		({ messenger: { messagesByConversationId, metaByConversationId } }) => ({
			messages: messagesByConversationId[intConversationId],
			meta: metaByConversationId[intConversationId]
		})
	)

	const myId = useSelector(({ profile }) => profile.user.id)

	const handleReceivedMessage = useCallback(
		async data => {
			try {
				await dispatch(pushReceivedMessage(data))
			} catch (e) {
				console.dir(e)
			}
		},
		[dispatch]
	)

	useConnectToEchoServer({
		channelName,
		events: {
			[MESSAGE_SENT]: handleReceivedMessage
		}
	})

	const hasNextPage = useMemo(() => _get(meta, 'hasNextPage', true), [meta])

	_useEffect(() => {
		if (intConversationId) {
			dispatch(getMessagesByConversationId(intConversationId))
			conversationIdRef.current = intConversationId
		}
	}, [intConversationId])

	const pushMessage = useCallback(async () => {
		setMessage(message => {
			if (message) {
				dispatch(
					sendMessage(conversationIdRef.current, {
						type: 1,
						version: 0,
						// body: message?.replaceAll(`\n`, '<br/>')
						body: message?.replace(/\n/g, '<br/>')
					})
				)
				return ''
			}
		})
	}, [dispatch])

	const handleChangeMessage = useCallback(({ target: { value } }) => {
		setMessage(value)
	}, [])

	const handleLoadMore = useCallback(
		async event => {
			try {
				await dispatch(
					getNextPageMessages(intConversationId, {
						sort: 'desc',
						since: messages[0]?.sentAt
					})
				)
			} catch (e) {
				console.dir(e)
			}
		},
		[dispatch, intConversationId, messages]
	)

	const handleClickInput = useCallback(
		event => {
			if (isMobile) {
				dispatch(setShowNavigationBar(false))
			}
			if (!isLastMessageRead) {
				dispatch(setIsLastMessageRead(intConversationId, true))
			}
		},
		[isMobile, dispatch, isLastMessageRead, intConversationId]
	)

	const handleMouseOver = useCallback(() => {
		if (!isLastMessageRead) {
			dispatch(setIsLastMessageRead(intConversationId, true))
		}
	}, [intConversationId, isLastMessageRead, dispatch])

	const handleOutsideClick = useCallback(() => {
		if (isMobile) {
			dispatch(setShowNavigationBar(true))
			if (!isLastMessageRead) {
				dispatch(setIsLastMessageRead(intConversationId, true))
			}
		}
	}, [isMobile, dispatch, isLastMessageRead, intConversationId])

	const [inputContainerRef] = useOutsideClickRef(handleOutsideClick)

	// console.log(inputContainerRef);

	const handleClickPushBtn = useCallback(() => {
		inputNode.focus()
		pushMessage()
	}, [inputNode, pushMessage])

	useEffect(() => {
		if (inputNode) {
			inputNode.addEventListener('keydown', e => {
				const keyCode = e.which || e.keyCode

				if (keyCode === 13 && !e.shiftKey) {
					e.preventDefault()
					pushMessage()
				} else if (keyCode === 13 && e.shiftKey) {
					// setMessage(value => `${value}\n`)
					// pushMessage()
				}
			})
		}
		//eslint-disable-next-line
	}, [inputNode, pushMessage])

	_useWillUnmount(() => {
		dispatch(setShowNavigationBar(true))
	})

	const props = useMemo(
		() => ({
			messages: messages,
			myId: myId,
			value: message,
			onChange: handleChangeMessage,
			loadMore: handleLoadMore,
			hasNextPage: hasNextPage,
			onClickPushBtn: handleClickPushBtn,
			onClickInput: handleClickInput,
			conversationId: intConversationId,
			inputRef,
			inputContainerRef,
			onMouseOver: handleMouseOver
		}),
		[
			messages,
			myId,
			message,
			handleChangeMessage,
			handleLoadMore,
			hasNextPage,
			handleClickPushBtn,
			handleClickInput,
			intConversationId,
			inputRef,
			inputContainerRef,
			handleMouseOver
		]
	)

	return children(props)
}

export default ConversationContainer
