import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring/web.cjs'

const Fade = React.forwardRef((props, ref) => {
	const { in: open, children, onEnter, onExited, ...other } = props
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () =>
			setTimeout(() => {
				if (open && onEnter) {
					onEnter()
				}
			}, 0),
		onRest: () =>
			setTimeout(() => {
				if (!open && onExited) {
					onExited()
				}
			}, 0)
	})

	return (
		<animated.div ref={ref} style={style} {...other}>
			{children}
		</animated.div>
	)
})

Fade.propTypes = {
	children: PropTypes.element,
	in: PropTypes.bool,
	onEnter: PropTypes.func,
	onExited: PropTypes.func
}

export default Fade
