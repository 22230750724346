import React from 'react'
import classes from './PrivacyPolicyPage.module.scss'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import Button from 'components/ui/Button'
import { content } from './config'
import useHistory from 'hooks/useHistory'

const navigationHeaderClasses = {
	root: classes.navigationHeader
}

const PrivacyPolicyPage = () => {
	const history = useHistory()

	return (
		<div className={classes.root}>
			<NavigationHeader
				title="Privacy Policy"
				onClickBackIcon={() => history.goBack()}
				classes={navigationHeaderClasses}
			/>
			<div className={classes.wrapper}>
				<div className={classes.text}>{content}</div>
				<Button
					className={classes.btn}
					onClick={() => history.goBack()}
					shape="rounded"
				>
					OK
				</Button>
			</div>
		</div>
	)
}

export default PrivacyPolicyPage
