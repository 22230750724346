import React, { useCallback, useState } from 'react'
import classes from './HelpPage.module.scss'
import BaseAccordionList from 'components/ui/controls/BaseAccordionList'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import useHistory from 'hooks/useHistory'
import { listItems, itemsDescription } from './config'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import BaseCard from 'components/ui/controls/BaseCard'
import useFlagManager from 'hooks/useFlagManager'
import SpriteIcon from '../../components/ui/SpriteIcon'

const accordionClasses = {
	root: 'mb-3'
}

const HelpPage = () => {
	const history = useHistory()
	const showDescription = useFlagManager(false)
	const [selectedItem, setSelectedItem] = useState(null)

	const handleShowDetails = useCallback(
		target => {
			showDescription.toggle()
			setSelectedItem(target.innerText)
		},
		[showDescription]
	)

	const handleClickBackButton = useCallback(() => {
		showDescription.state ? showDescription.toggle() : history.goBack()
	}, [showDescription, history])

	const format = useDetermineScreenFormat(breakpointsUp.sm)

	return (
		<div className={classes.root}>
			{format === screenFormat.mobile ? (
				<NavigationHeader
					title="Help & Support"
					onClickBackIcon={handleClickBackButton}
				/>
			) : (
				<SpriteIcon
					name="arrow-back"
					className={classes.backIcon}
					onClick={handleClickBackButton}
				/>
			)}
			<BaseScrollbars>
				<div className={classes.wrapper}>
					<div className={classes.list}>
						{showDescription.state ? (
							<BaseCard className={classes.cardRoot}>
								<div className={classes.descriptionTitle}>{selectedItem}</div>
								<div className={classes.descriptionText}>
									{itemsDescription[selectedItem]}
								</div>
							</BaseCard>
						) : (
							listItems.map((item, index) => (
								<BaseAccordionList
									classes={accordionClasses}
									title={item.title}
									items={item.items}
									key={item.title + index}
									onItemClick={handleShowDetails}
									// isFirst={index === 0}
								/>
							))
						)}
					</div>
				</div>
			</BaseScrollbars>
		</div>
	)
}

export default HelpPage
