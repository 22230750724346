import React from 'react'
import { ReactComponent as ProfileIcon } from 'assets/staticIcons/profile.svg'
import classes from './LeftSidebar.module.scss'
import Typography from 'components/ui/Typography'
import useUpgradeNotification from 'hooks/useUpgradeNotification'

const ProfileLeftSidebar = () => {
	const upgradeNotification = useUpgradeNotification()

	return (
		<div className={classes.root}>
			<ProfileIcon />
			<Typography className={classes.text}>
				Update, edit and preview
				<br />
				information about you
			</Typography>
			{upgradeNotification.carouselBanner}
		</div>
	)
}

export default ProfileLeftSidebar
