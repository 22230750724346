import React from 'react'
import moment from 'moment'
import classes from './DateDelimiter.module.scss'

const DateDelimiter = ({ date, format }) => {
	return (
		<div className={classes.root}>
			<span>{moment.unix(date / 1000).format(format)}</span>
		</div>
	)
}

export default DateDelimiter
