import React from 'react'
import classes from './FilterEditor.module.scss'
import { screenFormat } from 'constants/screenFormat'
import NavigationHeader from 'components/ui/layout/NavigationHeader'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import Logo from 'components/ui/layout/Logo'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'

const logoClasses = {
	icon: classes.icon,
	root: 'mt-2 mb-2'
}

const FilterEditor = ({ open, onClose, name, title, children }) => {
	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile

	const handleClose = () => onClose(name)

	return (
		<BaseDialog
			open={open}
			onClose={handleClose}
			fullScreen={isMobile}
			classes={{ paper: classes.desktopModal }}
		>
			{isMobile ? (
				<NavigationHeader title={title} onClickBackIcon={handleClose} />
			) : null}
			<div className={classes.content}>
				{!isMobile ? (
					<Typography className={classes.title}>{title}</Typography>
				) : null}

				<div className={classes.inputs}>{children}</div>

				{!isMobile ? (
					<Button
						onClick={handleClose}
						size="md"
						shape="rounded"
						className={classes.btn}
					>
						Done
					</Button>
				) : null}

				{isMobile ? (
					<Logo variant="dark" withTitle classes={logoClasses} />
				) : null}
			</div>
		</BaseDialog>
	)
}

export default FilterEditor
