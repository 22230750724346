import React, { useCallback } from 'react'
import classes from './BaseCard.module.scss'
import clsx from 'clsx'

const BaseCard = ({ className, children, onClick = () => null, name }) => {
	const handleClick = useCallback(() => {
		onClick(name)
	}, [name, onClick])
	return (
		<div
			role="presentation"
			onClick={handleClick}
			className={clsx(className, classes.root)}
		>
			{children}
		</div>
	)
}

export default BaseCard
