import React from 'react'
import CreateConversationContainer from './CreateConversationContainer.js'
import ConversationView from 'modules/conversation/ConversationView.js'

const CreateConversationPage = ({ onPushMessage, isConversationBlocked }) => {
	return (
		<CreateConversationContainer onPushMessage={onPushMessage}>
			{props => (
				<ConversationView
					isConversationBlocked={isConversationBlocked}
					{...props}
				/>
			)}
		</CreateConversationContainer>
	)
}

export default CreateConversationPage
