import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from './BaseSwitch.module.scss'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import clsx from 'clsx'

const BaseSwitch = ({
	value,
	name,
	onChange,
	label,
	spaceBetween = false,
	labelPlacement,
	checked = false,
	classes: outerClasses = {}
}) => {
	const labelClasses = useMemo(
		() => ({
			root: clsx(classes.labelRoot, outerClasses.labelRoot, {
				[classes.spaceBetween]: spaceBetween
			}),
			label: clsx(classes.label, outerClasses.label)
		}),
		[outerClasses.label, outerClasses.labelRoot, spaceBetween]
	)
	const switchClasses = useMemo(
		() => ({
			switchBase: clsx(classes.switchBase, outerClasses.switchBase),
			track: clsx(classes.switchTrack, outerClasses.switchTrack),
			checked: clsx(classes.switchChecked, outerClasses.switchChecked)
		}),
		[
			outerClasses.switchBase,
			outerClasses.switchChecked,
			outerClasses.switchTrack
		]
	)

	return (
		<FormControlLabel
			onChange={onChange}
			value={value}
			name={name}
			classes={labelClasses}
			control={
				<Switch color="primary" classes={switchClasses} checked={checked} />
			}
			label={label}
			labelPlacement={labelPlacement}
		/>
	)
}

BaseSwitch.propTypes = {
	className: PropTypes.string,
	labelPlacement: PropTypes.oneOf(['bottom', 'top', 'start', 'end'])
}

BaseSwitch.defaultProps = {
	className: ''
}

export default memo(BaseSwitch)
