import React from 'react'
import SubscribeContainer from './SubscribeContainer'
import SubscribeView from './SubscribeView'

const SubscribePage = ({ promoCode }) => {
	return (
		<SubscribeContainer promoCode={promoCode}>
			{SubscribeView}
		</SubscribeContainer>
	)
}

export default SubscribePage
